import React from 'react';

const Tab = (props) => {
  const onNewClick = () => {
    const { label, onClick } = props;
    onClick(label);
  };
  const { activeTab, label } = props;

  let classNameItem = 'nav-item nav-link';
  let classNameLink = 'nav-item nav-link';

  if (activeTab === label) {
    classNameItem += ' active ';
    classNameLink += ' active';
  }

  return (
    <div>
      <button
        className={classNameItem + classNameLink}
        onClick={() => onNewClick()}
        data-toggle="tab"
      >
        {label}
      </button>
    </div>
  );
};

export default Tab;
