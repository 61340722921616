/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
import { store } from '../../../hooks/dataContext';
import Pagination from '../../../components/Pagination/Pagination';
import Styles from './Table.styles';
import PublicSwitch from '../../../components/PublicSwitch/PublicSwitch';
import './Table.css';
import { AiFillEye } from 'react-icons/ai';
import {
  IoMdDownload,
  IoMdKey,
  IoMdMore,
  IoMdTrash,
} from 'react-icons/io';
import { FaProjectDiagram } from 'react-icons/fa';

import { getProjectById } from '../../../utils/projects.api';
import { Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function TeamsTable({ columns, data }) {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    onPageChange,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PublicPrivateSwitch = (node) => {
    if (state.user.id === node.owner) {
      return <PublicSwitch type="node" item={node} />;
    }
    if (node.is_public === true) {
      return <FiCheckCircle />;
    }
    return 'Private';
  };
  const DownloadAction = (row) => {
    return (
      <div className="col">
        <button
          className="tableBtn"
          onClick={() =>
            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                data: row.original,
                subModalTitle: 'Download Node',
              },
            })
          }
        >
          <IoMdDownload />
          <span className="pl-1">.CSV</span>
        </button>
      </div>
    );
  };
  const ViewAction = (row) => {
    return (
      <div className="col columnPadding">
        <Link
          className="tableBtn"
          to="/viewNode"
          onClick={() => {
            dispatch({
              type: 'SET_SELECTED_NODE',
              payload: row.original,
            });
          }}
        >
          <AiFillEye />
          <span className="pl-1">VIEW</span>
        </Link>
      </div>
    );
  };
  const Actions = (row) => {
    const node = row.original;
    if (state.user.id === node.owner) {
      return (
        <div className="row">
          {DownloadAction(row)}
          {ViewAction(row)}
          <div className="col">
            <button
              className={'tableBtn openMenu' + node.id}
              id={'openMenu' + node.id}
              onClick={handleClick}
            >
              <IoMdMore />
              <span className="pl-1">more</span>
            </button>
            <Menu
              id={'menu' + node.id}
              anchorEl={anchorEl}
              keepMounted
              open={
                Boolean(anchorEl) &&
                anchorEl.id === 'openMenu' + node.id
              }
              onClose={handleClose}
            >
              <MenuItem
                className="tableBtn"
                onClick={() => {
                  dispatch({
                    type: 'TOGGLE_MODAL',
                    payload: {
                      data: row.original,
                      subModalTitle: 'Manage Sharing',
                    },
                  });
                  handleClose();
                }}
              >
                <IoMdKey />
                <span className="pl-1">Manage Sharing</span>
              </MenuItem>

              <MenuItem
                className="tableBtn"
                onClick={() => {
                  dispatch({
                    type: 'SET_SELECTED_NODE',
                    payload: row.original,
                  });
                  history.push(`/qaqc`);
                  handleClose();
                }}
              >
                <FaProjectDiagram />
                <span className="pl-1">Edit Ontology</span>
              </MenuItem>

              <MenuItem
                className="tableBtn"
                onClick={() => {
                  dispatch({
                    type: 'TOGGLE_MODAL',
                    payload: {
                      data: { id: row.original.id },
                      subModalTitle: 'Decommission Node',
                    },
                  });
                  handleClose();
                }}
              >
                <IoMdTrash />
                <span className="pl-1">Decommission</span>
              </MenuItem>
            </Menu>
          </div>
        </div>
      );
    } else if (
      state.user.id !== node.owner &&
      node.is_public !== true
    ) {
      return (
        <div className="row">
          {DownloadAction(row)}
          {ViewAction(row)}
          <div className="col">
            <button
              className="tableBtn"
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    data: row.original,
                    subModalTitle: 'Leave Shared Node',
                  },
                });
                handleClose();
              }}
            >
              <IoMdTrash />
              <span className="pl-1">Leave</span>
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        {DownloadAction(row)}
        {ViewAction(row)}
      </div>
    );
  };

  // Render the UI for your table
  return (
    <div className="tableContainer">
      <Styles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={column.id} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.original.id}>
                  {row.original ? (
                    <tr key={row.original.id} {...row.getRowProps()}>
                      {/* Node name and id */}
                      <td className="node">
                        <Link
                          className="node"
                          to="/viewNode"
                          onClick={() => {
                            dispatch({
                              type: 'SET_SELECTED_NODE',
                              payload: row.original,
                            });
                            getProjectById(
                              row.original.project_id,
                              dispatch
                            );
                          }}
                        >
                          {row.original.name}
                          <br />
                          id: {row.original.node_id}
                        </Link>
                      </td>
                      <td>
                        <p>
                          {row.original.product_id ||
                            row.original.deploymentid}
                        </p>
                      </td>
                      <td>
                        <p>{row.original.status}</p>
                      </td>
                      <td>
                        <p>
                          {row.original.cellular
                            ? 'connected'
                            : 'offline'}
                        </p>
                      </td>
                      <td>{PublicPrivateSwitch(row.original)}</td>
                      <td>{Actions(row)}</td>
                    </tr>
                  ) : (
                    <tr key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, i) => (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        <Pagination
          pages={pageCount}
          page={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          onPageChange={onPageChange}
          pageIndex={pageIndex}
          pageSize={pageSize}
          data={data}
        />
      </Styles>
    </div>
  );
}
