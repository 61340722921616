/**
 * Semi-reusable horizontal step indicator demonstrates where user is in multi-step process.
 * Props: {
 *    stepArray: array of strings for step names
 *    activeStepNum: current step number from parents local state.
 *    setActiveNumber: function from parent that sets active step number
 *    finish: boolean
 * }
 *
 *  TODO: this is really brittle. Would be great if it wasn't.
 *  Could be replaced with material-ui stepper.
 */

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Stepper.css';
import { Button } from '@material-ui/core';

const Stepper = (props) => {
  const { stepArray, activeStepNum, setActiveNumber, finish } = props;

  const stepDisplay = stepArray.map((step, i) => (
    <div
      key={i}
      className="stepWrapper"
      onClick={() => setActiveNumber(i + 1)}
      role="button"
    >
      <div
        className={
          i + 1 === activeStepNum
            ? 'circle blue white-text stepNumber'
            : 'circle stepNumber'
        }
      >
        {i + 1}
      </div>
      <div>{step}</div>
      <div
        className={i !== stepArray.length - 1 ? 'dividerLine' : ''}
      />
    </div>
  ));

  return (
    <div className="horizontalStepper">
      <Button
        variant="contained"
        color="gray"
        onClick={() => {
          setActiveNumber(activeStepNum - 1);
        }}
        disabled={activeStepNum === 1}
      >
        Back
      </Button>
      <div className="stepArea">{stepDisplay}</div>
      {finish && activeStepNum === stepArray.length ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            finish();
          }}
        >
          Finish
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          disabled={activeStepNum === stepArray.length}
          onClick={() => {
            setActiveNumber(activeStepNum + 1);
          }}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default Stepper;
