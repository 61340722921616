import axios from 'axios';
import ConsoleLogger from '../hooks/ConsoleLogger';

export const getNodeList = async (state, dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_IOT_SERVER}/nodes`, {
        rejectUnauthorized: false,
        withCredentials: false,
        //headers: { user_id: state.user.id },
      })
      .then((response) => {
        ConsoleLogger('getNodeList response', response.data.records);
        dispatch({
          type: 'SET_NODE_LIST',
          payload: response.data.records,
        });
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error getting nodeList", e);
  }
};

export const addNewNode = async (node, dispatch) => {
  const data = {
    deploymentid: '',
    elevation: '',
    point: '',
    metadata: {
      name: '',
    },
    ...node,
  };
  ConsoleLogger('addNewNode', data);
  // try {
  //   return await axios
  //     .post(`${process.env.REACT_APP_IOT_SERVER}/nodes`, data, {
  //       rejectUnauthorized: false,
  //       withCredentials: false,
  //     })
  //     .then((response) => {
  //       dispatch({ type: 'SET_NODE_LIST', payload: response.data });
  //       return response.data;
  //     });
  // } catch (e) {
  //   console.warn("There's been an error adding new node", e);
  // }
};
export const getNodeUsersByNodeId = async (nodeId, dispatch) => {
  ConsoleLogger('getNodeUsersByNodeId', nodeId);
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_IOT_SERVER}/nodes/${nodeId}/users`,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        ConsoleLogger('getNodeUsersByNodeId response', response.data);
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error getting node by id", e);
  }
};
export const getNodeById = async (nodeId, dispatch) => {
  ConsoleLogger('getNodeById', nodeId);
  try {
    return await axios
      .get(`${process.env.REACT_APP_IOT_SERVER}/nodes/${nodeId}`, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        ConsoleLogger('getNodeById response', response.data);
        dispatch({
          type: 'SET_SELECTED_NODE',
          payload: response.data.records,
        });
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error getting node by id", e);
  }
};

export const updateNodeUsers = async (node, dispatch) => {
  const { node_ids, emails } = node;

  if (!node.node_ids) {
    console.warn(
      'Could not update node users. at least one node Id is required'
    );
    return;
  }

  ConsoleLogger('update node users', node);
  try {
    return await axios
      .put(`${process.env.REACT_APP_IOT_SERVER}/nodes/users`, node, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error updating node users", e);
  }
};

export const removeNodeUsers = async (node, dispatch) => {
  const { node_ids, user_ids } = node;

  if (!node.node_ids) {
    console.warn(
      'Could not remove node users. at least one node Id is required'
    );
    return;
  }

  ConsoleLogger('remove node users', node);
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_IOT_SERVER}/nodes/users/remove`,
        node,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        console.info('User Removed');
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error updating node users", e);
  }
};

export const getDataByNodeId = async (nodeId, dispatch) => {
  ConsoleLogger('getDataByNodeId nodeId:', nodeId);
  try {
    dispatch({ type: 'TOGGLE_LOADING', payload: null });
    return await axios
      .get(
        `${process.env.REACT_APP_IOT_SERVER}/nodes/${nodeId}/data`,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        ConsoleLogger('getDataByNodeId response:', response.data);
        // dispatch({
        //   type: 'SET_SELECTED_NODE',
        //   payload: response.data.records,
        // });
        dispatch({
          type: 'SET_PROJECT_NODES',
          payload: response.data.records,
        });
        dispatch({
          type: 'TOGGLE_LOADING',
        });
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error getting data by node id", e);
  }
};

export const deleteNode = async (node, dispatch) => {
  ConsoleLogger('fake delete Node:', node);
  // try {
  //   return await axios
  //     .delete(
  //       `${process.env.REACT_APP_IOT_SERVER}/nodes/${node.node_id}`,
  //       node,
  //       {
  //         rejectUnauthorized: false,
  //         withCredentials: false,
  //       }
  //     )
  //     .then((response) => {
  // dispatch({
  //   type: 'TOGGLE_MODAL',
  //   payload: { subModalTitle: 'Success' },
  // });
  //       ConsoleLogger(
  //         'this is the response for deleteNode',
  //         response,
  //       );
  //       // dispatch({
  //       //   type: 'SET_NODE_LIST',
  //       //   payload: response.data,
  //       // });

  //       return response.data;
  //     });
  // } catch (e) {
  //   console.warn("There's been an error deleting node", e);
  // }
};

// Gets all nodes associated with a specific project
export const getNodesByProjectId = async (
  projectId,
  state,
  dispatch
) => {
  ConsoleLogger('this is getNodesByProjectId projectId:', projectId);
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_IOT_SERVER}/projects/${projectId}/nodes`,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        ConsoleLogger(
          'this is getNodesByProjectId response',
          response.data.records
        );

        if (dispatch) {
          dispatch({
            type: 'SET_PROJECT_NODES',
            payload: response.data.records,
          });
          return response.data;
        }

        return response.data.records;
      });
  } catch (e) {
    console.warn(
      "There's been an error getting nodes by project id",
      e
    );
  }
};

export const togglePublicNode = async (node, state, dispatch) => {
  const toggled = {
    is_public: !node.is_public,
  };
  ConsoleLogger('this is togglePublicNode node:', node);
  try {
    return await axios
      .patch(
        `${process.env.REACT_APP_IOT_SERVER}/nodes/${node.id}`,
        toggled,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        ConsoleLogger('this is togglePublicNode response:', response);
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: { subModalTitle: 'Success' },
        });
        getNodeList(state, dispatch);
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error toggling public node", e);
  }
};

export const registerNode = async (id, userId, nodeName) => {
  const nodeBlob = {
    id: id,
    owner: userId,
    name: nodeName,
  };
  ConsoleLogger('registerNode nodeId', id, 'userId', userId);
  try {
    return await axios
      .post(`${process.env.REACT_APP_IOT_SERVER}/nodes`, nodeBlob, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error registering node", e);
  }
};

export const decommissionAndRegisterNode = async (
  nodeId,
  nodeName,
  userId,
  state,
  dispatch
) => {
  const nodeBlob = {
    node_id: nodeId,
    owner: userId,
    name: nodeName,
  };
  ConsoleLogger(
    'decommission and register Node nodeId',
    nodeId,
    'userId',
    userId
  );
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_IOT_SERVER}/nodes/decommission-and-register`,
        nodeBlob,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        getNodeList(state, dispatch);
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: {
            subModalTitle: 'Success',
          },
        });

        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error decommissioning node", e);
  }
};

export const decommissionNode = async (
  id,
  userId,
  state,
  dispatch
) => {
  const nodeBlob = {
    id: id,
    owner: userId,
  };
  ConsoleLogger('decommissionNode nodeId', id, 'userId', userId);
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_IOT_SERVER}/nodes/decommission`,
        nodeBlob,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        getNodeList(state, dispatch);
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: {
            subModalTitle: 'Success',
          },
        });

        return response.data;
      });
  } catch (e) {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        subModalTitle: 'Error',
      },
    });
    console.warn("There's been an error decommissioning node", e);
  }
};
