import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import Accordion from '../../../../components/Accordion/Accordion';
import TabList from '../../../../components/TabList/TabList';
import { store } from '../../../../hooks/dataContext';
import './NodeSetup.css';
import { useMediaQuery } from 'react-responsive';

const NodeSetup = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { nodeSetup } = state;
  const {
 steps, transcript, tips, assemblyMaterials 
} = nodeSetup;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 650 });

  const [videoURL, setVideoURL] = useState(
    'https://www.youtube.com/watch?v=Jn85Lr-G_VU&t=2s'
  );
  const [autoPlay, setAutoPlay] = useState(false);

  const handleVideoPlay = (url) => {
    setVideoURL(url);
    setAutoPlay(true);
  };

  if (isTabletOrMobile === true) {
    return (
      <div className="setupContainerMobile">
        <div id="mainSetupVideoMobile">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <ReactPlayer
            url={videoURL}
            controls
            playing={autoPlay}
            width="100%"
          />
        </div>
        {steps.map((step) => (
          <Accordion title={step.title}>
            <div>
              <div className="stepVideo">
                <button
                  className="blue white-text"
                  onClick={() => handleVideoPlay(step.url)}
                >
                  View Video at Step
                </button>
              </div>
              <div>
                <div className="stepComponents">
                  <h2 className="semi-bold">Components</h2>
                  {step.components.map((item) => (
                    <p>{`- ${item}`}</p>
                  ))}
                </div>
                <div className="stepInstructions">
                  <h2 className="semi-bold">Instructions</h2>
                  {step.subSteps.map((subStep) => (
                    <p>{subStep}</p>
                  ))}
                </div>
                {!step.notes ? null : (
                  <div className="stepInstructions">
                    <h2 className="semi-bold">Notes:</h2>
                    {step.notes.map((note) => (
                      <p>{note}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Accordion>
        ))}
      </div>
    );
  }

  return (
    <div className="nodeSetupContainer">
      <div className="setupInstructionsArea">
        <TabList>
          {/* //// Steps //// */}
          <div label="Setup" className="accordionContainer">
            <button
              className="blue white-text startVideo"
              onClick={() => handleVideoPlay(
                    'https://www.youtube.com/watch?v=Jn85Lr-G_VU&t=2s'
                  )
              }
            >
              Start Video
            </button>
            {steps.map((step) => (
              <Accordion title={step.title}>
                <div>
                  <div className="stepVideo">
                    <button
                      className="blue white-text"
                      onClick={() => handleVideoPlay(step.url)}
                    >
                      View Video at Step
                    </button>
                  </div>
                  <div>
                    <div className="stepComponents">
                      <h2 className="semi-bold">Components</h2>
                      {step.components.map((item) => (
                        <p>{`- ${item}`}</p>
                      ))}
                    </div>
                    <div className="stepInstructions">
                      <h2 className="semi-bold">Instructions</h2>
                      {step.subSteps.map((subStep) => (
                        <p>{subStep}</p>
                      ))}
                    </div>
                    {!step.notes ? null : (
                      <div className="stepInstructions">
                        <h2 className="semi-bold">Notes:</h2>
                        {step.notes.map((note) => (
                          <p>{note}</p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </Accordion>
            ))}
          </div>

          {/* //// Transcript //// */}
          <div label="Transcript">
            <div className="transcriptContainer">
              <div className="transcript">
                {transcript.map((paragraph) => (
                  <p className="transcriptParagraph">{paragraph}</p>
                ))}
              </div>
            </div>
          </div>

          {/* //// Tips //// */}
          <div label="Tips">
            <div className="tipContainer">
              {tips.map((tip) => (
                <p>{tip}</p>
              ))}
            </div>
          </div>

          {/* //// Materials //// */}
          <div label="Materials">
            <div className="materialsContainer">
              <div className="materials">
                <h1>Materials</h1>
                {assemblyMaterials.materials.map((item) => (
                  <p>{`- ${item}`}</p>
                ))}
              </div>
              <div className="materials">
                <h1>Tools (Not Included)</h1>
                {assemblyMaterials.tools.notIncluded.map((tool) => (
                  <p>{`- ${tool}`}</p>
                ))}
              </div>
            </div>
          </div>
        </TabList>
      </div>
      <div id="mainSetupVideo">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <ReactPlayer url={videoURL} controls playing={autoPlay} />
      </div>
    </div>
  );
};

export default NodeSetup;
