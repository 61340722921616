/**
 * Hook provides user authentication through a google account for the app.
 * Currently, user must have a google account for it to be functional
 */

import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useGoogleLogin } from 'react-use-googlelogin';
import { verifyTokenAuth } from '../utils/auth';
import axios from 'axios';

const GoogleAuthContext = React.createContext();

export const GoogleAuthProvider = ({ children }) => {
  const googleAuth = useGoogleLogin({
    clientId: process.env.REACT_APP_GEMS_IOT_GOOGLE_CLIENT_ID, // Your clientID from Google.
  });

  //Verify the google token and create a new jwt from it
  const getVerifications = async () => {
    if (googleAuth?.googleUser?.tokenId) {
      const response = await verifyTokenAuth(
        googleAuth?.googleUser?.tokenId
      );
      if (response?.token) {
        sessionStorage.setItem('token', response?.token);
        //setting axios header here
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${response.token}`;
      } else {
        googleAuth.refreshUser();
      }
    }
  };

  useEffect(() => {
    getVerifications();
  });

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {children}
    </GoogleAuthContext.Provider>
  );
};

export const useGoogleAuth = () => useContext(GoogleAuthContext);
