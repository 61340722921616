/**
 * Component creates a dropdown allowing user to choose b/t several options.
 * Needs setSelected (function) and selectedOption (variable) props to function.
 * The props for this component should be an array of options.
 * Will accept objects with specific keys (this is not ideal):
 * - name
 * - sensor_type
 * - node_id
 *
 * TODO: Eventually this should be more reusable and less brittle.
 */

import React, { useState, useEffect, useRef } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import './DropdownSelect.css';

const DropdownSelect = (props) => {
  const [toggled, toggleDropdown] = useState(false);
  const { data, setSelected, selectedOption, nodes   } = props;
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      // check if element that was clicked is inside of ref'd component
      // if so no action is required from this event listener to exit
      if (ref.current.contains(event.target)) {
        return;
      }
      // else close the dropdown
      toggleDropdown(false);
    };

    document.body.addEventListener('mouseout', onBodyClick);

    // cleanup
    return () => {
      document.body.removeEventListener('mouseout', onBodyClick);
    };
  }, []);

  // ConsoleLogger('this is SO on DDS', selectedOption.hardware);

  // This function returns the menu of options in the dropdown.
  const dropdownChoices = () => {
    if (!nodes) {
      return data.map((item, i) => (
        <button
          key={i}
          type="button"
          className="dropdownOption "
          onClick={() => {
            toggleDropdown(!toggled);
            setSelected(item);
          }}
        >
          <p>
            {item
              ? item.name || item.sensor_type || item.node_id || item
              : 'Loading'}
          </p>
        </button>
      ));
    }
    return nodes.map((item, i) => (
      <button
        key={i}
        className="dropdownOption "
        onClick={() => {
          toggleDropdown(!toggled);
          setSelected(item);
        }}
      >
        <p>
          {item
            ? item.name || item.sensor_type || item.node_id || item.id
            : 'Loading'}
        </p>
      </button>
    ));
  };

  return (
    <div className="dropdownContainer" ref={ref}>
      <button
        className="blue white-text dropdownOption"
        onClick={() => toggleDropdown(!toggled)}
      >
        {selectedOption
          ? selectedOption.name
            || selectedOption.sensor_type
            || selectedOption.node_id ||
            selectedOption
          : 'Loading'}
        <IoIosArrowDown />
      </button>

      {/* If toggled is true, render dropdownChoices */}
      {toggled && (
        <div className="dropdownChoices">{dropdownChoices()}</div>
      )}
    </div>
  );
};

export default DropdownSelect;
