/**
 * This map library should probably be repolaced with Open Street Maps and Leaflet.
 */

import * as React from 'react';
import { useState } from 'react';
import ReactMapGL from 'react-map-gl';
import './Map.css';

export function Map(props) {
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8,
  });
  const { hidden, zoom, height, width   } = props;

  if (hidden === false) {
    return (
      <ReactMapGL
        {...viewport}
        scrollZoom={zoom}
        height={height}
        width={width}
        className="mapStyles"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
      />
    );
  }

  return null;
}
