import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../../hooks/dataContext';
import { CSVLink } from 'react-csv';
import DotLoader from 'react-spinners/DotLoader';
import { Button, Typography } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { isWithinInterval, parseJSON } from 'date-fns';

const DownloadProjectCSV = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { projectNodes, loading } = state;
  const { project } = props;

  const [startDate, setStartDate] = useState(
    parseJSON(project.start_date)
  );
  const [endDate, setEndDate] = useState(
    project.end_date ? parseJSON(project.end_date) : null
  );

  const [csvData, setCsvData] = useState([]);
  const [filteredCsvData, setFilteredCsvData] = useState([]);

  const csvFileName = () =>
    `Project ${project.name} - ${new Date().toISOString()}.csv`;

  useEffect(() => {
    if (loading || !projectNodes) return;

    // flatten nested project -> node -> data structure into array with data formatted for csv keys.
    let csvData = projectNodes.reduce((combined, node) => {
      if (!node || !node.data) return combined;
      const nodeCSV = node.data.map((reading) => ({
        ...formatProjectCSV(project),
        ...formatNodeCSV(node),
        ...formatNodeDataCSV(reading),
      }));
      return [...combined, ...nodeCSV];
    }, []);

    setCsvData(csvData);
  }, [project, projectNodes, loading]);

  useEffect(() => {
    // project data contains all data. Filter data to fall between start and end date
    setFilteredCsvData(
      csvData.filter((reading) => {
        const start = startDate;
        const end = endDate || new Date();
        const timeOfReading = parseJSON(reading.data_time_of_reading);
        return isWithinInterval(timeOfReading, { start, end });
      })
    );
  }, [startDate, endDate, csvData]);

  const projectHeaders = [
    { label: 'Project ID', key: 'id' },
    { label: 'Project Name', key: 'name' },
    { label: 'Project Start', key: 'start_date' },
    { label: 'Project End', key: 'end_date' },
    { label: 'Project Polygon', key: 'polygon' },
    { label: 'Project is Public', key: 'is_public' },
    { label: 'Project Metadata', key: 'metadata' },
  ];

  const nodeHeaders = [
    { label: 'Node ID', key: 'node_node_id' },
    { label: 'Node is Public', key: 'node_is_public' },
    { label: 'Node Metadata', key: 'node_metadata' },
    { label: 'Node Tags', key: 'node_tags' },
  ];

  const nodeDataHeaders = [
    { label: 'Reading ID', key: 'data_level0_id' },
    { label: 'Reading Parameter', key: 'data_parameter' },
    { label: 'Reading Value', key: 'data_data' },
    { label: 'Time of Reading', key: 'data_time_of_reading' },
    { label: 'Reading Co-ordinates', key: 'data_point' },
    { label: 'Reading Elevation', key: 'data_elevation' },
    { label: 'Reading Metadata', key: 'data_metadata' },
  ];

  const csvHeaders = [
    ...projectHeaders,
    ...nodeHeaders,
    ...nodeDataHeaders,
  ];

  const formatNodeDataCSV = (data) => ({
    data_level0_id: data.level0_id,
    data_node_id: data.node_id,
    data_parameter: data.parameter,
    data_data: data.data,
    data_time_of_reading: data.time_of_reading,
    data_point: data.point,
    data_elevation: data.elevation,
    data_metadata: JSON.stringify(data.metadata).replace(/"/gm, '""'),
  });

  const formatNodeCSV = (node) => ({
    node_node_id: node.node_id,
    node_owner: node.owner,
    node_is_public: node.is_public,
    node_metadata: JSON.stringify(node.metadata).replace(/"/gm, '""'),
    node_tags: node.tags,
  });

  const formatProjectCSV = (project) => ({
    ...project,
    metadata: JSON.stringify(project.metadata).replace(/"/gm, '""'),
  });

  const handleCSVDownload = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  const onCancel = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  return (
    <form className="csvNodeModal" onSubmit={handleCSVDownload}>
      <Typography gutterBottom variant="h5">
        Download Project Data (.CSV)
      </Typography>
      <Typography variant="subtitle1">
        Filter data by date range (optional)
      </Typography>
      <DateTimePicker
        format="dd MMM yyyy 'at' h:mm a"
        margin="normal"
        label="Start Date"
        minDate={parseJSON(project.start_date)}
        maxDate={parseJSON(project.end_date)}
        value={startDate}
        clearable
        onChange={(startDate) => setStartDate(startDate)}
      />
      <DateTimePicker
        format="dd MMM yyyy 'at' h:mm a"
        margin="normal"
        label="End Date"
        minDate={startDate}
        maxDate={parseJSON(project.end_date)}
        value={endDate}
        clearable
        onChange={(endDate) => setEndDate(endDate)}
      />
      <div className="editFormButtons">
        {loading ? (
          <DotLoader size={20} color="#006888" />
        ) : (
          <div>
            <Button onClick={onCancel} type="button">
              Cancel
            </Button>
            <CSVLink
              data={filteredCsvData}
              headers={csvHeaders}
              onClick={handleCSVDownload}
              id="csvProject"
              filename={csvFileName()}
            >
              <button type="button" className="blue white-text">
                Download
              </button>
            </CSVLink>
          </div>
        )}
      </div>
    </form>
  );
};

export default DownloadProjectCSV;
