import axios from 'axios';
import ConsoleLogger from '../hooks/ConsoleLogger';

export const getAllNodeData = async (node) => {
  return await axios
    .get(
      `${process.env.REACT_APP_IOT_SERVER}/level0data`,
      {
        params: {
          node_id: node.node_id,
        },
      },
      {
        rejectUnauthorized: false,
        withCredentials: false,
      }
    )
    .then((response) => {
      ConsoleLogger('node data recieved');

      return response?.data?.records;
    })
    .catch((err) => {
      ConsoleLogger(
        'Theres been an error retrieving node dates',
        err
      );
    });
};

export const getLevelZeroData = async (
  nodeId,
  startDate,
  endDate
) => {
  return await axios
    .get(`${process.env.REACT_APP_IOT_SERVER}/level0data`, {
      params: {
        node_id: nodeId,
        start_date: startDate
          ? new Date(startDate).toISOString()
          : null,
        end_date: endDate ? new Date(endDate).toISOString() : null,
      },
    })
    .then((response) => {
      ConsoleLogger(
        'This is level0data / node data',
        response.data.records
      );
      return response.data.records;
    })
    .catch((err) => {
      ConsoleLogger(
        'Theres been an error retrieving level0data',
        err
      );
    });
};
