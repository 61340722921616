import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { store } from '../../../hooks/dataContext';
import DotLoader from 'react-spinners/DotLoader';
import {
  getNodeList,
  removeNodeUsers,
} from '../../../utils/node.api';
import {
  Button,
  Chip,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { toBeValid } from '@testing-library/jest-dom';
import { isArray } from 'lodash';

const LeaveSharedNode = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { node } = props;
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  const handleRemoveCurrentUser = async () => {
    setLoading(true);

    const requestPayload = {
      node_ids: '' + node.id,
      user_ids: state.user.id,
    };

    removeNodeUsers(requestPayload).then((x) => {
      setLoading(false);
      getNodeList(state, dispatch);
      toast.success('Node user removed', {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {},
      });
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    await handleRemoveCurrentUser();
  };

  return (
    <form className="csvNodeModal" onSubmit={submit}>
      <div className="sharingLabelBlock">
        <div className="sharingLabelBlockContent">
          <div className="sharingLabelBlockContentLeft">
            <Typography>{node.node_id}</Typography>
            <Typography>{node.name}</Typography>
          </div>

          <br />
        </div>
        <div className="sharingLabelBlockContentLeftBl">
          <Typography>
            Confirm you no longer want to have this node shared with
            you.
          </Typography>
        </div>
      </div>

      <div className="editFormButtons">
        {loading ? (
          <DotLoader size={20} color="#006888" />
        ) : (
          <div>
            <Button onClick={onCancel} type="button">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Confirm
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default LeaveSharedNode;
