import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../AddProject/components/NodeSetup/NodeSetupRedirect.css';

const NodeQuestions = () => {
  //   const { setActiveNumber } = props;
  const [questionStep, setQuestionStep] = useState(null);

  const nodeSetupSwitch = () => {
    switch (questionStep) {
      case 'setup':
        return (
          <div className="nodeButtonArea">
            <p className="semi-bold">
              Is your node(s) already assembled?
            </p>
            <div>
              <button
                className="blue white-text"
                onClick={() => setQuestionStep('register')}
              >
                Yes
              </button>
              <button className="green white-text">
                <Link
                  className="white-text"
                  to="/devicesetup/assemble"
                >
                  No
                </Link>
              </button>
            </div>
          </div>
        );
      case 'register':
        return (
          <div className="nodeButtonArea">
            <p className="semi-bold">
              Is your node (or nodes) already registered?
            </p>
            <div>
              <button
                className="blue white-text"
                // onClick={() => setActiveNumber(2)}
              >
                <Link className="white-text" to="/devicesetup/deploy">
                  Yes
                </Link>
              </button>
              <button className="green white-text">
                <Link
                  className="white-text"
                  to="/devicesetup/register"
                >
                  No
                </Link>
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="nodeButtonArea">
            <p className="semi-bold">Do you have nodes?</p>
            <div>
              <button
                className="blue white-text"
                onClick={() => setQuestionStep('setup')}
              >
                Yes
              </button>
              <button className="green white-text">
                <Link className="white-text" to="/order">
                  No
                </Link>
              </button>
            </div>
          </div>
        );
    }
  };
  return (
    <div className="nodeSetupRedirectContainer">
      {nodeSetupSwitch()}
    </div>
  );
};

export default NodeQuestions;
