/**
 * Popup for eventual hamburger menu when viewing on a phone.
 */

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../../hooks/dataContext';

const MobileMenu = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  return (
    <div className="mobileModal">
      <Link
        className="nav-link account"
        to="/devicesetup/register"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: null,
            },
          });
        }}
      >
        Setup
      </Link>
      <Link
        className="nav-link account"
        to="/help"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: null,
            },
          });
        }}
      >
        Help
      </Link>
      <Link
        className="nav-link account"
        to="/login"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: null,
            },
          });
        }}
      >
        Logout
      </Link>
      <button
        className="blue white-text"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: null,
            },
          });
        }}
      >
        Close
      </button>
    </div>
  );
};

export default MobileMenu;
