/**
 * AreYouSure is a popup that asks the user to confirm before placing a hardware/node order.
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';

const AreYouSure = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <div className="quantityModal">
      <p>
        After you Place Order, your order will be submitted for
        review. Within 3-5 business days, a member of our team will
        contact you with a price and necessary steps to complete your
        order.
      </p>
      <button
        className="blue white-text"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
      >
        Place Order
      </button>
    </div>
  );
};

export default AreYouSure;
