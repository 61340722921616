/**
 * Popup that asks user to confirm quantitiy of a specific config of hardware to add to order.
 */

import React, { useState, useContext } from 'react';
import Sensor8 from '../../../assets/sensor8.jpg';
import { store } from '../../../hooks/dataContext';

const picWidth = '10vw';

const ConfirmQuantity = (props) => {
  const { data } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [nodeBlob, setNodeBlob] = useState({
    quantity: 0,
  });

  return (
    <div className="quantityModal">
      <div className="sensorListOnQuantity">
        <img
          src={Sensor8}
          alt="Field sprouting in the morning"
          style={{
            width: `${picWidth}`,
            boxShadow: '10px 10px 18px 18px white inset',
          }}
        />
        <div className="sensorsOnNode">
          <h1 className="semi-bold"> Sensors on this node:</h1>
          {data.map((sensorType) => {
            switch (sensorType) {
              case 'airTemp':
                return <p key={sensorType}>Air Temperature</p>;
              case 'humidity':
                return <p key={sensorType}>Humidity</p>;
              case 'barometricPressure':
                return <p key={sensorType}>Barometric Pressure</p>;
              case 'soilTemp':
                return <p key={sensorType}>Soil Temperature</p>;
              case 'soilMoist':
                return <p key={sensorType}>Soil Moisture</p>;
              case 'windSpeed':
                return <p key={sensorType}>Wind Speed</p>;
              case 'windDirection':
                return <p key={sensorType}>Wind Directions</p>;
              case 'co2':
                return <p key={sensorType}>Carbon Dioxide</p>;
              case 'oxygen':
                return <p key={sensorType}>Oxygen</p>;
              case 'PAR':
                return <p key={sensorType}>PAR</p>;
              case 'solarRadiation':
                return <p key={sensorType}>Solar Radiation</p>;
              default:
                return <p key={sensorType}>{sensorType}</p>;
            }
          })}
        </div>
      </div>
      <p>
        Please enter quantity of nodes with selected sensors that you
        want.
      </p>
      <div className="orderQuantityInput">
        <input
          type="number"
          onChange={(e) => {
            setNodeBlob({
              nodeSensors: data,
              quantity: Number(e.target.value),
            });
          }}
        />
        <button
          className="blue white-text"
          onClick={() => {
            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                data: {},
              },
            });
            dispatch({
              type: 'SEND_TO_INVOICE_NODE',
              payload: nodeBlob,
            });
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConfirmQuantity;
