/**
 * Generic success popup
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';

const Success = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <div>
      <h2>Success!</h2>
      <button
        className="blue white-text"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
      >
        Close
      </button>
    </div>
  );
};

export default Success;
