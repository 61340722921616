/**
 * Allows user to enter project metadata fields.
 * Props: project (obj) and setProject (func)
 *
 * Easily expandable for more metadata options.
 */

import React from 'react';
import './ProjectInformation.css';
import { TextField, Paper } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

const ProjectInformation = (props) => {
  const { project, setProject } = props;

  return (
    <Paper elevation={3} className="projectInfoContainer">
      <TextField
        fullWidth
        required
        label="Project Name"
        placeholder={project.project_name}
        onChange={(e) =>
          setProject({ ...project, project_name: e.target.value })
        }
      />
      <br />
      <DateTimePicker
        format="dd MMM yyyy 'at' h:mm a"
        margin="normal"
        label="Start Date"
        fullWidth
        value={project.start_date}
        clearable
        required
        onChange={(startDate) =>
          setProject({ ...project, start_date: startDate })
        }
      />
      <DateTimePicker
        fullWidth
        format="dd MMM yyyy 'at' h:mm a"
        margin="normal"
        label="End Date (optional)"
        value={project.end_date}
        placeholder="End Date (optional)"
        clearable
        onChange={(endDate) =>
          setProject({ ...project, end_date: endDate })
        }
      />
      <br />
      <TextField
        fullWidth
        label="Crop (optional)"
        placeholder={'Crop (optional)' || project.metadata.crop}
        onChange={(e) =>
          setProject({
            ...project,
            metadata: { ...project.metadata, crop: e.target.value },
          })
        }
      />
      <br />
      <TextField
        label="Summary (optional)"
        fullWidth
        placeholder={'Summary (optional)' || project.enddate}
        onChange={(e) =>
          setProject({
            ...project,
            metadata: {
              ...project.metadata,
              summary: e.target.value,
            },
          })
        }
      />
      <br />
    </Paper>
  );
};

export default ProjectInformation;
