/**
 * Generic popup for errors.
 * TODO: Create a way to dyamically render errors returned from database too/instead
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';

const ModalError = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  return (
    <div className="errorModal">
      <h2>Something isn't right.</h2>
      <p>
        You might need to login. If that's not it, you probably need
        authorization to proceed.
      </p>
      <button
        className="blue white-text"
        onClick={() =>
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: null,
            },
          })
        }
      >
        Close
      </button>
    </div>
  );
};

export default ModalError;
