import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';
import { decommissionNode } from '../../../utils/node.api';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const DecommissionNode = (props) => {
  const history = useHistory();
  const { data } = props;
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  return (
    <div>
      <p>Are you sure you want to decommission this node?</p>
      <p>This cannot be undone.</p>
      <br />
      <Button
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
        type="button"
      >
        <p>No</p>
      </Button>
      <Button
        type="button"
        variant="contained"
        color="primary"
        className="blue white-text"
        onClick={() => {
          decommissionNode(data.id, state.user.id, state, dispatch);
          // history.push('/projectlist');
        }}
      >
        <p>Yes I'm sure</p>
      </Button>
    </div>
  );
};

export default DecommissionNode;
