/**
 * Section that displays two graphs. Each one user can control independently.
 * If node prop is true, user is only given a dropdown to select from hardware on node.
 * If node prop is false, user has two dropdowns. One for node and one for hardware on node.
 */

import React, { useEffect, useRef, useState } from 'react';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Brush,
} from 'recharts';
// import CSVButton from '../../CSVButton/CSVButton';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import './GraphSection.css';
import { format, isWithinInterval, parseJSON, sub } from 'date-fns';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DotLoader from 'react-spinners/DotLoader';
import { CalendarToday, GetApp, ShowChart } from '@material-ui/icons';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  downloadCSVButton: {
    marginLeft: '10px',
  },
  showReadingLabel: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginRight: '5px',
  },
  showReadingIcon: {
    marginRight: '5px',
  },
}));

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const GraphSection = (props) => {
  const classes = useStyles();
  const {
    node,
    allData,
    getAllNodeData,
    downloadCSV,
    updateViewingFrom,
  } = props;
  const [selectedNode, setSelectedNode] = useState(node);
  const [nodeData, setNodeData] = useState();
  const [parameterList, setParameterList] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState();
  const prevSelectedParameter = usePrevious(selectedParameter);
  const [datePreset, setDatePreset] = useState(null); // day | week | month | all

  const getAvailablePresets = () => {
    if (!allData) return {};
    const [, lastReading] =
      allData[selectedNode.id]?.timestampRange || [];

    if (!lastReading) return {};
    return {
      day: lastReading > sub(new Date(), { days: 1 }),
      week: lastReading > sub(new Date(), { weeks: 1 }),
      month: lastReading > sub(new Date(), { months: 1 }),
      all: true,
    };
  };
  const [availablePresets, setAvailablePresets] = useState(
    getAvailablePresets()
  );
  const [chartData, setChartData] = useState();

  const allDataQueried =
    allData && selectedNode && allData[selectedNode?.id]?.isAll;

  useEffect(() => {
    if (selectedNode?.id) {
      setNodeData({ ...allData[selectedNode.id]?.data });
      const presets = getAvailablePresets();
      setAvailablePresets(presets);
      if (!datePreset)
        setDatePreset(
          presets['day']
            ? 'day'
            : presets['week']
            ? 'week'
            : presets['month']
            ? 'month'
            : allDataQueried
            ? 'all'
            : undefined
        );
    }
  }, [selectedNode, allData]);

  useEffect(() => {
    if (nodeData) {
      const parameters = Object.keys(nodeData).sort();
      setParameterList(parameters);
      if (!selectedParameter) setSelectedParameter(parameters[0]);
    }
  }, [nodeData]);

  useEffect(() => {
    if (!nodeData || !selectedParameter) return;
    const parameterData = nodeData[selectedParameter]?.data || [];
    const [parameterStart, parameterEnd] =
      nodeData[selectedParameter]?.dateRange;
    let filterStart,
      filterEnd = new Date(parameterEnd);
    switch (datePreset) {
      case 'day':
        filterStart = sub(filterEnd, { days: 1 });
        break;
      case 'week':
        filterStart = sub(filterEnd, { days: 7 });
        break;
      case 'month':
        filterStart = sub(filterEnd, { months: 1 });
        break;
      case 'all':
        filterStart = null;
        break;
      default:
        filterStart = filterEnd;
        break;
    }

    // we need to ensure parameterStart is before filterStart before showing a chart.
    if (
      !!parameterStart &&
      (!filterStart ||
        new Date(parameterStart).getTime() > filterStart.getTime())
    ) {
      setChartData(null);
      if (!filterStart) getAllNodeData(selectedNode);
      return;
    }
    const filtered = parameterData
      .filter(
        ({ time_of_reading }) =>
          !filterStart ||
          isWithinInterval(parseJSON(time_of_reading), {
            start: filterStart,
            end: filterEnd,
          })
      )
      .map(({ time_of_reading, data }) => ({
        label: parseJSON(time_of_reading).getTime(),
        data,
      }))
      .sort((a, b) => a.label - b.label);

    // If there is data, update the viewingFrom date to the very first element shown on the graph.
    if (filtered.length > 0)
      updateViewingFrom(new Date(filtered[0].label));
    if (chartDataShouldUpdate(filtered)) {
      setChartData(filtered);
    }
  }, [selectedParameter, nodeData, datePreset]);

  const chartDataShouldUpdate = (incoming) => {
    // only update chartData if the graph needs to change to avoid unnecessary rerenders
    if (selectedParameter !== prevSelectedParameter) return true;
    if (incoming.length === 0) return false;
    const firstLabelOf = (d) => d && d[0]?.label;
    const lastLabelOf = (d) => d && d[d.length - 1]?.label;
    if (
      firstLabelOf(incoming) === firstLabelOf(chartData) &&
      lastLabelOf(incoming) === lastLabelOf(chartData)
    )
      return false;

    return true;
  };

  return (
    <div className="graphSectionContainer">
      <div className="dropdownArea">
        {!node && (
          <div className="nodeDropdownThing">
            <p className="semi-bold">Node: </p>
            <DropdownSelect
              data={[]}
              nodes={[]}
              setSelected={setSelectedNode}
              selectedOption={undefined}
            />
          </div>
        )}
        <div className="parameterDropdown">
          <p className="semi-bold">Parameter: </p>
          <DropdownSelect
            data={parameterList}
            setSelected={setSelectedParameter}
            selectedOption={selectedParameter}
          />
        </div>
        <div className="datePreset">
          <Typography
            className={classes.showReadingLabel}
            color="primary"
          >
            <CalendarToday
              className={classes.showReadingIcon}
              fontSize="small"
            />{' '}
            SHOW READING FOR:
          </Typography>
          <ToggleButtonGroup
            id="dateRangePreset"
            value={datePreset}
            color="primary"
            exclusive
            onChange={(e, v) => setDatePreset(v)}
            aria-label="text alignment"
          >
            <ToggleButton
              value="day"
              aria-label="last day"
              disabled={!availablePresets['day']}
            >
              24 Hours
            </ToggleButton>
            <ToggleButton
              value="week"
              aria-label="last week"
              disabled={!availablePresets['week']}
            >
              7 Days
            </ToggleButton>
            <ToggleButton
              value="month"
              aria-label="last month"
              disabled={!availablePresets['month']}
            >
              1 Month
            </ToggleButton>
            <ToggleButton
              value="all"
              aria-label="justified"
              disabled={!availablePresets['all']}
            >
              all
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            className={classes.downloadCSVButton}
            color="primary"
            onClick={() => downloadCSV && downloadCSV()}
          >
            <GetApp /> Download CSV
          </Button>
        </div>
      </div>
      <div className="graphSection">
        {!chartData ? (
          allDataQueried ? (
            <div className="graphLoading">
              <ShowChart fontSize="large"></ShowChart>
              <p>No data</p>
            </div>
          ) : (
            <div className="graphLoading">
              <DotLoader size={50} color="#006888" />
            </div>
          )
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={{
                right: 100,
                left: 100,
                bottom: 10,
              }}
              data={chartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Line
                animationDuration={700}
                type="monotone"
                dataKey="data"
                stroke="#8884d8"
                dot={chartData.length < 100}
              />
              <XAxis
                dataKey="label"
                domain={['dataMin', 'dataMax']}
                name="Time"
                type="number"
                tickFormatter={(v) => {
                  try {
                    return format(
                      new Date(v),
                      "dd MMM yyyy 'at' h:mm a"
                    );
                  } catch (error) {}
                }}
              />
              <YAxis
                label={{
                  value: selectedParameter,
                  position: 'insideLeft',
                  offset: -20,
                  angle: -90,
                }}
                domain={['auto', 'auto']}
              />
              <Tooltip
                formatter={(value, name, props) => [value, 'Value']}
                labelFormatter={(v) => {
                  try {
                    return format(
                      new Date(v),
                      "dd MMM yyyy 'at' h:mm a"
                    );
                  } catch (error) {}
                }}
              />
              <Brush
                tickFormatter={(v) => {
                  return format(
                    new Date(chartData[v].label),
                    'h:mm a dd MMM yyyy'
                  );
                }}
                onChange={({ startIndex, endIndex }) => {
                  updateViewingFrom(
                    new Date(chartData[startIndex].label)
                  );
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default GraphSection;
