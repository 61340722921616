import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import NotFound from './pages/NotFound/NotFound';
import AddProject from './pages/AddProject/AddProject';
import ProjectList from './pages/ProjectList/ProjectList';
import ViewProject from './pages/ViewProject/ViewProject';
import Nodes from './pages/Nodes/Nodes';
import Setup from './pages/Setup/Setup';
import Help from './pages/Help/Help';
import User from './pages/User/User';
import Landing from './pages/LandingPage/LandingPage';
import OrderPage from './pages/OrderPage/OrderPage';
import AddNode from './pages/AddNode/AddNode';
import Login from './pages/Login/Login';
import Footer from './components/Footer/Footer';
import Legal from './pages/Legal/Legal';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { DataContext } from './hooks/dataContext';
import { ModalProvider } from './components/Modal/Modal';
import ViewNode from './pages/ViewNode/ViewNode';
import RegisterNode from './pages/RegisterNode/RegisterNode';
import NodeQuestions from './pages/NodeQuestions/NodeQuestions';
import Qaqc from './pages/Qaqc/Qaqc';
import './App.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Using this color configuration https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=006889&secondary.color=F7941E&primary.text.color=ffffff
const theme = createTheme({
  palette: {
    primary: {
      light: '#4a96b9',
      main: '#006889',
      dark: '#003d5c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc553',
      main: '#f7941e',
      dark: '#be6600',
      contrastText: '#000',
    },
  },
});

function App() {
  /**
   * This function returns the app.
   * The outermost wrapper is the DataContext which is a data store accessed by all the children.
   * Inside the DataContext is the Modal Provider which allows chilren acess to the popup modal.
   * Inside the Modal provider is the Router which handles the routing to each page.
   * The Router includes the Navbar, a switch to handle routing, and a footer.
   * There are 2 types of routes. The protected route requires users to be logged in.
   * Every route needs a path (for the URL) and a component to render.
   */
  return (
    <div className="App">
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <DataContext>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ModalProvider>
              <Router>
                <Navbar />
                <Switch>
                  {/* The following Routes require user to be logged in */}
                  <ProtectedRoute
                    path="/devicesetup/:step?"
                    component={Setup}
                  />
                  <ProtectedRoute path="/nodes" component={Nodes} />
                  <ProtectedRoute path="/help" component={Help} />
                  <ProtectedRoute path="/user" component={User} />
                  {/* <ProtectedRoute
                    path="/order"
                    component={OrderPage}
                  /> */}
                  <ProtectedRoute
                    path="/addNode"
                    component={AddNode}
                  />
                  <ProtectedRoute
                    path="/viewNode"
                    component={ViewNode}
                  />
                  <ProtectedRoute
                    path="/addProject"
                    component={AddProject}
                  />
                  <ProtectedRoute
                    path="/registernode"
                    component={RegisterNode}
                  />
                  <ProtectedRoute path="/qaqc" component={Qaqc} />
                  <ProtectedRoute
                    path="/nodeQuestions"
                    component={NodeQuestions}
                  />
                  {/* These routes are accessible to all public users. No login necessary. */}
                  <Route
                    path="/viewProject"
                    component={ViewProject}
                  />
                  <Route path="/project" component={ViewProject} />
                  <Route
                    path="/projectlist"
                    component={ProjectList}
                  />
                  <Route path="/legal" component={Legal} />
                  <Route path="/landing" component={Landing} />
                  <Route path="/login" component={Login} />
                  <Route exact path="/" component={Landing} />
                  <Route component={NotFound} />
                </Switch>
                <Footer />
              </Router>
            </ModalProvider>
          </MuiPickersUtilsProvider>
        </DataContext>
      </ThemeProvider>
    </div>
  );
}

export default App;
