/**
 * Asks users a series of Qs about their nodes and redirects them to order/assemble/register
 * Props: activeStepNumber
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Paper } from '@material-ui/core';

import './NodeSetupRedirect.css';

const NodeSetup = (props) => {
  const { setActiveNumber } = props;
  const [questionStep, setQuestionStep] = useState(null);

  const nodeSetupSwitch = () => {
    switch (questionStep) {
      case 'setup':
        return (
          <div className="nodeButtonArea">
            <h1>Is your node (or nodes) already assembled?</h1>
            <div className="nodeButtons">
              <Link className="white-text" to="/devicesetup/assemble">
                <Button variant="contained" color="gray">
                  No, show me how
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setQuestionStep('register')}
              >
                Yes
              </Button>
            </div>
          </div>
        );
      case 'register':
        return (
          <div className="nodeButtonArea">
            <h1>Is your node (or nodes) already registered?</h1>
            <div className="nodeButtons">
              <Link className="white-text" to="/devicesetup/register">
                <Button variant="contained" color="gray">
                  No, show me how
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setActiveNumber(2)}
              >
                Yes
              </Button>
            </div>
          </div>
        );
      default:
        return (
          <div className="nodeButtonArea">
            <h1>Do you already have one or more nodes?</h1>
            <div className="nodeButtons">
              <Link className="white-text" to="/help">
                <Button variant="contained" color="gray">
                  No
                </Button>
              </Link>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setQuestionStep('setup')}
              >
                Yes
              </Button>
            </div>
          </div>
        );
    }
  };
  return (
    <div>
      <Paper className="nodeSetupRedirectContainer" elevation={3}>
        {nodeSetupSwitch()}
      </Paper>
    </div>
  );
};
export default NodeSetup;
