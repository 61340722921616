import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ProjectCard from './ProjectCard/ProjectCard';
import { store } from '../../hooks/dataContext';
import { getProjects } from '../../utils/projects.api';
import TabList from '../../components/TabList/TabList';
import { useGoogleAuth } from '../../hooks/GoogleAuth';
import { project } from '../../utils/constants';
import './ProjectList.css';
import { Button } from '@material-ui/core';
import { getNodeList } from '../../utils/node.api';

function ProjectList() {
  const { state, dispatch } = useContext(store);
  const { projects } = state;
  const { isSignedIn } = useGoogleAuth();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 650 });
  const [userProjects, setUserProjects] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getAllProjects();
    filterUserProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterUserProjects();
  }, [state.user, projects]);

  const getAllProjects = async () => {
    await getProjects(dispatch, history);
    getNodeList(state, dispatch);
  };

  const filterUserProjects = () => {
    const userProjectArray = projects.filter((project) => {
      if (project.owner && state.user.id === project.owner) {
        return project;
      }
    });
    setUserProjects(userProjectArray);
  };

  // Creates a card for each project user has access to.
  const renderProjects = () =>
    projects.map((publicProject) => (
      <div className="projectCardMargin">
        <ProjectCard
          key={publicProject.id}
          name={publicProject.name}
          projectDesc={publicProject.description}
          startDate={publicProject.start_date}
          endDate={publicProject.end_date}
          item={publicProject}
        />
      </div>
    ));

  const renderUserProjects = () => {
    if (userProjects.length === 0) {
      return (
        <ProjectCard
          key="Test"
          name="Sample Project"
          projectDesc="This project could be yours! Click add project to get started"
          item={project}
        />
      );
    }
    return userProjects.map((userProject) => (
      <div className="projectCardMargin">
        <ProjectCard
          key={userProject.id}
          name={userProject.name}
          projectDesc={userProject.description}
          startDate={userProject.start_date}
          endDate={userProject.end_date}
          item={userProject}
        />
      </div>
    ));
  };

  return (
    <div className="page projectListContainer">
      {isSignedIn ? (
        <h1 className="semi-bold title">Projects</h1>
      ) : (
        <h1 className="semi-bold title">Public Projects</h1>
      )}
      <div
        className={
          isTabletOrMobile ? 'addProject-mobile' : 'addProject'
        }
      >
        {isSignedIn && (
          <Link to="/addProject">
            <Button variant="contained" color="primary">
              Add Project
            </Button>
          </Link>
        )}
      </div>
      <div className="tabContainer">
        {isSignedIn ? (
          <TabList>
            <div label="My Projects" className="tab-content">
              <div id="projectList">{renderUserProjects()}</div>
            </div>

            <div label="All Projects" className="tab-content">
              <div id="projectList">{renderProjects()}</div>
            </div>
          </TabList>
        ) : (
          <div id="projectList">{renderProjects()}</div>
        )}
      </div>
    </div>
  );
}

export default ProjectList;
