/**
 * Popup that prompts user confirm to toggle is_public boolean for nodes
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';
import { togglePublicNode } from '../../../utils/node.api';

const PublicNodeToggle = (props) => {
  const { data } = props;
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  return (
    <div>
      {data.is_public ? (
        <p>Are you sure you want to make this private?</p>
      ) : (
        <p>Are you sure you want to make this public?</p>
      )}

      <button
        className="blue white-text"
        onClick={() => {
          togglePublicNode(data, state, dispatch);
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
      >
        Confirm
      </button>
    </div>
  );
};

export default PublicNodeToggle;
