/**
 * Popup that prompts user confirm to toggle is_public boolean for projects
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';
import { togglePublicProject } from '../../../utils/projects.api';

const PublicToggle = (props) => {
  const { data } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <div>
      {data.is_public ? (
        <p>Are you sure you want to make this private?</p>
      ) : (
        <p>Are you sure you want to make this public?</p>
      )}

      <button
        className="blue white-text"
        onClick={() => {
          togglePublicProject(data, dispatch);
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
      >
        Confirm
      </button>
    </div>
  );
};

export default PublicToggle;
