/**
 * Use hotkeys/shortcut to collapse all to make this more manageable.
 * File (should) contain(s) all constant variables.
 */

const nodeSetup = {
  steps: [
    // Step one
    {
      title:
        'Step One: ~ Assembling the Radiation Shield [4:02 - 19:21]',
      url: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=242s',
      components: [
        'Data Logger Unit',
        'Soil Moisture Interface Units',
        'Hook Up Connections',
        'Housings',
      ],
      subSteps: [
        '1.1 Take part of housing with a larger area. Put the top side of black wire where housing can snap into it and insert the wire. If you insert the wire incorrectly, it will not lock. Tug the wire lightly - if it stays in place, you did it correctly.',
        '1.2 Place all wires in the housing. From left to right: green, white, red, black',
        '1.3 Repeat the process for the other side. Create four total',
      ],
      notes: [
        'In Assembling the Radiation Shield: Step 2 - The threaded insert should sit flush to the top of the plastic plate, do not worry if it does not seat down further.',
        'After the conclusion of Assembling the Radiation Shield: Step 6 - Secure the cables to the body of the radiation shield as shown here ',
      ],
    },
    // Step two
    {
      title: 'Step Two: ~ Internal Connections [19:22 - 32:04]',
      url: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=1162s',
      components: [
        'Washers',
        '#4 Thread Cutting Scres (6)',
        '#2 thread cutting screws (10)',
        'Cable glands (5)',
        'Mounting Plate (10)',
        'Charge Control Unit',
        'Housings',
      ],
      subSteps: [
        '2.1 Mount battery charge controller using a washer under each hole, place the charge controller unit on top, and use the #2 thread cutting screws and the #1 phillips screwdriver.',
        'Be careful to not overtighten the screws whichcan strip out the material',
        '2.2. Mount data logger to mounting plate using the same procedure as 2.1.',
        '2.3 Attach soil moisture interface units to back using the #2 thread cutting screw.',
        '2.4 Mount the battery with the switch and connectors toward the edge using the #4 thread cutting screws.',
      ],
      notes: [
        'In video Internal Connections: Step 3 - The number of “#2 Short Thread Cutting Screws” given in the callout at the beginning is incorrect. Only two (2) are required for this step.',
      ],
    },
    // Step three
    {
      title: 'Step Three: ~ Box Assembly [32:05-40:49]',
      url: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=1925s',
      components: [],
      subSteps: [
        '3.1. Connect logger to soil moisture interface units with black connected to ground using the 1.8mm flathead screwdriver to screw down.. Repeat this for all three',
        '3.2 Connect cables to logger. Black to ground.Screw down.',
        '3.3. Connect data connection from charge controller board to the main logger board. Black to ground.',
        '3.4. Make the power connections. Connect output of the black wire to the minus on the charge controller board to bat + and minus. Be sure that there is no copper exposed.',
        '3.5. Make the power connection from the charge controller to the batteries. Make sure the switch is in the off position. Connect the blue in the minus and yellow to positive. Then connect it to the charge controller.',
        '3.6. Input cable glands. Use adjustable wrench to tighten snug.',
      ],
    },
    // Step four
    {
      title: 'Step Four: ~ Last Electrical Assembly [40:50 - End]',
      url: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=2450s',
      components: [],
      subSteps: [
        '4.1 Use wire cutter to trim soil moisture wires to ¼ inch. Repeat for all soil moisture nodes.',
        '4.2. Strip soil temperature nodes. Twist copper to reduce wire fraying.',
        '4.3. Remove solar panel plug (because it won’t fit through cable glands).',
        '4.4. Thread nodes and solar panel through cable glands.',
      ],
      notes: [
        'After the conclusion of the final step (Last Electrical Assembly Step 4) - If the node is not going to be immediately registered and deployed, turn the battery off so as to prevent drain before solar power is available! The device can be plugged in for the registration step and update step, and then should remain in the off state until ready for deployment ',
      ],
    },
  ],

  // Should transcript be an object of paragraphs or one lone string?
  transcript: [
    "So here we have our mechanical batch of components of the parts that we're looking at. These are divided primarily into two batches over here. We have the three D printed parts that are going to comprise our radiation shield. And over here, we have our parts that are going to comprise our box assembly and mounting plate. The individual components. We have our solid leaf of our radiation shield. We have a pair of open leaves. These are all three D printed out of abs plastic, and we have our bottom mounting connector. And then we have our top plate as well to cover everything over for the box assembly. We have the box itself. Which has been drilled out appropriately, and we have the lid to the box as well. And then we have another three D printed part, which is the mounting plate, which will hold all the electronics within the box. So here we have an overview of the parts, which are our sensors and cables get anything with a long cable connection. So over here we have our tipping bucket rain gauge here. We have our solar panel connector. This will allow us to more easily connect the solar panel into the box. Here we have our soil moisture sensor here. We have a pair of three meter cables for connecting the sensors to the main unit over here. We have two each have a black red, green, and white crimp connector.And then we have a pair of four crimp housings. These will be used for making internal connections within the box. We have a one inch pair of black and yellow connections for making power connections in the box. And we have a pair of three inch red and black wire connectors for that same purpose. And finally, we have our soil temperature sensor as well.",

    'So here we have an overview of the printed circuit boards and other of the low level components that are going to be used in this assembly. So here we have all of the low level boards that will be used. And here we have some of the other components that will be used. So here we have our primary data logger.',

    "That will be the system that actually collects and records that from the system here, we have our battery holder and protection circuitry. That's going to act to power, the main data logger. And here we have the battery charge controller and solar charger. So this will act to allow the batteries to be charged by the solar panel.",

    'And then here we have an adapter board for our soil moisture and soil temperature sensor. Here. We have our bare metric pressure and relative humidity sensor. And here we have our hot wire anemometer cause also a wind speed sensor. Over here. We just have an SD card for local data storage, a micro SD card.',

    "And here we have our batteries, which are NCR 18, six 50 form factor lithium ion batteries. So in addition to the other parts that we've laid out, we will also be providing a solar panel for providing external remote power to the system and a boron LTE or boron two G three G depending on the region of interest.",

    "And then this will be the primary telemetry unit for the system. There'll be additional documentation on this, through particle itself. So here we have an overview of the tools which will be used in this assembly and come included in the kit. Over here. We have our precision, a slotted screwdriver. This is 1.8 millimeter slotted screwdriver.",

    'Here. We have our smaller Phillips screwdriver. This is a pH one Phillips here. We have our larger Phillips screwdriver. This is a  Phillips. And finally here we have our wrench, which will be used for attaching the cable glands to the box.',

    "So here, we're going to go through the assembly of the radiation shield laid out here are all of the parts required for that. We have all of the three D printed parts for the radiation shield itself. That is the top plate, the solid leaf, the two open leaves and the bottom connector we also have in terms of hardware, our long machine screw, our large nuts.",

    'Are threaded insert as well as to short number four thread cutting screws are too long. Number four thread cutting screws, and one number two thread cutting screw. In terms of sensors, we have our barometric pressure temperature sensor. We also have our hot wire anemometer and then to connect those, we have both of our cable connectors.',

    'In terms of tools that we will need, we will need our precision screwdriver, our small Phillips and our large Phillips screwdriver.',

    "Step. One of assembling the radiation shield is we need to connect our cables to the hot wire anemometer and to the barometric pressure, temperature and relative humidity sensor. For doing that, we're going to need both of those sensors as well as both cable connections and our precision screwdriver. So we're going to now go and connect the cable into the hot wire anemometer.",

    'One thing that should be noted whenever handling or through this assembly process of the hot wire anemometer, is that both the board here and the blue interface board are. Quite stable. However, the actual Hotwire element, everything beyond this black line here is very delicate and is very easy to break this piece off or even crack it slightly to avoid this whenever handling always handle it by the blue interface board and be mindful when moving around, not to crash this into other objects, because until we place it in the housing, it is a very delicate piece of instrumentation for connecting this.',

    "We have our colorations here of black red. Brown and orange looking at this sensor from this point of view, we'll be connecting it with starting from left to right black, red, Brown, orange. And that will be true for both of these sensors. I find it easiest to arrange the wires in the orange or that you want before inserting them.",

    "We're going to take our screwdriver and make sure. That these are loosened all of the way. So I'm rotating counterclockwise here until I feel a little resistance. That'll just make our job easier for inserting these. Now we will be inserting our wires into the connector itself. So we will just start on one end and slide each one in and then notch. The next one, one N tends to be the easiest way of doing it. Until they're fully inserted. Then we will come in with our screwdriver and tighten these down. We just want to tighten them until it's snug. No need to overtighten them go down and do that for each one. And now we want to look and make sure here that in pushing these in that no, uh, copper wire. Got pushed out and is potentially connecting across here. Now this looks very clean, so we should be good. Well, now go ahead and repeat this step for the burn metric pressure temperature relative humidity sensor, again, with the same color order going from left to right. And there we go once again, checking to make sure that no copper is jumping across here and this looks good. We now have our two completed units.",

    "This is step two. We now have here the, uh, solid leaf. Now in the top of that, there's a hole that has been placed in it. We're going to go and take our threaded insert, place that with the cut end that you can see here facing down and press it into the hole there so that it is seated within it. And then this should stay in the body of it nicely.",

    "So here, we're going over. Step three of the radiation shield assembly. We have our parts here are completed cabled up hot wire anemometer are solid leaf. Now with the threaded insert installed. As well as we have our parts to short number four thread cutting screws and our smaller Phillips screwdriver.",

    "So we're going to go here and you'll notice this slot in the top. We're going to pass our hot wire anemometer through that slot. Now we need to be careful because this area is delicate. So make sure it's passing through this slot properly. We want the white board to end up on the slotted side. Like, so, so we pass that through there between these two boards, we have this piece of plastic here.",

    "We're actually going to want to sit between them. Like, so now that we have that, we're going to make sure that you'll see, there are two holes in this plastic plate. That they need to line up with our two holes here and here, which we have currently. We're not going to take our screws and attach through those points.",

    "Again, being careful not to let this be damaged while we're doing that. We see our screw holes here. We're not going to place the screw into it and tighten this down just until. We get a snug fit and repeat with our other screw.",

    "We now have our completed top assembly. We'll rest that here, making sure it's resting on the base and not on the top.",

    "So now we're going through step four of the radiation shield assembly. Over here. We see our completed component from step three. We're not going to be mounting the top plate onto here, which will go and protect the hot wire anemometer, both in the field and for the future assembly steps. So we have those two. We also have our two long number four thread, cutting screws and our smaller, a Phillips screwdriver. So we will start here. We will take one of these and we will thread it through one of these two holes here and here and pass it through the top. Like, so we will then take this and attach to one of the corresponding legs as always being careful to not contact the hot wire anemometer. And screw that down, not quite tight so that we have a little bit of play here so that we can make sure to match up the other hole. Now we can rest it on its top because this will be protecting the anemometer.",

    "Now we take our other screw and pass it through the other hole and then we will move. Our top plate around until it matches up with it. Go ahead and screw this in. There we go. Now it's matched up and we will tighten this down again, just to be snug or we don't want to over tighten and strip this out. So now we have our completed top unit.",

    "Now we have step five of the radiation shield assembly here. We're going to be mounting our temperature pressure relative humidity sensor into one of our open leaves for this will need an open leaf. We will need our cable sensor from an earlier step and we will need our short number two thread cutting screw, and for tools, we will need our smaller Phillips screwdriver.",

    "We will take our open leaf. You will note on here, there's a cut in here which will allow you to pass the screwdriver through it. There is also a hole here that we will be passing the screw into in order to Mount the sensor. We take our sensor, we pass it up through the bottom of the, uh, open leaf. We're going to install through the lower of the two holes in the sensor.",

    "You'll see. Cute. We're going to take our number two thread. Cutting screw, place it on our screwdriver. We're then going to pass that through the hole in the sensor, and we're going to stat against the hole in the open leaf. And then we're going to begin to screw that down and then we'll want to make sure that this is upright and we're just going to screw until we feel a good tension. Um, just to have a consistent result.",

    "This is step six of the radiation shield assembly here. We're going to be dealing with assembling the. Physical structure and running all the cables through. We're going to start with our top assembly here. We also have our lower assembly. We just put together. In addition, we have the other open leaf that we did not use for this, and we have our end connector and then we have our bolt, which we'll connect them all together.",

    "We'll start with our top assembly. We're going to go and straighten out this cable. And we're going to pass it through the open leaf assembly. Now we're going to want this to be on the opposite side from where our sensor is, so we'll pass it through there.",

    "okay. So we have that passed through. Now we'll bring the two plates actually together. I'm making sure that we don't crash and do anything when we do so we'll have them sit like that for a second. We're not going to go and pass both of these cables through the same slots. So like this through the, uh, plate directly below them, before we do that, we should pay attention to the King of the base of each of the open leaves so that they can only fit together in one way.",

    "And so you'll also can line up, uh, the cuts that we see here. And here when talking about which quadrant we're going to pass the cable through. So we line those two cuts up and then we're going to go and pass the cable through the corresponding quadrant between the two",

    "Okay. And now we're going to go and pass our temperature pressure, relative humidity, sensor cable, through. The opposite quadrant.",

    "Okay. We now have both of those pass through there, so we will place those stack like this, and then we're finally going to pass them through this bottom plate. So again, we'll pay attention to the King. This will be going on like this. Which means we want to pass this cable through this quadrant for the hot wire anemometer and this cable through this quadrant for the temperature pressure, radiation sheet And now we'll go through and pass our other cable through.",

    "okay. Now that we have our cables pass through all the parts of the system, we're going to go and connect them together. So we will match up the King of these two right here. We will it on the top plate. And then. We will match up the keying on the bottom connector, like so, so that everything is stacked up.",

    "Once everything is stacked up, we will take our bolt and pass it. Okay. All the way through. And depending on how tight the three D print is, you may need to screw it some of the way. Um, but it should move relatively easily.and then again, as it passes through each section into the next one, making sure that everything is lined up now passing into the top section and now are here just making sure that. All of the orientations that we set up previously are maintained. As you pass into the top section, it should catch on the threaded insert that we placed in there before. And we're going to then continue to tighten it down until the whole tower is snug together. And then just snug that down. So we don't strip the threaded insert. And then that is our final assembled system. Our last step. We'll be, we're going to place our large nut into the bottom here. This will allow us to connect our tripod up into it. And then we will set this aside for getting connected to our logger system later.",

    "Here, we're going to go through the cable assembly for the overall system assembly. Here, we have our parts. So we have two each of black, red, white, and green crimp wires. And then we have four crimp housings for these and together. We're going to go and use two crimp housings, a piece to make a cable, which we'll use to connect a pair of things within our logger box.",

    "So for the assembly, we're going to go and place in order from left to right, looking at the top of the connector here, where you see these slots, black, red, white, and green, and to connect these together, if you look on the character, you'll see that there is a one side, which is beveled like this on the ends and the holes are smaller color.",

    "That's where the ends are going to stick out. Right. So we're going to insert it into the opposite side. Here, where you'll see that the holes are slightly larger and not bevel now to insert them. We're going to look at the crimped end here and you'll see, there's a top of the crimp that we're looking at now, and that has a groove in it, which is going to latch with the groove in the connector and lock it together. The other side is smooth. So we'll see if we insert it in properly with the, uh, smooth side up. That it will insert fine. However, it will pull out as well. When inserted properly with the group side facing up to correspond with these groups here, you'll hear a slight clicking sound and you won't be able to pull the connector out.",

    "So now we'll go through and repeat this process for the different colors. So we have black in, we will now place red in. Place our white and then place green in. Now we will flip this around and repeat the connection on this end. Same way. So black, red, white, and green. And now when we leave this out, we should see that, uh, we have, you know, in a straight line, green, white. Red black. So this is a properly assembled, um, cable connection. We can give this a little pole and nothing's coming out. That looks good. We will then repeat this process again. So that in total we have two completed cable harnesses like this.",

    "So now we're going to go in and actually Mount our circuit boards to the top of our mounting plate. For that we will need our mounting plate. We will need our data logger and our battery monitor charger, and we will also need to number four, nylon washers to number four short thread cutting screws for number two, nylon washers and four number two short thread cutting screws. So the order that we'll have here is we will have our circuit board on top. We will then have a. Washer in between, and then we will have our screw connect. Both of them. We will start with mounting the lager with the two number four nylon washers and two number four short thread cutting screws. So we will put these on there, pass it through the board, place our washer on the backside of that.",

    "And then we will go and. Screw that down and repeat that process for the other hole. All right, then we will go and Mount the battery monitor using four number two nylon washers, and using for number two short thread. Cutting screws. Passing the screw through the board, placing it over the washer and then placing this through the appropriate hole on the board. And then we'll repeat for the other three. ",

    'Alright, and there we go.',

    "So now we're going to go through the process of mounting all of the boards to the mounting plate for the bottom side. So for that, we have our completed mounting plate from the top side mounting. We have our soil moisture, so temperature interface unit, we have our battery board. And then in addition, we have. For short number four thread cutting screws, too short number two thread cutting screws. So now we will flip this over and Mount to the bottom side. So first we will Mount our soil moisture temperature interface unit. So for this, we will Mount it in this direction on these two pegs here. Unlike the top mounting, no washers are required here for this.",

    "We will use our two number two thread, cutting screws and our smaller Phillips. So we will place them through the hole in the PCB and then just screw them into the mounting post below. We will leave it a little, a loose on the first one so that we can Mount it into the hole for the. Second. And now we'll screw that down just until both are snug. Now we will go and place the battery unit on the bottom, like so, so that the switch is on the open side. Here. We will now Mount this down in the same manner with four number four thread, cutting screws and repeat until all boards are fully mounted. and there we go.",

    "So now we're going to go in and make all of our internal connections inside of the box. So for that, we're going to have our previously assembled mounting plate and other boards on there. We're going to have our two, one inch wires, our two, three inch wires and our two, three inch cable harnesses. In addition, we're also going to have our SD card as this is a good time to plug that in for tools. We will need our precision 1.8 millimeter Flathead screwdriver. So we can start on this end. We're going to be making a connection between the battery system to the battery monitor. So for that, we'll use our two, one inch short connectors here. So we will be connecting this terminal here, bat to the terminal marked V out on the bottom here, you see the plus and minus indication. So plus is the terminal away from me minus is the terminal closest to me on the. Bottom side. We see the V out terminal with minus being further from me, plus being closest to me. So we're going to take our black, a short connection and go from minus two minus we'll place that in and screw this down just until you feel some good tension against it.",

    "We don't want to over tighten and crush these connectors. So we'll now go over to the top side. Plug this in again to Linus and tighten this down. We'll now repeat the process with the yellow connector for the positive or plus. okay. So that connection is now complete. Now we will go and make the connection from the battery monitor battery controller to the logger itself. So we'll be going from disconnector Mark system. Where the positive is closest to me, the negative is further away from me. True. The terminal here marked bat, which has the positive further away from me. The negative closer to me, we will start again, connecting the negative terminal. screwing it down in the same fashion. And if you have trouble with any of these connections, um, make sure that they're all the way unscrewed. If you're having trouble fitting the connector into it.",

    "Okay. Okay. So there, we have our connection from, uh, the battery monitor battery controller to the logger itself. Um, we'll now make one more connection from here, which will allow us to monitor the status of the battery charger and controller. So we will use one of our cable harnesses. We will connect that in here, as I was saying, make sure these are all unscrewed. And just insert that in here with black, furthest away from me, green closest to me, we will then screw each one of these down. We will then bring that over to this connection. This terminal here with black, furthest to the right, like, so we will then tighten this down and now we will make one last connection.",

    "D. Adapter board on the bottom side. So we can start here. Here. We will have green for this, to the right black, furthest to left. Like, so we'll tighten that down. Then we will pass this cable through this group here, up to here top here. We will have it with black, furthest to the right green, furthest to the left. Like, so take that and connect it to the top side to the logger. So again, with black on the right green on the left. And we will go and insert it over here into port three. And we will screw that down as before. So next we'll install the SD card. So now we have our SD card and we're going to place that in here.",

    "So we're going to place our SD card in here. And press in until it seats. It may take a little bit of pressure, but should not take too much. That is the appropriate amount of indent that should be seen. So that is a properly seated SD card. So now we have our fully assemble internal connections. Now we can go in places in the box and make the external connection. So now we're going to go through the box preparation for us to put our preassembled logger subsystem within it. So here we have the bottom of the box itself that has been pre drilled. We have the top of the box. We have six cable glands and six corresponding nuts to Mount those. We also have the four machine screws for the top of the box.",

    "As for tools, we have a wrench for the cable glands, and we also have our larger P two Phillips screwdriver. We'll start off with our cable glands. So we have all these holes in the side, into each one. We're going to place a cable gland like, so. We're then going to take a nut and place it on the back of it and tighten that down. Just, you know, finger tight. It's important here that we're tightening down the actual body of the cable gland and not the cover, which we'll tighten down around the cable. So we want to make sure that we're tying against the body of the box. So we will put our wrench on like, so, and tighten down just a bit until we have good pressure against it.",

    "Um, now this is fully sealed against the box itself and we have a secure connection there. So we'll repeat that for all of the other holes I'm using the wrench. Now that we have all of our cable glands installed in here, we're going to go and install the screws in the top of the box. Which are done in such a way that you'll screw them in through the plastic so that they stay in the late. And there's no chance of dropping them in the field when you're installing them or anything. So we're going, we'll be on the top side of the box, place our screw in here with our number two Phillips and screw it through. And there'll be just a little bit of resistance to that. And then as soon as it screws far enough, It should drop through like, so, and now this will spin freely, but stay within the lid and not come out.",

    "So we'll just repeat that process for the rest of these. Okay. So now our box is fully prepared. Now we can put the data logger unit into there and make all of our external connections. So now we're going to go and make all of our external connections to our prebuilt. Data logger core. So for that, we will have that from our previous step. We have our radiation shield with our pair of sensors in it from an earlier step. So we'll be using that. We have our box that has not been prepped with our cable glands.",

    "We have our, so moisture sensor, our serial temperature sensor, our rainfall gauge, and we have our precision 1.8 millimeter slotted screwdriver. So move this out of the way we will have. Cables coming in from here from our temperature pressure, relative humidity sensor, as well as from our hot wire anemometer. And so we'll just make sure to keep these straight so you can always track them back to the sensors inside of here pretty easily. In addition to all of the external sensor connections, we'll also be connecting external power in the form of our solar panel. So for that, we'll need this adaptor connector for the following step.",

    "Now we're going to start passing our connections through. Here we have our hot wire anemometer on the top side. So we pass that through the cable grind. So we have our temperature pressure relative humidity sensor on the bottom side, passing through the cable gland. So these cables are large, so there'll be a little stiff to move through. That's fine. We'll want to make sure that we get enough Slack with these. So then you take our data logger core.",

    "We have this for our temperature pressure, relative humidity. We will go and lay these out. So we're going to make a connection and we're going to have it be from my right to left, have them in the order of black, red, Brown, orange, from my right to left, uh, as we've done in some of our connections before. So we're going to plug these into port to making sure these are all. Fully unscrewed. There we go. Now tightening these down. So now we will go and connect the hot wire anemometer coming in here. So again, same as before black, red, Brown orange from right to left. And we'll plug that in here and do port one.",

    "Now we're going to screw these down. Now we're going to go ahead and connect our moisture sensor and our soil temperature sensor onto the interface board on the bottom side. First we'll open these up so that we can get appropriate Slack coming in. Same with the temperature. We will pass our soil moisture through on the bottom side, we'll pass our soil temperature through on the top side, and then we will come here to make our connections. We'll have the soil temperature sensor on the three furthest toward me. Um, we will have the order of connections of black yellow, red, a black being closest to me.",

    "So pass those in like so, and tighten them down. So now we have those three connections. Now we'll go and connect the soil moisture sensor. We will have the clear wire closest toward me, the black in the middle and the red furthest away. So we'll slide those in and tighten them down. Those are the only connections that are going to be coming around through the bottom. So now we can place the board back, somewhat having these connections come down through here. These connections will just go straight out the front and we can pull back, slow this Slack.",

    "Just to make our lives a little easier. Next week, we'll be attaching the tipping bucket rain gauge. That's going to come in through this opening and is going to come up through this slot in the mounting plate and we'll come over. And connect to this two position terminal right here, the connection closest to me of, uh, being green and red being further away, pass those in and tighten them down. And now last we will bring in the solar power connector. So that will come in through here, go over to the battery monitor connector over here and here we bring in our solar power connector to go to the battery monitor with ground and black, closest to me positive for this away. Like, so, and tighten that down.",

    "Okay,now that we have all the connections made, we will go in and seat the mounting plate down and in doing so pull the extra Slack out of these as needed. We don't want them to be strained, but we also want to make sure that the plate can sit down appropriately. So that looks pretty good. So now we're done making all of our external connections to the data logger itself. Now we're going to do some pre-assembly with our particle boron. So we've gone and taken apart our boron box. And these are the contents here that are important to us, which is our boron itself and the associated antenna. So one key part that can be kind of tricky is attaching the antenna to the boron itself.",

    "So on the boron here on the far side, we see that there's a small gold plated connector. This is for us to attach these cellular antenna to it. In order to do this, we will go and we'll take our Tena. And the antenna on the cable side is like a cup. That's going to go snap around this cylinder here. So we will place it on top. Like, so. And then we will apply some pressure with like the edge of your fingernail and pop like that. You should have seen a drop down just a little bit. That's all it takes. There should not be much force required for this and make sure to not match the antenna at all. When doing this now that we have that connected, we have our full particle boron unit ready to go. So this can be attached and plugged into our longer unit.",

    "So now we're going to do our very last electrical assembly and test that we have power going to the system appropriately. For that we have our assembled system up to date. We have our solar panel and we have our boron radio. So step one, we're going to go and plug this in. You'll see these rows of pins here.",

    "There's a corresponding set of pins on each side of the boron. So we're going to do is rest, are born into there, like, so, and then press it down there until it mate's flush with the sides so that all we see along here is black and there's no metal in between. Now we have our Braun fully set down. Now we'll go and get our intent into the box.",

    "Now we're going to go and make our solar panel connections. So we have our solar panel with the male plug and our box side with our female plug. And we're going to connect these together and press until you hear a click, like, so. These are not fully mated and shouldn't easily separate, and this should be a water tight connection.",

    "Now, now that we have all this together, we are ready to go. If this solar panels out under the sun, instead of these studio lights, the system would turn on automatically to facilitate that. The last thing that we'll want to do is remove the film that is on top of here. This is a protective film. Used for shipping and handling of the device, but once we're deploying it, we want to make sure that we get the actual solar radiation passing through it.",

    "Unfortunately, the lighting here is still not enough to automatically turn this on, which may be the case for when you're deploying it. If that is the case, there is a button here on the battery monitor, and that allows us to jumpstart the system. If there's not sufficient solar presence. So here, we're going to go and flip on the batteries by taking this switch and pressing it away from us.",

    "And the device does not come online. We then jumpstart the device and we get an indication that the boron has power. So our system has been assembled properly. Wait, now that we have our system and it is running properly, as we can see by our status lights. And we have power to the device. We're going to go and, uh, place the lid on our box and we're ready to start logging data. So we're going to simply place our lid on it. We'll seat down a little bit with the gasket that's around it.",

    "We're going to use our larger Phillips, your driver and tighten each one of these down. For starters, you don't want to tighten them too much or we'll candle lever. The. Box top off and make it harder to get a good seal on them. So just until you feel tension against it, once you do that, then go around and tighten them down more legitimately until you feel more pressure of it's sealing down against the body of the box.",
    'There we go. And now we have our system fully running and fully ready to be placed out in the field.',
  ],
  tips: [
    '1. Place node in groud vertically.',
    '2. Do not force node into soil.',
  ],
  assemblyMaterials: {
    materials: [
      'Solar Panel (1)',
      'Particle Boron (1)',
      'SD Card (1)',
      'Battery Unit (in bag)',
      'Charge Control Unit',
      'Data Logger Unit',
      'Soil Moisture Interface Units',
      'Hook Up Connections',
      'Housing Connectors',
      'Wires for Power Connection',
      'Soil Moisture Nodes (VH400)',
      'Washers',
      'Cable Glands (5)',
      'Mounting Plate',
      'Box',
      'VHB Tape',
      '#4 Short Thread Cutting Screws - Qty 11',
      '#4 Long Thread Cutting Screws - Qty 2',
      '#2 Short Thread Cutting Screws - Qty 7',
      '#4 Nylon Washers - Qty 2',
      '#2 Nylon Washers - Qty 4',
    ],
    tools: {
      notIncluded: [
        'Adjustable wrench',
        '#1 Philips screwdriver with narrow tip',
        '1.8mm slotted precision screwdriver',
        'Wire stripper and cutter',
        'Pair of Scissors',
        '2mm slotted precision screwdriver',
        'Small pair of pliers',
      ],
      included: [],
    },
  },
  videoEmbedding: {
    main: 'https://www.youtube.com/watch?v=Jn85Lr-G_VU&t=2s',
    stepOne: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=242s',
    stepTwo: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=1162s',
    stepThree: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=1925s',
    stepFour: 'https://www.youtube.com/watch?v=is8qP-JhnC8&t=2450s',
  },
};

const project = {
  project_name: 'Sample Project',
  startdate: 'This is the dateString',
  enddate: 'this is the ending date',
  polygon: {},
  metadata: {
    summary: 'Summary',
    crop: 'Crop',
  },
  public: false,
};

const nodeColumns = [
  {
    Header: 'Nodes',
    columns: [
      {
        Header: 'Node',
        accessor: 'id',
        sortType: 'basic',
        disableSortBy: false,
      },
      {
        Header: 'Project ID',
        accessor: 'productId',
        sortType: 'basic',
        disableSortBy: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortType: 'basic',
        disableSortBy: false,
      },
      {
        Header: 'Cellular',
        accessor: 'cellular',
        sortType: 'basic',
        disableSortBy: false,
      },
      {
        Header: 'Public',
        accessor: 'public',
        sortType: 'basic',
        disableSortBy: false,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortType: 'basic',
        disableSortBy: false,
      },
    ],
  },
];

const node = {
  node_id: 0,
  name: 'Test',
  projectId: 1234,
  online: true,
  connected: true,
  owner: 'Company name' || 1234,
  status: 'normal', // normal | error
  geometry: { type: 'Point', coordinates: { y: 0, x: 0, z: 0 } },
  hardware: [
    {
      id: 1,
      deployed_hardware_id: 1,
      data: [
        { fValue: 3, timestamp_in_epoch: 1599587100 },
        { fValue: 0, timestamp_in_epoch: 1599587200 },
        { fValue: 6, timestamp_in_epoch: 1599587300 },
        { fValue: 4, timestamp_in_epoch: 1599587400 },
        { fValue: 5, timestamp_in_epoch: 1599587500 },
        { fValue: 2, timestamp_in_epoch: 1599587600 },
        { fValue: 2, timestamp_in_epoch: 1599587700 },
        { fValue: 1, timestamp_in_epoch: 1599587700 },
        { fValue: 0, timestamp_in_epoch: 1599587800 },
        { fValue: 3, timestamp_in_epoch: 1599587900 },
        { fValue: 3, timestamp_in_epoch: 1599588000 },
        { fValue: 3, timestamp_in_epoch: 1599588100 },
        { fValue: 6, timestamp_in_epoch: 1599588200 },
        { fValue: 3, timestamp_in_epoch: 1599588300 },
        { fValue: 0, timestamp_in_epoch: 1599588400 },
        { fValue: 2, timestamp_in_epoch: 1599588500 },
        { fValue: 1, timestamp_in_epoch: 1599588600 },
        { fValue: 0, timestamp_in_epoch: 1599588700 },
        { fValue: 2, timestamp_in_epoch: 1599588800 },
        { fValue: 1, timestamp_in_epoch: 1599588900 },
      ],
      sensor_type: 'Barometric',
      units: 'mm',
      location: [
        /* some form of location data -- could be a single point? * */
      ],
    },
    {
      id: 2,
      deployed_hardware_id: 3,
      data: [
        { fValue: 3, timestamp_in_epoch: 1599587100 },
        { fValue: 0, timestamp_in_epoch: 1599587200 },
        { fValue: 6, timestamp_in_epoch: 1599587300 },
        { fValue: 4, timestamp_in_epoch: 1599587400 },
        { fValue: 5, timestamp_in_epoch: 1599587500 },
        { fValue: 2, timestamp_in_epoch: 1599587600 },
        { fValue: 2, timestamp_in_epoch: 1599587700 },
        { fValue: 1, timestamp_in_epoch: 1599587700 },
        { fValue: 0, timestamp_in_epoch: 1599587800 },
        { fValue: 3, timestamp_in_epoch: 1599587900 },
        { fValue: 3, timestamp_in_epoch: 1599588000 },
        { fValue: 3, timestamp_in_epoch: 1599588100 },
        { fValue: 6, timestamp_in_epoch: 1599588200 },
        { fValue: 3, timestamp_in_epoch: 1599588300 },
        { fValue: 0, timestamp_in_epoch: 1599588400 },
        { fValue: 2, timestamp_in_epoch: 1599588500 },
        { fValue: 1, timestamp_in_epoch: 1599588600 },
        { fValue: 0, timestamp_in_epoch: 1599588700 },
        { fValue: 2, timestamp_in_epoch: 1599588800 },
        { fValue: 1, timestamp_in_epoch: 1599588900 },
      ],
      sensor_type: 'Wind Speed',
      units: 'mm',
      location: [
        /* some form of location data -- could be a single point? * */
      ],
    },
  ],
};

const mockNodeData = {
  succeeded: true,
  error_message: 'None',
  records: [
    {
      id: 24012,
      hardwareid: 4,
      data: { id: '20', iValue: '1608133421' },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24045,
      hardwareid: 18,
      data: { id: '18', iValue: 249.0 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24044,
      hardwareid: 17,
      data: { id: '17', iValue: 0.0 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24043,
      hardwareid: 16,
      data: { id: '16', iValue: 0.0 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24042,
      hardwareid: 15,
      data: { id: '11', iValue: 1.6072998 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24041,
      hardwareid: 14,
      data: { id: '10', iValue: 20.125 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24040,
      hardwareid: 13,
      data: { id: '5', iValue: 15.0 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
    {
      id: 24039,
      hardwareid: 12,
      data: { id: '4', iValue: 20.0 },
      metadata: null,
      timeofreading: '2020-12-16 15:44:57.796000+00:00',
      point: null,
    },
  ],
};

const selectedSensor = {
  data: [
    { fValue: 3, timestamp_in_epoch: 1599587100 },
    { fValue: 0, timestamp_in_epoch: 1599587200 },
    { fValue: 6, timestamp_in_epoch: 1599587300 },
    { fValue: 4, timestamp_in_epoch: 1599587400 },
    { fValue: 5, timestamp_in_epoch: 1599587500 },
    { fValue: 2, timestamp_in_epoch: 1599587600 },
    { fValue: 2, timestamp_in_epoch: 1599587700 },
    { fValue: 1, timestamp_in_epoch: 1599587700 },
    { fValue: 0, timestamp_in_epoch: 1599587800 },
    { fValue: 3, timestamp_in_epoch: 1599587900 },
    { fValue: 3, timestamp_in_epoch: 1599588000 },
    { fValue: 3, timestamp_in_epoch: 1599588100 },
    { fValue: 6, timestamp_in_epoch: 1599588200 },
    { fValue: 3, timestamp_in_epoch: 1599588300 },
    { fValue: 0, timestamp_in_epoch: 1599588400 },
    { fValue: 2, timestamp_in_epoch: 1599588500 },
    { fValue: 1, timestamp_in_epoch: 1599588600 },
    { fValue: 0, timestamp_in_epoch: 1599588700 },
    { fValue: 2, timestamp_in_epoch: 1599588800 },
    { fValue: 1, timestamp_in_epoch: 1599588900 },
  ],
  deployed_hardware_end_date: null,
  deployed_hardware_id: 5,
  deployed_hardware_metadata: null,
  deployed_hardware_point: null,
  deployed_hardware_start_date: '2020-12-15 19:30:00+00:00',
  hardware_id: 9,
  headercrc32c: null,
  model: null,
  node_id: 'e00fce6813794773754ac5b9',
  sensor_id: null,
  sensor_type: 'TIME',
  uuid: 'e00f',
};

const nodeList = [
  {
    id: 1,
    deploymentid: 21,
    hardwareType: 'Moisture',
    online: true,
    connected: true,
    owner: 'Company name' || 1234,
    status: 'normal', // normal | error
    crop: 'Red Winter Wheat',
    growingSeason: '', // Unix date?
    geometry: { type: 'Point', coordinates: { y: 0, x: 0, z: 0 } },
    hardware: {
      examplesensor1: {
        data: [
          { fValue: 3, timestamp_in_epoch: 1599587100 },
          { fValue: 0, timestamp_in_epoch: 1599587200 },
          { fValue: 6, timestamp_in_epoch: 1599587300 },
          { fValue: 4, timestamp_in_epoch: 1599587400 },
          { fValue: 5, timestamp_in_epoch: 1599587500 },
          { fValue: 2, timestamp_in_epoch: 1599587600 },
          { fValue: 2, timestamp_in_epoch: 1599587700 },
          { fValue: 1, timestamp_in_epoch: 1599587700 },
          { fValue: 0, timestamp_in_epoch: 1599587800 },
          { fValue: 3, timestamp_in_epoch: 1599587900 },
          { fValue: 3, timestamp_in_epoch: 1599588000 },
          { fValue: 3, timestamp_in_epoch: 1599588100 },
          { fValue: 6, timestamp_in_epoch: 1599588200 },
          { fValue: 3, timestamp_in_epoch: 1599588300 },
          { fValue: 0, timestamp_in_epoch: 1599588400 },
          { fValue: 2, timestamp_in_epoch: 1599588500 },
          { fValue: 1, timestamp_in_epoch: 1599588600 },
          { fValue: 0, timestamp_in_epoch: 1599588700 },
          { fValue: 2, timestamp_in_epoch: 1599588800 },
          { fValue: 1, timestamp_in_epoch: 1599588900 },
        ],
        type: 'example',
        units: 'mm',
        location: [
          /* some form of location data -- could be a single point? * */
        ],
      },
      examplesensor2: {
        data: [
          { fValue: 3, timestamp_in_epoch: 1599587100 },
          { fValue: 0, timestamp_in_epoch: 1599587200 },
          { fValue: 6, timestamp_in_epoch: 1599587300 },
          { fValue: 4, timestamp_in_epoch: 1599587400 },
          { fValue: 5, timestamp_in_epoch: 1599587500 },
          { fValue: 4, timestamp_in_epoch: 1599587600 },
          { fValue: 3, timestamp_in_epoch: 1599587700 },
          { fValue: 1, timestamp_in_epoch: 1599587700 },
          { fValue: 0, timestamp_in_epoch: 1599587800 },
          { fValue: 3, timestamp_in_epoch: 1599587900 },
          { fValue: 4, timestamp_in_epoch: 1599588000 },
          { fValue: 5, timestamp_in_epoch: 1599588100 },
          { fValue: 6, timestamp_in_epoch: 1599588200 },
          { fValue: 6, timestamp_in_epoch: 1599588300 },
          { fValue: 3, timestamp_in_epoch: 1599588400 },
          { fValue: 2, timestamp_in_epoch: 1599588500 },
          { fValue: 1, timestamp_in_epoch: 1599588600 },
          { fValue: 3, timestamp_in_epoch: 1599588700 },
          { fValue: 2, timestamp_in_epoch: 1599588800 },
          { fValue: 1, timestamp_in_epoch: 1599588900 },
        ],
        type: 'example',
        units: 'mm',
        location: [
          /* some form of location data -- could be a single point? * */
        ],
      },
    },
  },
  {
    id: 3,
    deploymentid: 43,
    hardwareType: 'Temperature',
    online: true,
    connected: true,
    owner: 'Company name' || 1234,
    status: 'normal', // normal | error
    crop: 'Red Winter Wheat',
    growingSeason: '', // Unix date?
    geometry: { type: 'Point', coordinates: { y: 0, x: 0, z: 0 } },
    hardware: {
      examplesensor1: {
        data: [
          { fValue: 3, timestamp_in_epoch: 1599587100 },
          { fValue: 0, timestamp_in_epoch: 1599587200 },
          { fValue: 6, timestamp_in_epoch: 1599587300 },
          { fValue: 4, timestamp_in_epoch: 1599587400 },
          { fValue: 5, timestamp_in_epoch: 1599587500 },
          { fValue: 2, timestamp_in_epoch: 1599587600 },
          { fValue: 2, timestamp_in_epoch: 1599587700 },
          { fValue: 1, timestamp_in_epoch: 1599587700 },
          { fValue: 0, timestamp_in_epoch: 1599587800 },
          { fValue: 3, timestamp_in_epoch: 1599587900 },
          { fValue: 3, timestamp_in_epoch: 1599588000 },
          { fValue: 3, timestamp_in_epoch: 1599588100 },
          { fValue: 6, timestamp_in_epoch: 1599588200 },
          { fValue: 3, timestamp_in_epoch: 1599588300 },
          { fValue: 0, timestamp_in_epoch: 1599588400 },
          { fValue: 2, timestamp_in_epoch: 1599588500 },
          { fValue: 1, timestamp_in_epoch: 1599588600 },
          { fValue: 0, timestamp_in_epoch: 1599588700 },
          { fValue: 2, timestamp_in_epoch: 1599588800 },
          { fValue: 1, timestamp_in_epoch: 1599588900 },
        ],
        type: 'example',
        units: 'mm',
        location: [
          /* some form of location data -- could be a single point? * */
        ],
      },
      examplesensor2: {
        data: [
          { fValue: 3, timestamp_in_epoch: 1599587100 },
          { fValue: 0, timestamp_in_epoch: 1599587200 },
          { fValue: 6, timestamp_in_epoch: 1599587300 },
          { fValue: 4, timestamp_in_epoch: 1599587400 },
          { fValue: 5, timestamp_in_epoch: 1599587500 },
          { fValue: 4, timestamp_in_epoch: 1599587600 },
          { fValue: 3, timestamp_in_epoch: 1599587700 },
          { fValue: 1, timestamp_in_epoch: 1599587700 },
          { fValue: 0, timestamp_in_epoch: 1599587800 },
          { fValue: 3, timestamp_in_epoch: 1599587900 },
          { fValue: 4, timestamp_in_epoch: 1599588000 },
          { fValue: 5, timestamp_in_epoch: 1599588100 },
          { fValue: 6, timestamp_in_epoch: 1599588200 },
          { fValue: 6, timestamp_in_epoch: 1599588300 },
          { fValue: 3, timestamp_in_epoch: 1599588400 },
          { fValue: 2, timestamp_in_epoch: 1599588500 },
          { fValue: 1, timestamp_in_epoch: 1599588600 },
          { fValue: 3, timestamp_in_epoch: 1599588700 },
          { fValue: 2, timestamp_in_epoch: 1599588800 },
          { fValue: 1, timestamp_in_epoch: 1599588900 },
        ],
        type: 'example',
        units: 'mm',
        location: [
          /* some form of location data -- could be a single point? * */
        ],
      },
      examplesensor3: {
        data: [
          { fValue: 3, timestamp_in_epoch: 1599587100 },
          { fValue: 0, timestamp_in_epoch: 1599587200 },
          { fValue: 6, timestamp_in_epoch: 1599587300 },
          { fValue: 4, timestamp_in_epoch: 1599587400 },
          { fValue: 5, timestamp_in_epoch: 1599587500 },
          { fValue: 4, timestamp_in_epoch: 1599587600 },
          { fValue: 3, timestamp_in_epoch: 1599587700 },
          { fValue: 1, timestamp_in_epoch: 1599587700 },
          { fValue: 0, timestamp_in_epoch: 1599587800 },
          { fValue: 3, timestamp_in_epoch: 1599587900 },
          { fValue: 4, timestamp_in_epoch: 1599588000 },
          { fValue: 5, timestamp_in_epoch: 1599588100 },
          { fValue: 6, timestamp_in_epoch: 1599588200 },
          { fValue: 6, timestamp_in_epoch: 1599588300 },
          { fValue: 3, timestamp_in_epoch: 1599588400 },
          { fValue: 2, timestamp_in_epoch: 1599588500 },
          { fValue: 1, timestamp_in_epoch: 1599588600 },
          { fValue: 3, timestamp_in_epoch: 1599588700 },
          { fValue: 2, timestamp_in_epoch: 1599588800 },
          { fValue: 1, timestamp_in_epoch: 1599588900 },
        ],
        type: 'example',
        units: 'mm',
        location: [
          /* some form of location data -- could be a single point? * */
        ],
      },
    },
  },
  {
    id: 9,
    deploymentid: 8,
    hardwareType: 'Wind Speed',
    online: true,
    connected: true,
    owner: 'Company name' || 1234,
    status: 'normal', // normal | error
    crop: 'Red Winter Wheat',
    growingSeason: '', // Unix date?
    geometry: { type: 'Point', coordinates: { y: 0, x: 0, z: 0 } },
    hardware: {
      examplesensor1: {
        data: [
          { fValue: 3, timestamp_in_epoch: 1599587100 },
          { fValue: 0, timestamp_in_epoch: 1599587200 },
          { fValue: 6, timestamp_in_epoch: 1599587300 },
          { fValue: 4, timestamp_in_epoch: 1599587400 },
          { fValue: 5, timestamp_in_epoch: 1599587500 },
          { fValue: 2, timestamp_in_epoch: 1599587600 },
          { fValue: 2, timestamp_in_epoch: 1599587700 },
          { fValue: 1, timestamp_in_epoch: 1599587700 },
          { fValue: 0, timestamp_in_epoch: 1599587800 },
          { fValue: 3, timestamp_in_epoch: 1599587900 },
          { fValue: 3, timestamp_in_epoch: 1599588000 },
          { fValue: 3, timestamp_in_epoch: 1599588100 },
          { fValue: 6, timestamp_in_epoch: 1599588200 },
          { fValue: 3, timestamp_in_epoch: 1599588300 },
          { fValue: 0, timestamp_in_epoch: 1599588400 },
          { fValue: 2, timestamp_in_epoch: 1599588500 },
          { fValue: 1, timestamp_in_epoch: 1599588600 },
          { fValue: 0, timestamp_in_epoch: 1599588700 },
          { fValue: 2, timestamp_in_epoch: 1599588800 },
          { fValue: 1, timestamp_in_epoch: 1599588900 },
        ],
        type: 'example',
        units: 'mm',
        location: [
          /* some form of location data -- could be a single point? * */
        ],
      },
    },
  },
];

const order = {
  contact: {
    name: 'John Doe',
    id: 0,
    shippingAddress: '123 Main Street. #3',
    city: 'Minneaplis',
    state: 'MN',
    zip: 55455,
    email: 'faculty1@university.edu',
    phone: 'None',
  },
  nodesOnOrder: [], // An array of strings
  extras: {
    coupon: null,
    comments: null,
  },
};

export {
  nodeSetup,
  node,
  project,
  mockNodeData,
  nodeColumns,
  selectedSensor,
  nodeList,
  order,
};
