/**
 * Popup that returns message to user if order was success/failure.
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';

const OrderResponse = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  return (
    <div className="quantityModal">
      <p>{state.orderResponse || 'Success!'}</p>
      <button
        className="blue white-text"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: null,
            },
          });
        }}
      >
        Close
      </button>
    </div>
  );
};

export default OrderResponse;
