import React, { useState, useContext } from 'react';
import { FaPencilAlt, FaRegMap, FaTrashAlt } from 'react-icons/fa';
// import { IoIosMore } from 'react-icons/io';
import PublicSwitch from '../../../components/PublicSwitch/PublicSwitch';
import { store } from '../../../hooks/dataContext';
import { format } from 'date-fns';
import { deleteProject } from '../../../utils/projects.api';
import { IoMdDownload } from 'react-icons/io';
import { CSVLink } from 'react-csv';
import './ProjectHeader.css';
import parseJSON from 'date-fns/parseJSON';

const ProjectHeader = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { project } = state;

  const startDate = project.start_date
    ? format(parseJSON(project.start_date), "dd MMM yyyy 'at' h:mm a")
    : '';

  const endDate = project.end_date
    ? format(parseJSON(project.end_date), "dd MMM yyyy 'at' h:mm a")
    : 'Ongoing';

  const userIsOwner =
    project.owner != null && state.user.id === project.owner;

  return (
    <div className="nodeInfoHeader">
      <div className="nodeInfoStub">
        <p>{project.name}</p>
        <div className="projectName">
          <p>Crop Summary:</p>
          <p>{project.metadata && project.metadata.summary}</p>
        </div>
      </div>
      <div className="cropInfoStub">
        <div className="cropInfo">
          <p>Crop:</p>
          <p>{project.metadata && project.metadata.crop}</p>
        </div>
        <div className="cropInfo">
          <p>Start Date:</p>
          <p>{startDate}</p>
        </div>
        <div className="cropInfo">
          <p>End Date:</p>
          <p>{endDate}</p>
        </div>
      </div>
      <div className="nodeOptionButtons">
        {userIsOwner && (
          <div className="circle btn shadow optionButton">
            <FaPencilAlt
              onClick={() =>
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    data: project,
                    subModalTitle: 'Edit Project',
                  },
                })
              }
            />
          </div>
        )}
        {/* <div className="ml downloadBtn circle shadow optionButton">
          <IoMdDownload />
        </div> */}
        {/* <div className="circle shadow optionButton">
          <FaRegMap onClick={() => toggleHidden(!hidden)} />
        </div> */}
        {/* <div className="circle shadow optionButton">
          <IoIosMore />
        </div> */}
        <div className="circle ml btn shadow optionButton">
          <IoMdDownload
            onClick={() =>
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                  data: project,
                  subModalTitle: 'Download Project',
                },
              })
            }
          />
        </div>
        <div className="ml">
          <PublicSwitch
            disabled={!userIsOwner}
            type="project"
            item={project}
          />
        </div>
        {userIsOwner && (
          <div className="circle btn shadow optionButton">
            <FaTrashAlt
              onClick={() =>
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    data: { id: project.id },
                    subModalTitle: 'Delete Project',
                  },
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectHeader;
