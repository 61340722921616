import React, { useState, useContext } from 'react';
import { store } from '../../../hooks/dataContext';
import { CSVLink } from 'react-csv';
import { getLevelZeroData } from '../../../utils/level0data.api';
import DotLoader from 'react-spinners/DotLoader';

import { Button, Typography } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import sub from 'date-fns/sub';

const DownloadNodeCSV = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { node } = props;

  const [csvData, setCSVData] = useState([]);
  const [startDate, setStartDate] = useState(
    sub(new Date(), { weeks: 1 })
  );
  const [endDate, setEndDate] = useState(new Date());

  const [csvName, setCSVName] = useState('');
  const [loading, setLoading] = useState(false);

  const csvHeaders = [
    { label: 'ID', key: 'level0_id' },
    { label: 'Node ID', key: 'node_id' },
    { label: 'Parameter', key: 'parameter' },
    { label: 'Data', key: 'data' },
    { label: 'Time of Reading', key: 'time_of_reading' },
    { label: 'Co-ordinates', key: 'point' },
    { label: 'Elevation', key: 'elevation' },
    { label: 'Meta Data', key: 'metadata' },
  ];

  const handleCSVData = async () => {
    setLoading(true);

    setCSVName(
      'Node ' + node.node_id + '-' + new Date().toISOString() + '.csv'
    );

    const nodeData = await getLevelZeroData(
      node.node_id,
      startDate,
      endDate
    );

    const newNodeDataArray = nodeData.map((item) => {
      return {
        ...item,
        metadata: JSON.stringify(item.metadata).replace(/"/gm, '""'),
      };
    });

    setCSVData(newNodeDataArray);
    setLoading(false);

    // Have to programmatically click button because react-csv async feature doesnt work
    // https://github.com/react-csv/react-csv/issues/189
    document.getElementById('csvLink').click();

    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  const onCancel = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    await handleCSVData();
  };

  return (
    <form className="csvNodeModal" onSubmit={submit}>
      <Typography gutterBottom variant="h5">
        Download Node Data (.CSV)
      </Typography>
      <Typography variant="subtitle1">
        Filter data by date range
      </Typography>
      <DateTimePicker
        format="dd MMM yyyy 'at' h:mm a"
        required
        margin="normal"
        label="Start Date"
        disableFuture
        value={startDate}
        onChange={(startDate) => {
          setStartDate(startDate);
        }}
      />
      <DateTimePicker
        format="dd MMM yyyy 'at' h:mm a"
        required
        margin="normal"
        label="End Date"
        minDate={startDate}
        value={endDate}
        disableFuture
        onChange={(endDate) => {
          setEndDate(endDate);
        }}
      />

      <div className="editFormButtons">
        {loading ? (
          <DotLoader size={20} color="#006888" />
        ) : (
          <div>
            <Button onClick={onCancel} type="button">
              Cancel
            </Button>
            <button type="submit" className="blue white-text">
              Download
            </button>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              id="csvLink"
              filename={csvName}
            ></CSVLink>
          </div>
        )}
      </div>
    </form>
  );
};

export default DownloadNodeCSV;
