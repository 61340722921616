import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './ProjectCard.css';
import { store } from '../../../hooks/dataContext';
//import { Map } from '../../../components/Map/Map';
import PublicSwitch from '../../../components/PublicSwitch/PublicSwitch';
import { Button, Card, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import parseJSON from 'date-fns/parseJSON';
import { getProjectNodes } from '../../../utils/projects.api';

const useStyles = makeStyles({
  card: {
    width: '290px',
    padding: '5px',
  },
  cardGrid: {
    padding: '20px',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const ProjectCard = (props) => {
  const { state, dispatch } = useContext(store);
  const { name, projectDesc, item, startDate, endDate, metadata } =
    props;

  const classes = useStyles();

  const openProjectEditModal = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: item,
        subModalTitle: 'Edit Project',
      },
    });
  };

  const userIsOwner =
    item.owner != null && state.user.id === item.owner;

  return (
    <Card className={classes.card}>
      <Link
        to="/project"
        role="button"
        onClick={() => {
          dispatch({ type: 'SET_PROJECT', payload: item });
          getProjectNodes(item.id, dispatch);
        }}
      >
        {/* <div className="farmCardMap">
          <Map
            lassName="mapTest"
            hidden={false}
            zoom={false}
            width="100%"
            height={200}
          />
        </div> */}
        <div className="farmCardInfo">
          <div className="farmCardProject">
            <p
              style={{ color: '#006889' }}
              className="semi-bold title"
            >
              {' '}
              {name}
            </p>
            <p className="projDescription">{projectDesc}</p>
            <div className="farmCardData">
              <p className="semi-bold">Start Date: </p>
              <p>
                {startDate
                  ? format(parseJSON(startDate), 'dd MMM yyyy')
                  : ''}
              </p>
            </div>
            <div className="farmCardData">
              <p className="semi-bold">End Date: </p>
              <p>
                {endDate
                  ? format(parseJSON(endDate), 'dd MMM yyyy')
                  : 'Ongoing'}
              </p>
            </div>
            {/* <div className="farmCardData">
              <p className="semi-bold">ID: </p>
              <p>{item.id}</p>
            </div> */}
          </div>
        </div>
      </Link>
      <div className="farmCardInfo">
        {userIsOwner && <PublicSwitch type="project" item={item} />}
      </div>
      <div className="project-card-action-buttons">
        {userIsOwner && (
          <Button onClick={() => openProjectEditModal()}>Edit</Button>
        )}
      </div>
    </Card>
  );
};

export default ProjectCard;
