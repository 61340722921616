import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import gemsInformaticsLogo from '../../assets/gems-informatics.png';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div>
      <div className="footer-nav">
        <div className="nav-item">
          <Link to="/about" className="nav-link footer-nav-link">
            About GEMSIoT
          </Link>
        </div>
        <li className="nav-item">
          <a
            //   href="mailto:gemssupport@umn.edu"
            className="nav-link footer-nav-link"
          >
            Subscription Information
          </a>
        </li>
        <li className="nav-item">
          <a
            //   href="mailto:gemssupport@umn.edu"
            className="nav-link footer-nav-link"
          >
            Support
          </a>
        </li>
        <li className="nav-item">
          <Link to="/legal" className="nav-link footer-nav-link">
            Legal
          </Link>
        </li>
      </div>
      <div className="nav-item">
        <p disabled>&#169; {year} GEMS / All Rights Reserved</p>
      </div>
      <div className="informatics-logo-container">
        <img
          className="informatics-logo"
          src={gemsInformaticsLogo}
          alt=""
        />
      </div>
    </div>
  );
};

export default Footer;
