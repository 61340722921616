import React from 'react';
import './FieldSetup.css';

const FieldSetup = () => {
  return (
    <div className="fieldSetupContainer">
      <p>
        If you are finished setting up and registering your node(s),
        you are now ready to deploy your nodes in the field. Click
        'Continue' to view field installation materials.
      </p>
      <p className="semi-bold"> Important Notes: </p>

      <p>
        * As much as possible, ensure the system is free of
        obstructions to the solar panel or the rain gauge (trees or
        close by buildings, etc)        {' '}
      </p>
      <div>
          <button className="blue white-text">
          <a
            className=" white-text"
            href="https://drive.google.com/file/d/1LBog6nUZaCgk_3znkreKd4FtVylNAOwx/view?usp=sharing"
          >
            Continue
          </a>
        </button>
      </div>
    </div>
  );
};
export default FieldSetup;
