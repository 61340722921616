/**
 * This Modal is called in the Navbar component because
 * the Nav is rendered on every page this way allows for least amount of code reuse.
 *
 * Use dispatch to render modal as follows:
 *  dispatch({
        type: 'TOGGLE_MODAL',
        payload: {
          subModalTitle: 'Mobile Menu',
          data: "maybe it can't be null",
        },
    });
 * The dispatch type must always say 'TOGGLE_MODAL' to call the modal.
 * The 'subModalTitle' will help the switch statement in this file determine which popup to render.
 * The data cannot be null.
 */

import React, {
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { MdClose } from 'react-icons/md';
import ConfirmQuantity from './SubModals/ConfirmQuantity';
import AreYouSure from './SubModals/AreYouSure';
import ModalError from './SubModals/ModalError';
import MobileMenu from './SubModals/MobileMenu';
import OrderResponse from './SubModals/OrderResponse';
import { store } from '../../hooks/dataContext';
import PublicProjectToggle from './SubModals/PublicProjectToggle';
import PublicNodeToggle from './SubModals/PublicNodeToggle';
import Success from './SubModals/Success';
import AreYouSureNavigate from './SubModals/AreYouSureNavigate';
import DownloadNodeCSV from './SubModals/DownloadNodeCSV';
import DownloadProjectCSV from './SubModals/DownloadProjectCSV';
import ManageNodeUsers from './SubModals/ManageNodeUsers';
import LeaveSharedNode from './SubModals/LeaveSharedNode';
import RemoveNode from './SubModals/RemoveNode';
import './Modal.css';
import EditProject from './SubModals/EditProject';
import DeleteProject from './SubModals/DeleteProject';
import Info from './SubModals/Info';
import DecommissionNode from './SubModals/DecommissionNode';
import { Button } from '@material-ui/core';

const Context = React.createContext();
export function ModalProvider({ children }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  /**  make sure re-render is triggered after initial
  render so that modalRef exists */
  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <div className="portalModalContainer">
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </div>
  );
}

function Modal({
  title,
  data,
  children,
  modal,
  toggleModal,

  ...props
}) {
  const modalNode = useContext(Context);
  const globalState = useContext(store);
  const { dispatch } = globalState;

  /* This switch runs automatically when the Modal mounts and
  chooses the subModal case based on title passed from when the
  modal was called. It takes a variable "data" which is the
  necessary data from function call. */
  const chooseSubModal = () => {
    switch (title) {
      case 'Edit Project':
        return <EditProject project={data} />;
      case 'Confirm node quantity':
        return <ConfirmQuantity data={data} />;
      case 'Are you sure?':
        return <AreYouSure />;
      case 'Are you sure you want to navigate?':
        return <AreYouSureNavigate data={data} />;
      case 'Order Response':
        return <OrderResponse />;
      case 'Mobile Menu':
        return <MobileMenu />;
      case 'Public Node Toggle':
        return <PublicNodeToggle data={data} />;
      case 'Public Project Toggle':
        return <PublicProjectToggle data={data} />;
      case 'Download Node':
        return <DownloadNodeCSV node={data} />;
      case 'Download Project':
        return <DownloadProjectCSV project={data} />;
      case 'Manage Sharing':
        return <ManageNodeUsers title="Manage Sharing" node={data} />;
      case 'Leave Shared Node':
        return (
          <LeaveSharedNode title="Leave Shared Node" node={data} />
        );
      case 'Remove Node':
        return <RemoveNode data={data} />;
      case 'Decommission Node':
        return <DecommissionNode data={data} />;
      case 'Delete Project':
        return <DeleteProject data={data} />;
      case 'Success':
        return <Success />;
      case 'Info':
        return <Info data={data} />;
      case 'Error':
        return <ModalError />;
      default:
        return (
          <>
            <h2>There's been an issue. Please refresh.</h2>
            <button
              className="plum white-text modalSubmitButton"
              type="submit"
              onClick={() =>
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: { data: null },
                })
              }
            >
              Close
            </button>
          </>
        );
    }
  };
  const renderTitle = () => {
    if (
      title &&
      (title === 'Manage Sharing' || title === 'Leave Shared Node')
    ) {
      return <p className="modalTitleText">{title}</p>;
    }
    return null;
  };
  const baseModalComponent = () => (
    <div className="modal">
      <div className="modalTitle">
        {renderTitle()}
        <button className="modalCloseButton">
          <MdClose
            className="closeIcon"
            fontSize={20}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: { data: null },
              })
            }
          />
        </button>
      </div>
      <div className="subModal">{chooseSubModal(data)}</div>
    </div>
  );

  return modalNode
    ? ReactDOM.createPortal(
        <div className="modalOverlay">
          <div {...props}>
            {children}
            {/* {data !== null && baseModalComponent()} */}
            {baseModalComponent()}
          </div>
        </div>,
        modalNode
      )
    : null;
}

export default Modal;
