/**
 * User shouldn't see this ideally.
 * TODO: Could/Should be used in conjunction with an Error Boundary wrapper component.
 * TODO: Could experiment with timed auto-refresh/redirect
 */

import React from 'react';
import './NotFound.css';
import IceCream from '../../assets/sarah-kilian-52jRtc2S_VE-unsplash.jpg';

const NotFound = () => (
  <div className="notFoundContainer page">
    <h1 className="semi-bold">Oh No! Please refresh the page.</h1>
    <img
      src={IceCream}
      alt="Ice cream cone dropped on the hot hot pavement"
    />
  </div>
);

export default NotFound;
