/**
 * Takes user through a multi-step process to add a project.
 */

import React, { useState, useEffect, useContext } from 'react';
import Stepper from '../../components/Stepper/Stepper';
import ProjectInformation from './components/ProjectInformation/ProjectInformation';
import SelectNodes from './components/SelectNodes/SelectNodes';
import NodeSetupRedirect from './components/NodeSetup/NodeSetupRedirect';
import {
  createNewProject,
  getProjects,
} from '../../utils/projects.api';
import { getNodeList } from '../../utils/node.api';
import { store } from '../../hooks/dataContext';
import './AddProject.css';
import { useHistory } from 'react-router-dom';

const AddProject = () => {
  const { state, dispatch } = useContext(store);
  const [stepNumber, setActiveNumber] = useState(1);
  const steps = ['Node Setup', 'Project Information', 'Select Nodes'];
  const [project, setProject] = useState({
    project_name: '',
    start_date: new Date(),
    end_date: null,
    polygon:
      'POLYGON((51.6373 3.0746,51.6374 3.0743,51.6374 3.0745,51.63 3.07493,51.6373 3.0746))',
    metadata: { crop: null, summary: 'Lots to say. ' },
    public: false,
    owner: state.user.id,
  });
  const history = useHistory();

  useEffect(() => {
    getProjects(dispatch);
    getNodeList(state, dispatch);
  }, []);

  const submitProject = () => {
    if (project.project_name == '' || project.start_date == null) {
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {
          subModalTitle: 'Info',
          data: {
            success: false,
            message:
              'Please fill in the missing project information!',
          },
        },
      });
      return;
    }
    createNewProject(project, dispatch, state.nodeGroup);
    history.push('/projectlist');
  };

  // Renders component based on current selected step in stepper
  const stepFlow = () => {
    switch (stepNumber) {
      case 1:
        return (
          <NodeSetupRedirect setActiveNumber={setActiveNumber} />
        );
      case 2:
        return (
          <ProjectInformation
            setActiveNumber={setActiveNumber}
            project={project}
            setProject={setProject}
          />
        );
      case 3:
        return (
          <SelectNodes
            setActiveNumber={setActiveNumber}
            skip={submitProject}
          />
        );
      default:
        return (
          <ProjectInformation setActiveNumber={setActiveNumber} />
        );
    }
  };

  return (
    <div className="page">
      <h1 className="title addProjectTitle">Add a New Project</h1>
      <Stepper
        stepArray={steps}
        activeStepNum={stepNumber}
        setActiveNumber={setActiveNumber}
        finish={submitProject}
      />
      <div className="projectStepContainer">{stepFlow()}</div>
    </div>
  );
};

export default AddProject;
