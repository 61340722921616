/**
 * HOC that accepts a Component prop and only renders it if user is signedIn.
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useGoogleAuth } from '../../hooks/GoogleAuth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn } = useGoogleAuth();
  return (
    <Route
      render={(props) =>
        isSignedIn ? (
          <Component {...props} />
      ) : (
          <Redirect to="/login" />
      )
      }
      {...rest}
    />
  );
};

export default ProtectedRoute;
