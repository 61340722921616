/**
 * Component provides pagination for tables. Used with react-table
 * TODO: Refactor to function component if/when possible.
 */

/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  IoMdArrowDropleft,
  IoMdArrowDropright,
} from 'react-icons/io';
import './Pagination.css';

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: [1],
    };
  }

  componentDidMount() {
    const { pages } = this.props;
    this.getVisiblePages(null, pages);
  }

  componentDidUpdate(prevProps) {
    const { pages } = this.props;
    if (prevProps.pageCount !== pages) {
      this.getVisiblePages(null, pages);
    }
  }

  getVisiblePages(page, total) {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  }

  filterPages(visiblePages, totalPages) {
    const filteredVisible = visiblePages.filter(
      (page) => page <= totalPages,
    );
    this.setState({
      visiblePages: filteredVisible,
    });
    return visiblePages;
  }

  changePage(page) {
    const activePage = page + 1;
    if (page === activePage) {
      return;
    }

    this.props.gotoPage(page - 1);
  }

  render() {
    const { visiblePages } = this.state;
    const {
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      setPageSize,
      pageSize,
      data,
    } = this.props;

    const activePage = this.props.page + 1;

    return (
      <div className="pagination">
        <div className="arrowsAndPages">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <IoMdArrowDropleft />
          </button>
          <div className="Table__visiblePagesWrapper">
            {visiblePages.map((page, index, array) => (
              <button
                key={index}
                className={
                  activePage === page ? 'activeButton' : null
                }
                onClick={() => this.changePage(page)}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </button>
            ))}
          </div>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <IoMdArrowDropright />
          </button>
        </div>
        <div className="showing">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize, i) => (
              <option key={i} value={pageSize}>
                Show{pageSize}
              </option>
            ))}
          </select>
          {data.length <= 10 && activePage === 1 ? (
            <p>{`Showing ${data.length} of ${data.length}`}</p>
          ) : data.length >= 10 &&
            canNextPage === true &&
            pageSize === 10 ? (
            <p>{`Showing ${pageSize} of ${data.length}`}</p>
          ) : data.length >= 10 &&
            canNextPage === false &&
            pageSize >= 10 ? (
            <p>
              {`Showing ${
                data.length - pageSize * (activePage - 1)
              } of ${data.length}`}
            </p>
          ) : data.length > pageSize &&
            canNextPage === true &&
            pageSize > 10 ? (
            <p>{`Showing ${pageSize} of ${data.length}`}</p>
          ) : (
            <p>{`Showing ${data.length} of ${data.length}`}</p>
          )}
        </div>
      </div>
    );
  }
}
