import React, { useContext, useEffect, useState } from 'react';
// import { useMediaQuery } from 'react-responsive';
import { store } from '../../hooks/dataContext';
import { Map } from '../../components/Map/Map';
import NodeHeader from './components/NodeHeader';
import GraphSection from '../../components/Graphs/GraphSection';
import './ViewNode.css';
import { getLevelZeroData } from '../../utils/level0data.api';
import { sub } from 'date-fns';
import _, { isNull } from 'lodash';

const ViewNode = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedNode, nodeData } = state;
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 650 });
  const [hidden, toggleHidden] = useState(true);
  const [endDate, setEndDate] = useState(new Date());
  const [viewingFrom, setViewingFrom] = useState();

  const [loadingAllNodeData, setLoadingAllNodeData] = useState(false);

  const getNodeData = async (node, startDate, endDate) => {
    let data = await getLevelZeroData(
      node.node_id,
      startDate,
      endDate
    );

    dispatch({
      type: 'ADD_NODE_DATA',
      payload: {
        data,
        dateRange: [startDate, endDate],
        node_id: node.id,
      },
    });
    return data;
  };

  const getInitialNodeData = async (node, dispatch) => {
    const [curStart, curEnd] = getNodeDateRange(nodeData, node);
    if (isNull(curStart)) return;
    let day, month;
    let startDate = sub(endDate, { days: 1 });
    // first get the last 24 hours.
    if (startDate < curStart)
      day = await getNodeData(node, startDate, endDate);
    // then get last month
    let monthEndDate = startDate;
    startDate = sub(endDate, { months: 1 });
    if (startDate < curStart)
      month = await getNodeData(node, startDate, monthEndDate);

    //if node has no data
    if (!!day && !!month && month.length + day.length === 0) {
      getAllNodeData(node);
    }
  };

  const getNodeDateRange = (allNodeData, node) => {
    const nodedata = allNodeData[node.id];
    if (!nodedata?.data) return [new Date(), new Date()];
    let anyParametersData = Object.values(nodedata.data)[0];
    return _.get(
      anyParametersData,
      ['dateRange'],
      [new Date(), new Date()]
    );
  };

  const getAllNodeData = async (node) => {
    // get all node data up to the current start date.
    const [startDate] = getNodeDateRange(nodeData, node);
    if (loadingAllNodeData || isNull(startDate)) return;

    setLoadingAllNodeData(true);
    // now get the rest of the data.
    const allData = await getLevelZeroData(
      node.node_id,
      null,
      startDate
    );

    dispatch({
      type: 'ADD_NODE_DATA',
      payload: {
        data: allData,
        dateRange: [null, endDate],
        node_id: selectedNode.id,
      },
    });
    setLoadingAllNodeData(false);
  };

  useEffect(() => {
    getInitialNodeData(selectedNode, dispatch);
  }, [selectedNode]);

  const downloadCSV = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: selectedNode,
        subModalTitle: 'Download Node',
      },
    });
  };

  return (
    <div className="page">
      <NodeHeader
        hidden={hidden}
        toggleHidden={toggleHidden}
        viewingFrom={viewingFrom}
      />
      <div className="nodeMap" hidden={hidden}>
        <Map
          className="mapTest"
          hidden={hidden}
          height={300}
          width="100%"
        />
      </div>
      <div className="nodeDataArea">
        <GraphSection
          node={selectedNode}
          allData={nodeData}
          updateViewingFrom={setViewingFrom}
          getAllNodeData={getAllNodeData}
          downloadCSV={downloadCSV}
        />
      </div>
    </div>
  );
};

export default ViewNode;
