/**
 * AreYouSure is a popup that asks the user to confirm before placing a hardware/node order.
 */

import React, { useContext, useState, useEffect } from 'react';
import { store } from '../../../hooks/dataContext';
import {
  getProjectById,
  updateProject,
} from '../../../utils/projects.api';

import {
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import parseJSON from 'date-fns/parseJSON';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { getProjectNodes } from '../../../utils/projects.api';
import { getNodeList } from '../../../utils/node.api';
import PulseLoader from 'react-spinners/PulseLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 450,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const EditProject = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { project: initialState } = props;
  const [updateModel, setUpdateModel] = useState({
    id: initialState.id,
    metadata: {
      crop: initialState.metadata.crop,
      summary: initialState.metadata.summary,
    },
  });

  const classes = useStyles();
  // in order to do patch efficiently in the backend, we only want to send properties that changed to the updateProject call. So we build state of #changed# fields only (updateModel).
  // Values are initialized from props until updated
  const project = {
    ...initialState,
    start_date: initialState.start_date
      ? parseJSON(initialState.start_date)
      : null,
    end_date: initialState.end_date
      ? parseJSON(initialState.end_date)
      : null,
    ...updateModel,
  };

  //Edit Nodes
  const { nodeList, userNodes, projectNodes, sharedNodes } = state;
  const [allNodes, setAllNodes] = useState([]);
  const [updatedNodes, setUpdatedNodes] = useState(projectNodes);
  const [loadingNodes, setLoadingNodes] = useState(true);

  useEffect(() => {
    assignDefaultNodes();
  }, []);

  const assignDefaultNodes = async () => {
    //set load icon
    setLoadingNodes(false);
    await getNodeList(dispatch);
    //assign public nodes
    const filteredNodes = nodeList.filter(
      (node) => node.is_public == true
    );

    setAllNodes([...userNodes, ...sharedNodes, ...filteredNodes]);
    //existing selected nodes
    await getProjectNodes(initialState.id, dispatch);
    setLoadingNodes(true);
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setUpdateModel({
      ...updateModel,
      [name]: value,
    });
  };

  const togglePublic = () => {
    setUpdateModel({
      ...updateModel,
      is_public: !project.is_public,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let nodeIds = updatedNodes.map((el) => {
      return el.id;
    });
    let nondupIds = [];
    nodeIds.forEach((element) => {
      if (nondupIds.indexOf(element) == -1) {
        nondupIds.push(element);
      }
    });

    await updateProject(updateModel, nondupIds, dispatch);
    let updated = await getProjectById(updateModel.id);
    getProjectNodes(project.id, dispatch);
    dispatch({
      type: 'UPDATE_PROJECT',
      payload: updated,
    });
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  const onCancel = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };

  return (
    <form className="editProjectModal" onSubmit={onSubmit}>
      <div style={{ textAlign: 'left' }}>
        <Typography variant="h6" component="h6" color="primary">
          Edit Project
        </Typography>
      </div>
      <TextField
        fullWidth
        margin="dense"
        label="Project Name"
        value={project.name}
        onChange={handleInputChange}
        name="name"
      />
      <DateTimePicker
        margin="normal"
        label="Start Date"
        value={project.start_date}
        onChange={(start_date) =>
          setUpdateModel({ ...updateModel, start_date })
        }
      />
      <DateTimePicker
        margin="normal"
        label="End Date"
        value={project.end_date}
        clearable
        onChange={(end_date) =>
          setUpdateModel({ ...updateModel, end_date })
        }
      />
      <TextField
        fullWidth
        margin="dense"
        label="Crop"
        value={updateModel.metadata.crop}
        name="crop"
        onChange={(e) => {
          setUpdateModel({
            ...updateModel,
            metadata: {
              ...updateModel.metadata,
              crop: e.target.value,
            },
          });
        }}
      />
      <TextField
        fullWidth
        margin="dense"
        label="Crop Summary"
        value={updateModel.metadata.summary}
        name="summary"
        onChange={(e) => {
          setUpdateModel({
            ...updateModel,
            metadata: {
              ...updateModel.metadata,
              summary: e.target.value,
            },
          });
        }}
      />
      <br />
      <FormControl className="public-switch">
        <InputLabel>Visibility</InputLabel>
        <Select
          label="Visibility"
          value={project.is_public}
          onChange={togglePublic}
        >
          <MenuItem value={false}>Private</MenuItem>
          <MenuItem value={true}>Public</MenuItem>
        </Select>
        <div style={{ marginTop: '1rem' }}>
          {loadingNodes ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Edit Nodes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.root}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="tags-outlined"
                    options={allNodes}
                    defaultValue={projectNodes}
                    getOptionLabel={(option) => option?.node_id}
                    onChange={(e, value) => {
                      setUpdatedNodes(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select nodes"
                        placeholder="Select nodes"
                      />
                    )}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <PulseLoader size={8} color="#006888" />
          )}
        </div>
      </FormControl>

      <div className="editFormBtns-space-between">
        <Button
          variant="contained"
          style={{ backgroundColor: '#ef5350', color: 'white' }}
          onClick={() => {
            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                data: {},
              },
            });
            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                data: { id: project.id },
                subModalTitle: 'Delete Project',
              },
            });
          }}
          type="button"
        >
          Delete
        </Button>
        <div className="editFormButtons">
          <Button onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save Project
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EditProject;
