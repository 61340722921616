import axios from 'axios';

export const getStandardizedHeaders = async () => {
  return await axios
    .get(`${process.env.REACT_APP_IOT_SERVER}/standardized_headers`, {
      rejectUnauthorized: false,
      withCredentials: false,
    })
    .then((res) => {
      return res.data.records;
    })
    .catch((err) => {
      console.warn(
        "There's been an error getting standardized headers",
        err
      );
    });
};

export const getHeaderUoms = async () => {
  return await axios
    .get(`${process.env.REACT_APP_IOT_SERVER}/header_uoms`, {
      rejectUnauthorized: false,
      withCredentials: false,
    })
    .then((res) => {
      return res.data.records;
    })
    .catch((err) => {
      console.warn("There's been an error getting header uoms", err);
    });
};

export const getHeaderTerms = async () => {
  return await axios
    .get(`${process.env.REACT_APP_IOT_SERVER}/header_terms`, {
      rejectUnauthorized: false,
      withCredentials: false,
    })
    .then((res) => {
      return res.data.records;
    })
    .catch((err) => {
      console.warn("There's been an error getting header uoms", err);
    });
};

export const addStandardizedTermMappingsById = async (
  node_instance_id,
  mapping
) => {
  const data = {
    mapping: mapping,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_IOT_SERVER}/standardized_term_mappings/batch/${node_instance_id}`,
      data,
      {
        rejectUnauthorized: false,
        withCredentials: false,
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.warn(
        "There's been an error adding standardized headers",
        err
      );
    });
};

export const getQaqc = async (node_instance_id, node_id) => {
  return await axios
    .get(
      `${process.env.REACT_APP_IOT_SERVER}/qaqc/${node_instance_id}/${node_id}`,
      {
        rejectUnauthorized: false,
        withCredentials: false,
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.warn("There's been fetching qaqc data", err);
    });
};
