import React from 'react';
import './Legal.css';

const Legal = () => (
    <div className="legalContainer">
      <h1>G.E.M.S.&trade; Terms of Use Agreement</h1>
      <p>
        G.E.M.S™ is an integrated data storage and sharing (GEMShare™) and
        analytical (GEMSTools™) platform to enable research collaborations for
        innovation in food, agricultural production and other domain areas.
      </p>
      <h2>1. Terms and Conditions</h2>
      <p>
        PLEASE READ THESE TERMS OF USE (“AGREEMENT” OR “TERMS OF USE”) CAREFULLY
        BEFORE USING THE SERVICES OFFERED BY THE G.E.M.S™ PLATFORM. THIS
        AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR
        USE OF THE PLATFORM AT{" "}
        <a href="https://www.gems.agroinformatics.org">
          GEMS.AGROINFORMATICS.ORG
        </a>{" "}
        (THE “PLATFORM”) AND THE SERVICE OWNED AND OPERATED BY THE G.E.M.S™
        PLATFORM (INCLUDING YOUR USE OF SUCH SERVICE WHEN MADE AVAILABLE THROUGH
        SOFTWARE OWNED, OPERATED, OR LICENSED BY THE G.E.M.S™ PLATFORM OR
        THROUGH A THIRD PARTY WEBSITE) (COLLECTIVELY WITH THE PLATFORM, THE
        “SERVICE”). BY USING THE SERVICE IN ANY MANNER, INCLUDING BUT NOT
        LIMITED TO VISITING OR BROWSING THE PLATFORM, YOU AGREE TO BE BOUND BY
        THIS AGREEMENT. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL THE TERMS AND
        CONDITIONS OF THIS AGREEMENT, YOU HAVE NO RIGHT TO USE THE SERVICE. THIS
        AGREEMENT APPLIES TO ALL USERS OF THE PLATFORM OR SERVICE.
      </p>
      <p>
        THE G.E.M.S™ PLATFORM WILL POST NOTICE OF MODIFICATIONS TO THESE TERMS
        ON{" "}
        <a href="https://www.gems.agroinformatics.org">
          GEMS.AGROINFORMATICS.ORG
        </a>
        . BY CONTINUING TO USE THE G.E.M.S™ PLATFORM AFTER THE REVISIONS COME
        INTO EFFECT, YOU AGREE TO BE BOUND BY THE REVISED TERMS.
      </p>
      <h2>2. Representations by Organizations</h2>
      <p>
        If you agree to these terms on behalf of a business, organization,
        agency or institution “an Organization,” you represent and warrant that
        (i) you have authority to bind that Organization to this Agreement, (ii)
        your agreement to these terms will be treated as the agreement of the
        Organization, and (iii) any individual or entity to whom you provide
        access to the Software shall be made aware of and shall be bound by
        these terms. In that case, “you” and “your” in this Agreement shall
        refer to the Organization you represent.
      </p>

      <h2>3. Acceptance of Terms</h2>
      <p>
        The Service is offered subject to acceptance without modification of all
        of the terms and conditions contained herein (the “Terms of Use”), which
        terms also incorporate the G.E.M.S™ Platform’s Privacy Policy (available
        at{" "}
        <a href="https://www.gems.agroinformatics.org">
          GEMS.AGROINFORMATICS.ORG
        </a>
        . The Service is available only to individuals who are at least 13 years
        old. If you are under 13, you may not use or attempt to register for the
        Service.
      </p>
      <p>
        You represent and warrant that you are an individual, you are of legal
        age to form a binding contract, that all registration information you
        submit is accurate and truthful and that you will maintain the accuracy
        and truthfulness of such information by accessing your preference
        settings from within the Service. If you are accepting these terms as an
        individual on behalf of a corporation, partnership, or other
        organization, you represent that you have authority to bind such entity
        to these Terms of Use. The G.E.M.S™ Platform may, in its sole
        discretion, refuse to offer the Service to any person or entity and
        change its eligibility criteria at any time. This provision is void
        where prohibited by law and the right to access the Service is revoked
        in such jurisdictions.
      </p>
      <h2>4. Registration</h2>

      <p>
        You may browse the Platform without registering, but as a condition to
        using certain aspects of the Service, you may be required to register
        with the G.E.M.S™ Platform via creating a Globus account. The Globus
        account will connected to your home institution where your home
        institution is a member of the InCommon Federation or by creating a
        GlobusID.org account with a username and password. You shall provide the
        G.E.M.S™ Platform via Globus with accurate, complete, and updated
        registration information. Failure to do so shall constitute a breach of
        the Terms of Use, which may result in immediate termination of your
        G.E.M.S™ Platform account. You shall not (i) select or use as a User ID
        a name of another person with the intent to impersonate that person;
        (ii) use as a User ID a name subject to any rights of a person other
        than you without appropriate authorization; or (iii) use as a User ID a
        name that is otherwise offensive, vulgar or obscene. The G.E.M.S™
        Platform reserves the right to refuse registration of, or cancel a User
        ID in its sole discretion. You are solely responsible for activity that
        occurs on your account and shall be responsible for maintaining the
        confidentiality of your G.E.M.S™ Platform account via your institutional
        account or GlobusID.org password. You shall never use another user’s
        account without such other user’s express permission. You will
        immediately notify the G.E.M.S™ Platform in writing of any unauthorized
        use of your account, or other account related security breach of which
        you are or become aware.
      </p>
      <h2>5. Content and License</h2>
      <p>
        You agree that the Service contains Content specifically provided by the
        G.E.M.S™ Platform, its partners and other third parties, and that such
        Content is protected by copyrights, trademarks, service marks, patents,
        trade secrets or other proprietary rights and laws. You shall abide by
        and maintain all copyright notices, information, and restrictions
        contained in any Content accessed through the Service.
      </p>
      <p>
        Unless you have the expressed written permission of the Content owner,
        you shall not sell, license, rent, or otherwise use or exploit any
        Content for commercial use or in any way that violates any third party
        right.
      </p>
      <h2>6. Third Part Sites</h2>
      <p>
        The G.E.M.S™ Platform may make the Service available on or through other
        websites or resources on the Internet, and other websites or resources
        may contain links to the Platform. When you access third party websites,
        you do so at your own risk. These other websites are not under the
        G.E.M.S™ Platform's control, and you acknowledge that the G.E.M.S™
        Platform is not responsible or liable for the content, functions,
        accuracy, legality, appropriateness or any other aspect of such websites
        or resources. The inclusion of any such link does not imply endorsement
        by the G.E.M.S™ Platform or any association with its operators. You
        further acknowledge and agree that the G.E.M.S™ Platform shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any content, goods or services available on or through any
        such website or resource.
      </p>

      <h2>7. User Submissions</h2>
      <p>
        The Service provides you with the ability to add, create, upload,
        submit, distribute or post (“Submit,” “Submitting” or “Submission”)
        content, articles, data, text, photographs, images, illustrations, or
        other information on or to the Platform or Service (collectively, the
        “User Submissions”). By way of example, and not as a limitation, User
        Submissions may be Submitted when you request the Service to (i)
        identify, resolve, copy, or import content stored on your or a third
        party’s computer system, (ii) synchronize content such that the Service
        stores or “mirrors” content stored on your or a third party’s computer
        system by storing such content on equipment owned or operated by the
        G.E.M.S™ Platform, (iii) save or store your comments, edits or
        annotations to Content accessible through the Service, or (iv) share
        with another person any content stored on your or a third party’s
        computer system.
      </p>
      <p>
        You represent and warrant that the use or other exploitation of any User
        Submissions by You or the G.E.M.S™ Platform as contemplated by this
        Agreement will not infringe or violate the rights of any third party,
        including without limitation any privacy rights, publicity rights,
        copyrights, contract rights, or any other intellectual property or
        proprietary rights. Except in the case of User Submissions containing
        content exclusively in the public domain, you represent and warrant, and
        can demonstrate to the G.E.M.S™ Platform’s full satisfaction upon
        request that (i) you own or otherwise control any and all rights or
        licences required in order to Submit all content in your User
        Submissions and to permit the G.E.M.S™ Platform to use such content as
        contemplated by these Terms of Use, and (ii) you have full authority to
        act on behalf of any and all owners of any right, title or interest in
        and to any content in your User Submissions to use such content as
        contemplated by these Terms of Use.
      </p>
      <p>
        You understand that all information publicly posted or privately
        transmitted through the Platform or Service is the sole responsibility
        of the person from which such content was Submitted. The G.E.M.S™
        Platform will not be liable for any errors or omissions in any content,
        and may delete, modify, or reformat any materials, content or
        information Submitted by you. The G.E.M.S™ Platform does not endorse and
        has no control over any content Submitted to the Service. The G.E.M.S™
        Platform cannot guarantee the authenticity of any User Submission. You
        acknowledge that all Content accessed by you in connection with your use
        of the Service is at your own risk and you will be solely responsible
        for any damage or loss to any party resulting from your access to or
        your inability to access such Content.
      </p>
      <p>
        If You have chosen to make Your Information Publicly Available, You
        understand that the G.E.M.S™ Platform may share User Submissions with
        third parties in connection with your use of the Service, and that The
        G.E.M.S™ Platform cannot and does not control any such third parties.
        You acknowledge that The G.E.M.S™ Platform makes no representation or
        warranty regarding the privacy of any User Submissions and that you have
        no expectation of privacy with respect to any User Submissions unless
        expressly provided otherwise by The G.E.M.S™ Platform’s Privacy Policy,
        located at{" "}
        <a href="https://www.gems.agroinformatics.org">
          GEMS.AGROINFORMATICS.ORG
        </a>
        .
      </p>
      <h2>8. Terminations</h2>
      <p>
        The G.E.M.S™ Platform may terminate your access to all or any part of
        the Service at any time if: (a) you breach any provision of this
        Agreement or any other agreement between you and Provider; or (b) the
        G.E.M.S™ Platform is required to do so by applicable law, as determined
        by the G.E.M.S™ Platform in its sole discretion. You or the G.E.M.S™
        Platform may terminate this Agreement by providing no less than 90 days
        written notice that the service will no longer be required or offered.{" "}
      </p>
      <h2>9. Warranty Disclaimer</h2>
      <p>
        Unless required by applicable law or agreed to in writing, the G.E.M.S™
        Platform and its Content is provided on an "AS IS" BASIS, WITHOUT
        WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied,
        including, without limitation, any warranties or conditions of TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE.
        You are solely responsible for determining the appropriateness of using
        the G.E.M.S™ Platform and its Content and assume any risks associated
        with Your exercise of permissions under this Agreement.
      </p>

      <h2>10. Indemnification</h2>
      <p>
        You shall defend, indemnify, and hold harmless the G.E.M.S™ Platform
        and, its affiliates, employees, contractors, directors, suppliers and
        representatives from all liabilities, claims, and expenses, including
        reasonable attorneys' fees, that arise from or relate to your use or
        misuse of, or access to, the Platform, Service, or Content, violation of
        the Terms of Use, or infringement by you, or any third party using your
        account, of any intellectual property or other right of any person or
        entity. The G.E.M.S™ Platform reserves the right to assume the exclusive
        defense and control of any matter otherwise subject to indemnification
        by you, in which event you will assist and cooperate with the G.E.M.S™
        Platform in asserting any available defenses.
      </p>

      <h2>11. Limitation of Liability</h2>

      <p>
        In no event and under no legal theory, whether in tort (including
        negligence), contract, or otherwise, unless required by applicable law
        (such as deliberate and grossly negligent acts) or agreed to in writing,
        shall the G.E.M.S™ Platform be liable to You for damages, including any
        direct, indirect, special, incidental, or consequential damages of any
        character arising as a result of the use or inability to use the
        G.E.M.S™ Platform or its Content (including but not limited to damages
        for loss of goodwill, work stoppage, computer failure or malfunction, or
        any and all other commercial damages or losses), even if the G.E.M.S™
        Platform has been advised of the possibility of such damages.
      </p>
      <p>
        Some states do not allow the exclusion or limitation of incidental or
        consequential damages, so the above limitations and exclusions may not
        apply to You.
      </p>
      <h2>12. Fees and Payment</h2>
      <p>
        Although some Services and Content are currently free to users, the
        G.E.M.S™ Platform reserves the right to amend or withdraw such free
        Services or Content at any time. Fee-for-Service use of the G.E.M.S™
        Platform requires payment in order to be accessed by users. You shall
        pay all applicable fees, as described on the Platform or in agreements
        made in writing with the G.E.M.S™ Platform, or its designated affiliates
        in connection with any such Services selected by you. Failure to make
        the appropriate payment will result in the withdrawal of your access to
        the charged-for Services. The G.E.M.S™ Platform reserves the right to
        change its price list and to institute new charges at any time, upon
        notice to you, which may be sent by email or posted on the Platform.
        Your continuing use of the Services and Content following such
        notification constitutes your acceptance of any new or increased
        charges. Any fees paid hereunder are non-refundable (your statutory
        rights are not affected).
      </p>

      <h2>13. Dispute Resolution</h2>
      <p>
        A printed version of the Terms of Use and of any notice given in
        electronic form shall be admissible in judicial or administrative
        proceedings based upon or relating to the Terms of Use to the same
        extent and subject to the same conditions as other business documents
        and records originally generated and maintained in printed form. You and
        the G.E.M.S™ Platform agree that any cause of action arising out of or
        related to the Service must commence within one (1) year after the cause
        of action arose; otherwise, such cause of action is permanently barred.
      </p>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of Minnesota. Any dispute arising from or relating to the subject
        matter of this Agreement shall be finally settled by in Hennepin County
        District Court in Minneapolis, Minnesota. For all purposes of this
        Agreement, the parties consent to exclusive jurisdiction and venue in
        Minnesota. Use of the Service is not authorized in any jurisdiction that
        does not give effect to all provisions of the Terms of Use, including
        without limitation, this Section.
      </p>
      <h2>14. Integration and Severability</h2>
      <p>
        The Terms of Use are the entire agreement between you and the G.E.M.S™
        Platform with respect to the Platform and use of the Service, and
        supersede all prior or contemporaneous communications and proposals
        (whether oral, written or electronic) between you and the G.E.M.S™
        Platform with respect to the same. If any provision of the Terms of Use
        is found to be unenforceable or invalid, that provision will be limited
        or eliminated to the minimum extent necessary so that the Terms of Use
        will otherwise remain in full force and effect and enforceable. The
        failure of either party to exercise in any respect any right provided
        for herein shall not be deemed a waiver of any further rights hereunder.
      </p>
      <h2>15. Copyright Dispute Policy</h2>
      <p>
        The G.E.M.S™ Platform has adopted the following general policy toward
        copyright infringement in accordance with the European Union Copyright
        Directive. The address of The G.E.M.S™ Platform’s Designated Agent to
        Receive Notification of Claimed Infringement (“Designated Agent”) is
        listed at the end of this Section. It is the G.E.M.S™ Platform’s policy
        to (1) block access to or remove material that it believes in good faith
        to be copyrighted material that has been illegally copied and
        distributed by any of our advertisers, affiliates, content providers,
        members or users; and (2) remove and discontinue service to repeat
        offenders.
      </p>
      <div className="copyrightProcedure">
        <p>Procedure for Reporting Copyright Infringements:</p>
        <p>
          i. If you believe that material or content residing on or accessible
          through the Services infringes a copyright, please send a notice of
          copyright infringement containing the following information to the
          Designated Agent listed below:{" "}
        </p>
        <p>
          ii. A physical or electronic signature of a person authorized to act
          on behalf of the owner of the copyright that has been allegedly
          infringed;
        </p>
        <p>iii. Identification of works or materials being infringed;</p>
        <p>
          iv. Identification of the material that is claimed to be infringing
          including information regarding the location of the infringing
          materials that the copyright owner seeks to have removed, with
          sufficient detail so that the G.E.M.S™ Platform is capable of finding
          and verifying its existence;
        </p>
        <p>
          v. Contact information about the notifier including address, telephone
          number and, if available, email address;
        </p>
        <p>
          vi. A statement that the notifier has a good faith belief that the
          material identified in (3) is not authorized by the copyright owner,
          its agent, or the law; and
        </p>
        <p>
          vii. A statement made under penalty of perjury that the information
          provided is accurate and the notifying party is authorized to make the
          complaint on behalf of the copyright owner. Once Proper Bona Fide
          Infringement Notification is Received by the Designated Agent:
        </p>
        <p>viii. It is The G.E.M.S™ Platform’s policy:</p>
        <p>ix. to remove or disable access to the infringing material;</p>
        <p>
          x. to notify the content provider, member or user that it has removed
          or disabled access to the material; and
        </p>
        <p>
          xi. that repeat offenders will have the infringing material removed
          from the system and that The G.E.M.S™ Platform will terminate such
          content provider’s, member’s or user’s access to the Service.
        </p>
        <p>
          xii. Procedure to Supply a Counter-Notice to the Designated Agent:
        </p>
        <p>
          xiii. If the content provider, member or user believes that the
          material that was removed (or to which access was disabled) is not
          infringing, or the content provider, member or user believes that it
          has the right to post and use such material from the copyright owner,
          the copyright owner’s agent, or, pursuant to the law, the content
          provider, member, or user, must send a counter- notice containing the
          following information to the Designated Agent listed below:
        </p>
        <p>
          xiv: A physical or electronic signature of the content provider,
          member or user;
        </p>
        <p>
          xv. Identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or disabled;
        </p>
        <p>
          xvi. A statement that the content provider, member or user has a good
          faith belief that the material was removed or disabled as a result of
          mistake or misidentification of the material; and
        </p>
      </div>
      <p>
        If a counter-notice is received by the Designated Agent, the G.E.M.S™
        Platform may send a copy of the counter-notice to the original
        complaining party informing that person that the G.E.M.S™ Platform may
        replace the removed material or cease disabling it in 10 business days.
        Unless the copyright owner files an action seeking a court order against
        the content provider, member or user, the removed material may be
        replaced or access to it restored in 10 to 14 business days or more
        after receipt of the counter-notice, at The G.E.M.S™ Platform’s
        discretion. Please contact the G.E.M.S™ Platform’s Designated Agent to
        Receive Notification of Claimed Infringement at the following address:{" "}
        <a href="mailto:gemssupport@umn.edu">gemssupport@umn.edu</a>
      </p>
    </div>
  );

export default Legal;
