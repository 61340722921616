import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useGoogleAuth } from '../../hooks/GoogleAuth';
import farmField from '../../assets/sproutingCrops.jpg';
import './Login.css';

const Login = () => {
  const { signIn, isSignedIn } = useGoogleAuth();

  if (!isSignedIn) {
    return (
      <div className="page">
        <h1 className="title">Login</h1>
        <div className="loginContainer">
          <img
            src={farmField}
            className="loginImage"
            alt="field of corn growing"
          />
          <button
            className="blue white-text"
            onClick={() => signIn()}
          >
            <Link className="white-text" to="/landing">
              Login
            </Link>
          </button>
        </div>
      </div>
    );
  }
  return <Redirect to="/landing" />;
};

export default Login;
