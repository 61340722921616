/**
 * Toggle switch that allows user to toggle node/project/any between public/private.
 * Props: type ('project' or 'node') and item (obj w/ is_public key)
 */

import React, { useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { store } from '../../hooks/dataContext';

export default function PublicSwitch(props) {
  const { type, item, disabled } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const chooseType = () => {
    switch (type) {
      case 'project':
        return (
          <div>
            <Select
              disabled={disabled}
              label="Visibility"
              value={item.is_public}
              onChange={() =>
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    data: item,
                    subModalTitle: 'Public Project Toggle',
                    open: true,
                  },
                })
              }
            >
              <MenuItem value={false}>Private</MenuItem>
              <MenuItem value={true}>Public</MenuItem>
            </Select>
          </div>
        );
      case 'node':
        return (
          <Select
            disabled={disabled}
            label="Visibility"
            value={item.is_public}
            onChange={() =>
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                  data: item,
                  subModalTitle: 'Public Node Toggle',
                  open: true,
                },
              })
            }
          >
            <MenuItem value={false}>Private</MenuItem>
            <MenuItem value={true}>Public</MenuItem>
          </Select>
        );

      default:
        break;
    }
  };
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        {chooseType()}
      </FormGroup>
    </FormControl>
  );
}
