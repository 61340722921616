import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { store } from '../../../hooks/dataContext';
import DotLoader from 'react-spinners/DotLoader';
import {
  updateNodeUsers,
  getNodeUsersByNodeId,
} from '../../../utils/node.api';
import {
  Button,
  Chip,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { isArray } from 'lodash';

const ManageNodeUsers = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { node } = props;
  const [loading, setLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(node.is_public);
  const [value, setValue] = React.useState([]);
  const [listInput, setListInput] = React.useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const inputFile = useRef(null);
  const data = [];

  const fetchData = async () => {
    getNodeUsersByNodeId(node.id).then((result) => {
      setValue(
        result.records.map((x) => {
          return x.email;
        })
      );
    });
  };

  // Fetch data when component mounted
  useEffect(() => {
    fetchData();
  }, []);

  const handleSetSharingData = async (emailList) => {
    setLoading(true);

    const requestPayload = {
      node_ids: '' + node.id,
      emails: emailList,
      is_public: isPublic,
    };

    updateNodeUsers(requestPayload).then((x) => {
      setLoading(false);
      toast.success('Node users updated', {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {},
      });
    });
  };

  const onCancel = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        data: {},
      },
    });
  };
  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  };
  const submit = async (e) => {
    const val = listInput;

    e.preventDefault();
    if (!val || val.length == 0) {
      await handleSetSharingData(value.toString());
    } else {
      addEmailToList(val, true);
    }
  };
  const addEmailToList = (input, submit) => {
    if (isArray(input)) {
      var arr = value ?? [];
      input.map((email) => {
        if (email && isEmail(email) && arr.indexOf(email) == -1) {
          arr.push(email);
        }
      });

      setValue(
        arr.map((x) => {
          return x;
        })
      );
    } else if (input && input.length > 0 && isEmail(input)) {
      if (value.indexOf(input) == -1) {
        setValue([...(value ?? []), input]);
      }
      setListInput('');
      if (submit && submit == true) {
        handleSetSharingData(
          [...(value ?? []), input.trim()].toString()
        );
      }
    } else {
      toast.warning('Invalid email ', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const inputCsvData = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      var emails = text.split('\n').map((line) => {
        if (isEmail(line.trim())) {
          return line.trim();
        } else {
          alert('not email' + line);
        }
      });
      if (emails.length > 0) {
        addEmailToList(emails, false);
      }
    };
    reader.readAsText(file);
  };
  const setPublic = (value) => {
    setIsPublic(value);
    node.is_public = value;
  };
  const publicPrivateText = () => {
    if (isPublic) {
      return 'Set your node to Private to share it with selected people only.';
    } else {
      return 'Set your node to Public to make it available for all users.';
    }
  };
  const publicPrivateSwitch = (node) => {
    return (
      <div>
        <Select
          label="Visibility"
          value={isPublic}
          onChange={(e) => {
            setPublic(e.target.value);
          }}
        >
          <MenuItem value={true}>Public</MenuItem>
          <MenuItem value={false}>Private</MenuItem>
        </Select>
      </div>
    );
  };
  return (
    <form className="csvNodeModal" onSubmit={submit}>
      <div className="sharingLabelBlock">
        <div className="sharingLabelBlockContent">
          <div className="sharingLabelBlockContentLeft">
            <Typography>{node.node_id}</Typography>
            <Typography>{node.name}</Typography>
          </div>
          <div>
            <Typography>{publicPrivateSwitch(node)}</Typography>
          </div>
          <br />
        </div>
        <div className="sharingLabelBlockContentLeftBl">
          <Typography>{publicPrivateText()}</Typography>
        </div>
      </div>
      <div className="shareUserInputContainer">
        <TextField
          variant="standard"
          label="Shared with users"
          placeholder="Email Address"
          disabled={isPublic}
          name="shareInput2"
          value={listInput}
          onChange={(e) => setListInput(e.target.value)}
          inputProps={{
            autocomplete: 'off',
            form: {
              autocomplete: 'off',
            },
          }}
          onKeyPress={(event) => {
            if (event.key === ' ' || event.key === 'Enter') {
              event.preventDefault();
              var val = event.target.value;
              addEmailToList(val, false);
            }
          }}
        />
        <div className="uploadCSVBlock">
          <p>
            {' '}
            or{' '}
            <Link
              disabled={isPublic}
              onClick={(event) => {
                if (!isPublic) {
                  inputFile.current.click();
                }
              }}
            >
              upload csv
            </Link>
          </p>
          <input
            className="hiddenUpload"
            type={'file'}
            id={'csvFileInput'}
            accept={'.csv'}
            ref={inputFile}
            onChange={(e) => {
              setSelectedFile(e.target.files[0]);
              inputCsvData(e.target.files[0]);
            }}
          />
        </div>
      </div>
      <Autocomplete
        multiple
        disabled={isPublic}
        id="tags-filled"
        options={data.map((option) => option.label)}
        freeSolo
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            className="hiddenInput"
            variant="standard"
            placeholder="Email Address"
            name="shareInput"
            disabled={true}
          />
        )}
      />

      <div className="editFormButtons">
        {loading ? (
          <DotLoader size={20} color="#006888" />
        ) : (
          <div>
            <Button onClick={onCancel} type="button">
              Discard Changes
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Confirm
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default ManageNodeUsers;
