import React, { useState } from 'react';
import './Help.css';
import { submitHelpForm } from '../../utils/help';
import Paper from '@material-ui/core/Paper';
import {
  Typography,
  TextField,
  Button,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

function Help(props) {
  const [formResponse, setFormResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  //Accepts a messageProp
  //Passed like this: <Link to={{ pathname: '/help', messageProp: 'This is a message' }}>

  const [helpForm, setHelpForm] = useState({
    name: '',
    email: '',
    phone: null,
    subject: '',
    message: props.location.messageProp || '',
  });

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await submitHelpForm(helpForm);
    setLoading(false);
    setFormResponse({
      success: response.success,
      message: response.message,
    });
  };

  const requiredError = (val) => {
    if (val == '' && submitted) {
      return true;
    }
    return false;
  };

  return (
    <div className="page">
      <div className="helpContactForm">
        <form onSubmit={(e) => submitForm(e)}>
          <Paper elevation={3} className="helpForm">
            <h1 style={{ fontWeight: 'bold' }}>Help</h1>
            <br />
            <Typography style={{ color: 'gray' }}>
              Submit your question or comment here and someone will
              reach out to you. Thank you.
            </Typography>
            <TextField
              fullWidth
              placeholder={'Name'}
              label="Name"
              error={requiredError(helpForm.name)}
              required
              onChange={(e) => {
                setHelpForm({ ...helpForm, name: e.target.value });
              }}
            />
            <br />
            <TextField
              fullWidth
              placeholder={'Email'}
              label="Email"
              type="email"
              error={requiredError(helpForm.email)}
              required
              onChange={(e) =>
                setHelpForm({ ...helpForm, email: e.target.value })
              }
            />
            <br />
            <TextField
              fullWidth
              placeholder={'Phone (optional)'}
              label="Phone (optional)"
              onChange={(e) =>
                setHelpForm({ ...helpForm, phone: e.target.value })
              }
            />
            <br />
            <TextField
              fullWidth
              placeholder={'Subject'}
              label="Subject"
              required
              error={requiredError(helpForm.subject)}
              onChange={(e) =>
                setHelpForm({ ...helpForm, subject: e.target.value })
              }
            />
            <br />
            <br />
            <TextField
              fullWidth
              minRows={4}
              placeholder={'Message'}
              label="Message"
              required
              multiline
              error={requiredError(helpForm.message)}
              variant="outlined"
              value={helpForm.message}
              onChange={(e) =>
                setHelpForm({ ...helpForm, message: e.target.value })
              }
            />
            <br />
            {/* <div>
                <br />
                <Alert severity="error">
                  This form contains errors!
                </Alert>
              </div> */}
            <br />
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <Button
                type="submit"
                onClick={() => setSubmitted(true)}
                variant="contained"
                color="primary"
                disabled={formResponse?.success ? true : false}
              >
                SUBMIT
              </Button>
            )}

            {formResponse && (
              <div>
                <br />
                <Alert
                  severity={
                    formResponse.success ? 'success' : 'error'
                  }
                >
                  {formResponse.message}
                </Alert>
              </div>
            )}
          </Paper>
        </form>
      </div>
    </div>
  );
}

export default Help;
