/**
 * Custom hook allows console.log to remain in codebase bc logs only log in 'dev' mode.
 * Also helpful to use with the context provider to handle logging like Redux.
 */

const ConsoleLogger = (text = 'log', data) => {
  if (process.env.NODE_ENV === 'production') return;
  console.log(`${text}`, data);
};

export default ConsoleLogger;
