import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaStarOfLife } from 'react-icons/fa';
import { getProjects } from '../../utils/projects.api';
import { getNodeList } from '../../utils/node.api';
import { store } from '../../hooks/dataContext';
import RegisterNode from '../RegisterNode/RegisterNode';
import './AddNode.css';
import { Typography } from '@material-ui/core';

const AddNode = () => {
  const { dispatch, state } = useContext(store);

  useEffect(() => {
    getProjects(dispatch);
    getNodeList(state, dispatch);
  }, []);

  return (
    <div className="page">
      <Typography variant="h4">Register New Node</Typography>
      <RegisterNode />
    </div>
  );
};

export default AddNode;
