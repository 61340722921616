import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';
import { removeNode } from '../../../utils/projects.api';
import { Button } from '@material-ui/core';

const RemoveNode = (props) => {
  const { data } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <div>
      <p>Are you sure you want to remove this node?</p>
      <br />
      <Button
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
        type="button"
      >
        <p>No</p>
      </Button>
      <Button
        type="button"
        variant="contained"
        color="primary"
        className="blue white-text"
        onClick={() => {
          removeNode(data.id, data.node_id, data.owner, dispatch);
        }}
      >
        <p>Yes I'm sure</p>
      </Button>
    </div>
  );
};

export default RemoveNode;
