/**
 * Wrapper renders a horizontal list of tabs (children component).
 * When user clicks new tab, new info area displayed.
 * Each tab (child child component) needs a label or the Tab will be blank.
 *
 * TODO: better transition animations?
 */

import React, { useState } from 'react';
import Tab from '../Tab/Tab';
import './TabList.css';

const TabList = (props) => {
  const { children } = props;
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <nav>
        <div id="setupNav" className="nav nav-tabs" role="tablist">
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </div>
      </nav>
      <div className="tab-content" id="setupTabContent">
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return (
            <div key={child.props.label}>{child.props.children}</div>
          );
        })}
      </div>
    </div>
  );
};

export default TabList;
