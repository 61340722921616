import axios from 'axios';

export const submitHelpForm = async (helpForm) => {
  const data = helpForm;
  try {
    return await axios
      .post(`${process.env.REACT_APP_IOT_SERVER}/help`, data, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return { success: false, message: 'Something went wrong' };
      });
  } catch (e) {
    console.warn("There's been an error sending your form", e);
  }
};
