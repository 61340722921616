/**
 * Wrapper component thatcreates a horzontal bar that user can click to reveal hidden data below.
 * Hidden data is passed in as child compoent.
 * Component needs title prop. Also accepts unique icon prop.
 */

import React, { useState, useRef } from 'react';
import {
  IoMdArrowDropdown,
  IoMdArrowDropright,
} from 'react-icons/io';
import './Accordion.css';

const Accordion = (props) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  const toogleActive = () => {
    setActive(!active);
  };
  const { title, children, icon } = props;
  return (
    <div className="accordion">
      <div
        className="accordionButton blue white-text"
        onClick={toogleActive}
        role="button"
        aria-hidden="true"
      >
        <p>{title}</p>
        {active ? (
          <IoMdArrowDropdown className="arrow" />
        ) : (
          <IoMdArrowDropright className="arrow" />
        )}
        {icon}
      </div>

      {active && (
        <div ref={contentRef} className="accordionContent">
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
