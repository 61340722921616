import React, { useState } from 'react';
import NodeSetup from './components/NodeSetup/NodeSetup';
import RegisterNode from '../RegisterNode/RegisterNode';
import Stepper from '../../components/Stepper/Stepper';
import './Setup.css';
import FieldSetup from './components/FieldSetup/FieldSetup';
import { useHistory, useParams } from 'react-router';

const DEFAULT_STEP_NUMBER = 2;

function Setup() {
  const { step } = useParams(); // stepper should be controlled by url parameter. Step parameter should be contained in steps array.
  const history = useHistory();
  const steps = ['assemble', 'register', 'deploy'];
  const stepLabels = ['Node Assembly', 'Register', 'Field Deploy'];
  const getStepNumberFromParam = () => {
    let stepNum = steps.indexOf(step) + 1;
    return stepNum > 0 ? stepNum : DEFAULT_STEP_NUMBER;
  };

  const stepNumber = getStepNumberFromParam();

  // Renders component based on current step in stepper.
  const stepFlow = () => {
    switch (stepNumber) {
      case 1:
        return <NodeSetup />;
      case 2:
        return <RegisterNode setActiveNumber={changeStep} />;
      case 3:
        return <FieldSetup setActiveNumber={changeStep} />;
      default:
        return <NodeSetup />;
    }
  };

  const changeStep = (stepNumber) => {
    const stepQuery = steps[stepNumber - 1];
    history.push(`/devicesetup/${stepQuery}`);
  };

  return (
    <div className="setupPage">
      <h1 className="title">Node Assembly & Registration</h1>
      <div className="stepperArea">
        <Stepper
          stepArray={stepLabels}
          activeStepNum={stepNumber}
          setActiveNumber={changeStep}
        />
      </div>
      <div className="stepContainer">{stepFlow()}</div>
    </div>
  );
}

export default Setup;
