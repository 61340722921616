import React, { useState, useEffect, useContext } from 'react';
import './Qaqc.css';
import { store } from '../../hooks/dataContext';
import {
  Tooltip,
  Button,
  Select,
  FormControl,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {
  addStandardizedTermMappingsById,
  getQaqc,
} from '../../utils/ontology';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginTop: '4rem',
  },
}));

function Qaqc(props) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedNode } = state;
  const node = selectedNode;

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const tableHeaders = [
    { label: 'Set/unset', desc: 'This is some text' },
    { label: 'Term', desc: 'This is some text' },
    { label: 'On Device Units', desc: 'This is some text' },
    { label: 'Select Ontology Term', desc: 'This is some text' },
    { label: 'Definition', desc: 'This is some text' },
    { label: 'Ontology Units', desc: 'This is some text' },
    { label: 'Link to SOP', desc: 'This is some text' },
    { label: 'Auto-QAQC', desc: 'This is some text' },
  ];

  const [deviceUnits, setDeviceUnits] = useState([]);
  const [ontologyTerms, setOntologyTerms] = useState([]);
  const [standardizedHeaders, setStandardizedHeaders] = useState([]);
  const [standardizedTermMappings, setStandardizedTermMappings] =
    useState([]);

  //form ui
  const [success, setSuccess] = useState(false);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const getFilteredTerms = (mapping) => {
    const unitId = mapping.deviceUnit;
    if (mapping.deviceUnit == '') {
      return;
    }
    let filteredHeaders = [];
    filteredHeaders = standardizedHeaders.filter((header) => {
      return header.header_uom_id == unitId;
    });
    let termIds = [];
    termIds = filteredHeaders.map((term) => {
      return term.header_term_id;
    });
    let filteredTerms = [];
    filteredTerms = ontologyTerms.filter((term, idx) => {
      return termIds.includes(term.id);
    });

    return filteredTerms.map((term) => (
      <MenuItem value={term.id}>{term.name}</MenuItem>
    ));
  };

  const getOntologyDescription = (mapping) => {
    let standardizedHeader = null;
    if (standardizedHeaders == []) {
      return;
    }
    standardizedHeader = standardizedHeaders?.filter((header) => {
      return (
        header.header_term_id == mapping.ontologyTerm &&
        header.header_uom_id == mapping.deviceUnit
      );
    });
    return standardizedHeader[0]?.description;
  };

  const fetchData = async () => {
    setLoading(true);
    //fetch data
    if (!node.id && !node.node_id) {
      setLoading(false);
      return;
    }
    const qaqcData = await getQaqc(node.id, node.node_id);
    //set variables
    setStandardizedHeaders(qaqcData.standardized_headers);
    setDeviceUnits(qaqcData.header_uoms);
    setOntologyTerms(qaqcData.header_terms);
    //set standardizedTermMappings array
    configureMapping(qaqcData);
    //end loading
    setLoading(false);
  };

  const configureMapping = (qaqcData) => {
    let undefinedParameters = qaqcData.unique_parameters;
    let standardTermMappings = qaqcData.standardized_term_mapping;

    let combinedObjects = [];
    //create object properties
    undefinedParameters.forEach((el) => {
      let obj = {
        parameter: el[0],
        deviceUnit: '',
        ontologyTerm: '',
        standardized_header_id: '',
      };
      combinedObjects.push({ ...obj });
    });

    //attach standardized_header_id
    let addStandardizedHeaderArray = combinedObjects.map((el) => {
      standardTermMappings.forEach((mapping) => {
        if (mapping.parameter == el.parameter) {
          return (el.standardized_header_id =
            mapping.standardized_header_id);
        }
      });
      return el;
    });

    //set unit and term
    let standardizedTermMappingsArray =
      addStandardizedHeaderArray.map((el) => {
        qaqcData.standardized_headers.forEach((header) => {
          if (header.id == el.standardized_header_id) {
            el.deviceUnit = header.header_uom_id;
            el.ontologyTerm = header.header_term_id;
          }
        });
        return el;
      });

    setStandardizedTermMappings(standardizedTermMappingsArray);
  };

  const saveStandardizedTermMappings = async () => {
    if (standardizedTermMappings == []) {
      return;
    }
    //set loading ui
    setloadingSubmit(true);
    let validMappings = standardizedTermMappings.filter((el) => {
      return el.deviceUnit !== '' && el.ontologyTerm !== '';
    });
    //find standardized_header_id from combination of deviceUnit and ontologyTerm
    let data = validMappings.map((el) => {
      standardizedHeaders.forEach((header) => {
        if (
          el.deviceUnit == header.header_uom_id &&
          el.ontologyTerm == header.header_term_id
        ) {
          return (el.standardized_header_id = header.id);
        }
      });
      return el;
    });
    //api request
    const result = await addStandardizedTermMappingsById(
      node.id,
      data
    );
    setloadingSubmit(false);
    //if returns success
    if (result?.success) {
      setSuccess(true);
      setSuccessMsg(result?.message);
      return;
    }

    setSuccessMsg('There has been an error adding the mapping.');
    setSuccess(false);
  };

  return (
    <div className="qaqc-page">
      <div className="qaqc-table">
        <div className="contact-email">
          <a href="#">
            Email XXXX@umn.edu to suggest new ontology terms
          </a>
        </div>
        <br />
        <br />
        <table>
          <thead>
            <tr>
              {tableHeaders.map((column, i) => (
                <th key={i}>
                  <div className="qaqc-tooltip-outer">
                    <div className="qaqc-tooltip-inner">
                      <p>{column.label}</p>
                    </div>
                    <Tooltip
                      title={<p>{column.desc}</p>}
                      style={{ cursor: 'pointer' }}
                    >
                      <HelpIcon fontSize="small"></HelpIcon>
                    </Tooltip>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <div className="qaqc-loader">
              <div className="qaqc-loader-icon">
                <CircularProgress color="primary" />
              </div>
              <br />
            </div>
          ) : (
            <tbody>
              {standardizedTermMappings.map((mapping, i) => (
                <tr>
                  <td>
                    {mapping.deviceUnit !== '' &&
                    mapping.ontologyTerm !== '' ? (
                      <CheckIcon
                        style={{ color: '#2dd36f' }}
                      ></CheckIcon>
                    ) : (
                      <CloseIcon
                        style={{ color: '#eb445a' }}
                      ></CloseIcon>
                    )}
                  </td>
                  <td>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mapping.parameter}
                        displayEmpty
                        disabled
                      >
                        <MenuItem value={mapping.parameter} disabled>
                          {mapping.parameter}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mapping.deviceUnit}
                        onChange={(e) => {
                          const newArr = [
                            ...standardizedTermMappings,
                          ];
                          newArr[i].deviceUnit = e.target.value;
                          setStandardizedTermMappings(newArr);
                        }}
                        displayEmpty
                      >
                        <MenuItem value="">Units Drop-down</MenuItem>
                        {deviceUnits.map((unit, i) => (
                          <MenuItem value={unit.id}>
                            {unit.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mapping.ontologyTerm}
                        onChange={(e) => {
                          const newArr = [
                            ...standardizedTermMappings,
                          ];
                          newArr[i].ontologyTerm = e.target.value;
                          setStandardizedTermMappings(newArr);
                        }}
                        displayEmpty
                      >
                        <MenuItem value="">
                          Populated from Database
                        </MenuItem>
                        {getFilteredTerms(mapping)}
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    {getOntologyDescription(mapping)
                      ? getOntologyDescription(mapping)
                      : 'Once selected, definition of Sensing Ontology Term'}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {!loading && (
        <div className="qaqc-save-btn">
          <br />
          {loadingSubmit ? (
            <CircularProgress color="primary" />
          ) : (
            <Button
              onClick={() => {
                saveStandardizedTermMappings();
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
        </div>
      )}
      {successMsg !== '' && (
        <div className={classes.root}>
          <Alert
            variant="outlined"
            severity={success ? 'success' : 'error'}
          >
            {successMsg}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Qaqc;
