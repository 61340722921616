import axios from 'axios';

export const updateUserAccount = async (userInfo) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_IOT_SERVER}/users`, userInfo, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (e) {
    console.warn("There's been an error updating User Account", e);
  }
};

export const getUserById = async (userId, dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_IOT_SERVER}/users/${userId}`, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        dispatch({
          type: 'SET_USER',
          payload: response.data,
        });
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error getting user by id", e);
  }
};
