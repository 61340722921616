/**
 * Navbar is predominately where the authentication is utilized most.
 * Allows user to sign in/out, and navigate around the app once logged in.
 * Currently has the beginnings of tablet/mobile funcationality.
 * Could maybe be handled less messily by doing the check in App.js and rendering mobile components.
 */

/* eslint-disable react/button-has-type */
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FaBars } from 'react-icons/fa';
import GEMSLogo from '../../assets/gems-sensing-logo.png';
import { store } from '../../hooks/dataContext';
import { useGoogleAuth } from '../../hooks/GoogleAuth';
import Modal from '../Modal/Modal';
import { updateUserAccount } from '../../utils/user.api';
import './Navbar.css';
import { removeToken } from '../../hooks/token';
import { ReactComponent as CampaignIcon } from '../../../src/assets/icons/campaign.svg';

const Navbar = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 650 });
  const { signOut, signIn, isSignedIn, googleUser } = useGoogleAuth();
  let userInfo = null;

  const showAnnouncement = false;

  useEffect(() => {
    if (googleUser && googleUser.profileObj) {
      userInfo = {
        email: googleUser.profileObj.email,
        id: googleUser.profileObj.googleId,
        name: googleUser.profileObj.name,
        profile_pic: googleUser.profileObj.imageUrl,
      };
      updateUserAccount(userInfo);
      dispatch({ type: 'SET_USER', payload: userInfo });
    }
  }, [isSignedIn]);

  // ConsoleLogger('this is state', state);

  if (isTabletOrMobile) {
    return (
      <header id="mainNav">
        {showAnnouncement && (
          <div class="announcements">
            <span class="material-icons">
              <CampaignIcon fill="#006889"></CampaignIcon>
            </span>
            &emsp; &nbsp;We will be conducting routine maintenance
            over the next two weeks. Please expect intermittent
            downtime.
          </div>
        )}
        {/* GEMS Logo */}
        <div id="upperHalfNav">
          <div className="navLogo">
            <Link className="navbar-brand" to="/landing">
              <img
                className="logo"
                src={GEMSLogo}
                alt="GEMS Agroinformatics - Data-Driven Agricultural Innovation"
              />
            </Link>
          </div>
          <FaBars
            className="hamburgerMenu"
            onClick={() => {
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                  subModalTitle: 'Mobile Menu',
                  data: "maybe it can't be null",
                },
              });
            }}
          />
        </div>

        {/* Projects/Nodes/Analyze Nav */}
        <div id="navbarSupportedContent">
          {state.modal.open === true && (
            <Modal
              title={state.modal.subModalTitle}
              data={state.modal.data}
            />
          )}
          <ul>
            <button className="nav-item">
              <Link className="nav-link" to="/projectlist">
                Projects
              </Link>
            </button>
            <button className="nav-item">
              <Link to="/nodes" className="nav-link" role="button">
                Nodes
              </Link>
            </button>
            <button
              className="nav-item"
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    data: null,
                    subModalTitle:
                      'Are you sure you want to navigate?',
                  },
                });
              }}
            >
              {/* <a
                className="nav-link"
                // href="https://gems.agroinformatics.org"
              > */}
              Analyze Data on GEMS
              {/* </a> */}
            </button>
          </ul>
        </div>
      </header>
    );
  }

  return (
    <header id="mainNav">
      {/* announcements */}
      {showAnnouncement && (
        <div class="announcements">
          <span class="material-icons">
            <CampaignIcon fill="#006889"></CampaignIcon>
          </span>
          &emsp; &nbsp;We will be conducting routine maintenance over
          the next two weeks. Please expect intermittent downtime.
        </div>
      )}
      {/* Modal Toggle */}
      {state.modal.open === true && (
        <Modal
          title={state.modal.subModalTitle}
          data={state.modal.data}
        />
      )}
      {/* GEMS Logo */}
      <div id="upperHalfNav">
        <div className="navLogo">
          <Link className="navbar-brand" to="/landing">
            <img
              className="logo"
              src={GEMSLogo}
              alt="GEMS Agroinformatics - Data-Driven Agricultural Innovation"
            />
          </Link>
        </div>

        {/* User/Team Nav w/ Logout */}
        <nav id="accountNav" className="navbar ">
          <ul>
            <div className="nav-line">
              {isSignedIn ? (
                <div className="navList">
                  <li className="nav-item">
                    <Link className="nav-link account" to="/user">
                      Logged in as {googleUser.profileObj.name}
                    </Link>
                  </li>
                  <div className="nav-line" />
                  <li className="nav-item">
                    <Link
                      className="nav-link account"
                      to="/devicesetup/register"
                    >
                      Setup
                    </Link>
                  </li>

                  <div className="nav-line" />
                  {/* <li className="nav-item">
                    <Link className="nav-link account" to="/order">
                      Order
                    </Link>
                  </li>
                  <div className="nav-line" /> */}
                  <li className="nav-item">
                    <Link className="nav-link account" to="/help">
                      Help
                    </Link>
                  </li>
                  <div className="nav-line" />

                  <li className="nav-item">
                    <Link
                      className="nav-link account"
                      to="/login"
                      onClick={() => {
                        removeToken();
                        //google auth
                        signOut();
                      }}
                    >
                      Logout
                    </Link>
                  </li>
                </div>
              ) : (
                <div className="navList">
                  <li className="nav-item">
                    <a className="nav-link account" onClick={signIn}>
                      Login
                    </a>
                  </li>
                  <div className="nav-line" />

                  <li className="nav-item">
                    <Link className="nav-link account" to="/help">
                      Help
                    </Link>
                  </li>
                </div>
              )}
            </div>
          </ul>
        </nav>
      </div>
      {/* Explore/Share/Analyze Nav */}
      <div className="lightgrey" id="navbarSupportedContent">
        <ul>
          <button className="nav-item">
            <Link className="nav-link" to="/projectlist">
              Projects
            </Link>
          </button>
          <button className="nav-item">
            <Link to="/nodes" className="nav-link" role="button">
              Nodes
            </Link>
          </button>
          <button
            className="nav-item"
            onClick={() => {
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                  data: null,
                  subModalTitle: 'Are you sure you want to navigate?',
                },
              });
            }}
          >
            {/* <a
                className="nav-link"
                // href="https://gems.agroinformatics.org"
              > */}
            Analyze Data on GEMS
            {/* </a> */}
          </button>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
