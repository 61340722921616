import axios from 'axios';

export const verifyTokenAuth = async (googleAuthToken) => {
  const data = {
    token: googleAuthToken,
  };
  return await axios
    .post(`${process.env.REACT_APP_IOT_SERVER}/auth`, data, {
      rejectUnauthorized: false,
      withCredentials: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.warn("There's been an error with auth", err);
    });
};
