import React, { useEffect, useContext } from 'react';
// import { useMediaQuery } from 'react-responsive';
import ProjectHeader from './components/ProjectHeader';
import './ViewProject.css';
import { getProjectNodesData } from '../../utils/projects.api';
import { store } from '../../hooks/dataContext';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PublicSwitch from '../../components/PublicSwitch/PublicSwitch';
import { GetApp } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { DotLoader } from 'react-spinners';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { isNull } from 'lodash';

const useStyles = makeStyles({
  title: {},
  card: {
    width: '350px',
    padding: '24px',
  },
  cardGrid: {
    padding: '20px',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyNodesMessage: {
    padding: '20px',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    marginBottom: '5px',
  },
  nodeName: {
    paddingLeft: '8px',
    paddingTop: '3px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  menuButton: {
    float: 'right',
  },
});

const ViewProject = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { project, projectNodes, loading, user } = state;
  const classes = useStyles();
  const history = useHistory();

  const [selectedNode, setSelectedNode] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const userIsOwner =
    project.owner != null && state.user.id === project.owner;

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (project.owner == undefined && !project.public) {
      history.push('/projectlist');
    }
  });

  // useEffect(() => {
  //   getProjectNodesData(project.id, dispatch);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [project.id, project.start_date, project.end_date]);

  const renderNodes = () => {
    //if (loading) return <DotLoader color="#006888" />;
    return projectNodes.length === 0 ? (
      <div className={classes.emptyNodesMessage}>
        No nodes allocated to this project
      </div>
    ) : (
      projectNodes.map((node) => (
        <Grid item key={node.id}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  width: '250px',
                  textAlign: 'left',
                }}
                onClick={() => {
                  history.push('/viewNode');
                  dispatch({
                    type: 'SET_SELECTED_NODE',
                    payload: node,
                  });
                }}
              >
                <Avatar
                  style={{ backgroundColor: '#006889' }}
                ></Avatar>
                <Typography
                  className={classes.nodeName}
                  variant="h6"
                  gutterBottom
                >
                  {node.name}
                </Typography>
              </div>

              <Button
                classes={classes.menuButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  openMenu(e);
                  setSelectedNode(node);
                }}
                aria-label="settings"
                style={{
                  maxWidth: '50px',
                  maxHeight: '50px',
                  minWidth: '30px',
                  minHeight: '30px',
                  // marginLeft: '40px',
                }}
              >
                <MoreVertIcon />
              </Button>
            </CardContent>
            {/* </CardActionArea> */}
            <CardActions className={classes.cardActions}>
              <PublicSwitch
                type="node"
                item={node}
                disabled={user.id !== node.owner}
              />
              <Button
                className={classes.downloadCSVButton}
                size="small"
                color="primary"
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_MODAL',
                    payload: {
                      data: node,
                      subModalTitle: 'Download Node',
                    },
                  })
                }
                style={{ paddingRight: '5px' }}
              >
                <GetApp /> Download CSV
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))
    );
  };

  return (
    <div className="page viewProject">
      {/* Props passed to show and hide map */}
      {/* <ProjectHeader hidden={hidden} toggleHidden={toggleHidden} /> */}
      <ProjectHeader />
      <br />
      <br />
      {/* <div className="nodeMap" hidden={hidden}>
        <Map
          className="mapTest"
          hidden={hidden}
          height={300}
          width="100%"
        />
      </div> */}
      <h1>Project nodes:</h1>
      <Grid
        container
        className={classes.cardGrid}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {renderNodes()}
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            history.push('/viewNode');
            dispatch({
              type: 'SET_SELECTED_NODE',
              payload: selectedNode,
            });
          }}
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                data: selectedNode,
                subModalTitle: 'Download Node',
              },
            });
            closeMenu();
          }}
        >
          Download
        </MenuItem>
        {userIsOwner && (
          <MenuItem
            onClick={() => {
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                  subModalTitle: 'Remove Node',
                  data: {
                    id: project.id,
                    node_id: selectedNode.id,
                    owner: user.id,
                  },
                },
              });
              closeMenu();
            }}
          >
            Remove from project
          </MenuItem>
        )}
      </Menu>
      {/* <GraphSection node /> */}
    </div>
  );
};

export default ViewProject;
