import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Table from './components/Table';
import './Nodes.css';
import { getNodeList } from '../../utils/node.api';
import { store } from '../../hooks/dataContext';
import { nodeColumns } from '../../utils/constants';
import TabList from '../../components/TabList/TabList';
import { Button } from '@material-ui/core';

function Nodes() {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { nodeList, userNodes } = state;

  useEffect(() => {
    getNodeList(state, dispatch);
  }, []);

  return (
    <div className="page">
      <h1 className="title">All Active Nodes</h1>
      <div id="nodes">
        <div className="newNodeButton">
          <Link to="/addNode">
            <Button variant="contained" color="primary">
              Add New Node
            </Button>
          </Link>
        </div>
        <TabList>
          <div label="My Nodes" className="tab-content">
            <Table data={userNodes} columns={nodeColumns} />
          </div>
          <div label="All Nodes" className="tab-content">
            <Table data={nodeList} columns={nodeColumns} />
          </div>
        </TabList>
      </div>
    </div>
  );
}

export default Nodes;
