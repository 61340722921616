import React from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import Chart from '../../assets/graph-21.svg';
import Sensor from '../../assets/sensorvector.svg';
import './LandingPage.css';
import { ReactComponent as HourGlass } from '../../../src/assets/icons/hourglass_bottom.svg';

const LandingPage = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 650 });
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <div className="page">
      <div
        className={
          isTabletOrMobile ? 'landingArea-mobile' : 'landingArea'
        }
      >
        {/* COMING SOON */}
        <div className="comingSoon">
          <div className="triangle">
            <div className="comingSoon-content">
              <HourGlass />
              <p>COMING SOON</p>
            </div>
          </div>

          <button onClick={() => {}}>
            <img
              src={Sensor}
              alt="sensor"
              style={{ width: '100px' }}
              className="landingImage"
            />
            <p className="buttonLink blue white-text semi-bold">
              Order
            </p>
            <p className="description white-text">
              (Choose this if you need to order customized sensing
              stations.)
            </p>
            <div className="overlay"></div>
          </button>
        </div>

        <button
          className={
            isTabletOrMobile
              ? 'landingChoice-mobile'
              : 'landingChoice'
          }
          onClick={() => routeChange('/nodes')}
        >
          <img
            src={Chart}
            style={{ width: '60px' }}
            alt="line chart"
            className="landingImage"
          />
          <p className="buttonLink blue white-text semi-bold">Data</p>
          <p className="description white-text">
            (Choose this if you want to view available nodes.)
          </p>
        </button>
        <button
          className={
            isTabletOrMobile
              ? 'landingChoice-mobile'
              : 'landingChoice'
          }
          onClick={() => routeChange('/devicesetup/register')}
        >
          <AiOutlineAppstoreAdd
            size={50}
            className="white-text landingImage"
          />
          <p className="buttonLink blue white-text semi-bold">
            Setup
          </p>
          <p className="description white-text">
            (Choose this for step-by-step instructions to setup your
            new node.)
          </p>
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
