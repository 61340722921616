import React from 'react';
import { useGoogleAuth } from '../../hooks/GoogleAuth';
import './User.css';

const User = () => {
  const { googleUser } = useGoogleAuth();

  return (
    <div className="page">
      <h2 className="title">User Information</h2>
      <div className="userInfoContainer">
        <div className="userInfo avatar">
          <img
            src={googleUser.profileObj.imageUrl}
            alt="User Avatar"
          />
        </div>
        <div className="userInfo">
          <p className="semi-bold">Name:</p>
          <p>{googleUser.profileObj.name}</p>
        </div>
        <div className="userInfo">
          <p className="semi-bold">Email:</p>
          <p>{googleUser.profileObj.email}</p>
        </div>
      </div>
    </div>
  );
};

export default User;
