import React, { useContext } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
// import { IoIosMore } from 'react-icons/io';
import PublicSwitch from '../../../components/PublicSwitch/PublicSwitch';
import { store } from '../../../hooks/dataContext';
import { deleteNode } from '../../../utils/node.api';
import { Link } from 'react-router-dom';
import { getProjectNodesData } from '../../../utils/projects.api';
import { format } from 'date-fns';

const NodeHeader = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedNode, project, user, nodeData } = state;
  const { viewingFrom } = props;
  const [firstReading, lastReading] =
    nodeData[selectedNode.id]?.timestampRange || [];

  const userIsOwner =
    selectedNode.owner != null && user.id === selectedNode.owner;
  return (
    <div className="nodeInfoHeader">
      <div className="nodeInfoStub">
        <p>Node: {selectedNode.name}</p>
        <br />
        <Link
          to="/project"
          role="button"
          onClick={() => {
            dispatch({
              type: 'SET_PROJECT',
              payload: project,
            });
            getProjectNodesData(project.id, dispatch);
          }}
        >
          {selectedNode.project_id == null ? null : (
            <h1>{project.name} (CLICK TO VIEW)</h1>
          )}
        </Link>
      </div>

      <div className="cropInfoStub">
        <div className="cropInfo"></div>

        {viewingFrom && (
          <div className="cropInfo">
            <p>VIEWING FROM</p>
            <p>{format(viewingFrom, "dd MMM yyyy 'at' h:mm a")}</p>
          </div>
        )}
        {lastReading && (
          <div className="cropInfo">
            <p>LAST HEARD FROM</p>
            <p>{format(lastReading, "dd MMM yyyy 'at' h:mm a")}</p>
          </div>
        )}
      </div>
      <div className="nodeOptionButtons">
        {/* <div className="circle shadow optionButton">
          <FaPencilAlt
            onClick={() => {
              dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                  data: selectedNode,
                  subModalTitle: 'Edit Node',
                },
              });
            }}
          />
        </div> */}
        {/* <div className="circle shadow optionButton">
          <FaRegMap onClick={() => toggleHidden(!hidden)} />
        </div> */}
        {/* <div className="circle shadow optionButton">
          <IoIosMore />
        </div> */}
        <div>
          <PublicSwitch
            disabled={!userIsOwner}
            type="node"
            item={selectedNode}
          />
        </div>

        <div className="circle shadow optionButton">
          <FaTrashAlt
            onClick={() => deleteNode(selectedNode, dispatch)}
          />
        </div>
      </div>
    </div>
  );
};

export default NodeHeader;
