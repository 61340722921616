import React, { useContext, useState } from 'react';
import WindowsStill from '../../assets/pasted image 0.png';
import IdBlob from '../../assets/id_blob.png';
import registerNodeDecor1 from '../../assets/register_node_decor_1.png';
import { registerNode } from '../../utils/node.api';
import { store } from '../../hooks/dataContext';
import './RegisterNode.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import {
  getNodeList,
  decommissionAndRegisterNode,
} from '../../utils/node.api';
import { Link } from 'react-router-dom';

const RegisterNode = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const [nodeName, setNodeName] = React.useState('');

  const [nodeExists, setNodeExists] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [disableRegister, setDisableRegister] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const tryRegisterNode = async () => {
    const response = await registerNode(
      state.registrationNumber,
      state.user.id,
      nodeName
    );

    if (!response.success) {
      setErrorMsg(response.message);
      setIsOwner(response.isOwner);
      setDisableRegister(true);
      setNodeExists(true);

      return;
    } else {
      setNodeName('');
    }

    getNodeList(state, dispatch);
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: { subModalTitle: 'Success' },
    });
    dispatch({
      type: 'SET_REGISTRATION_NUMBER',
      payload: 'Node ID',
    });
  };

  return (
    <div className="nodeRegisterPage">
      <img
        className="registerNodeDecor"
        src={registerNodeDecor1}
        alt=""
      />
      <br />
      <br />
      <br />
      <Paper className="registerNodePage">
        <div>
          <TextField
            error={
              state.registrationNumber &&
              state.registrationNumber.length > 25
            }
            helperText={
              state.registrationNumber &&
              state.registrationNumber.length > 25
                ? 'maximum length 25 characters (' +
                  state.registrationNumber.length +
                  ')'
                : ''
            }
            margin="dense"
            label="Enter node id"
            value={state.registrationNumber}
            size="small"
            maxLength={25}
            onChange={(e) =>
              dispatch({
                type: 'SET_REGISTRATION_NUMBER',
                payload: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextField
            error={nodeName && nodeName.length > 50}
            helperText={
              nodeName && nodeName.length > 50
                ? 'maximum length 50 characters (' +
                  nodeName.length +
                  ')'
                : ''
            }
            margin="dense"
            label="Enter node name"
            value={nodeName}
            maxLength={50}
            size="small"
            onChange={(e) => setNodeName(e.target.value)}
          />
          <br />
          <br />
          <Button
            color="primary"
            variant="contained"
            disabled={
              !state.registrationNumber ||
              !nodeName ||
              state.registrationNumber.length > 25 ||
              nodeName.length > 50 ||
              disableRegister
            }
            size="large"
            onClick={() =>
              state.registrationNumber && tryRegisterNode()
            }
          >
            Register
          </Button>
        </div>
      </Paper>

      {nodeExists && (
        <Paper className="warning" elevation={3}>
          <h1 style={{ fontWeight: 'bold' }}>Warning</h1>
          <br />
          <Typography style={{ color: 'gray' }}>
            {errorMsg}
          </Typography>
          <br />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setNodeExists(false);
              setDisableRegister(false);
            }}
          >
            CANCEL
          </Button>
          &ensp;
          {isOwner ? (
            <Button
              style={{ backgroundColor: '#ef5350', color: 'white' }}
              variant="contained"
              onClick={async () => {
                await decommissionAndRegisterNode(
                  state.registrationNumber,
                  nodeName,
                  state.user.id,
                  state,
                  dispatch
                );
                setNodeExists(false);
                setNodeName('');
              }}
            >
              DECOMMISSION
            </Button>
          ) : (
            <Link to="/help">
              <Button variant="contained" color="primary">
                CONTACT SUPPORT
              </Button>
            </Link>
          )}
        </Paper>
      )}
      <br />
      <br />
      <div>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Detailed Registration Instructions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="page nodeSetupInstructions">
              <h1 className="semi-bold title">
                Particle Boron - Setup Directions
              </h1>
              {/* <hr className="dividerLineHorizontal" /> */}
              <div className="registerNodePrereq">
                <h2 className="semi-bold">Prerequisites:</h2>
                <li>
                  Particle Command Line (
                  <a>
                    https://docs.particle.io/tutorials/developer-tools/cli/
                  </a>
                  )
                </li>
                <li>
                  Python 3 (<a>https://www.python.org/downloads/</a>)
                </li>
                <li>
                  Select the “Add to PATH” option during installation
                </li>
              </div>
              <hr className="dividerLineHorizontal" />

              <div className="importantNotes">
                <h2 className="semi-bold">Important Notes:</h2>
                <p className="RegisterNodeNote">
                  * If you have any issues with thesetup process or
                  would like to provide feedback please email
                  hart1258@umn.edu
                </p>
                <p>
                  * If you have multiple nodes, repeat the setup
                  process in the downloaded setup program.
                </p>
                <p>
                  * Make note of the name you give the device, since
                  this will be how each node (and resulting data) is
                  identified to the system
                </p>
                <p>
                  * After the devices are registered, leave them
                  running for about a day in a location with cell
                  service to allow the firmware to update and for the
                  operation to be verified, wait for confirmation of
                  operation before proceeding to the field install,
                  once operation is confirmed, nodes should be shut
                  down while waiting for deployment
                </p>
              </div>

              <div className="registerNodeBottomSection">
                <div className="registerNodeDirections">
                  <h2 className="semi-bold">Directions:</h2>
                  <li>
                    1. Download the correct .zip for your operation
                    system.
                  </li>
                  <div className="registerDownloadButtons">
                    <button className="blue white-text">
                      <a href="https://iot.agroinformatics.org/dist_windows.zip">
                        Windows.zip
                      </a>
                    </button>
                    <button className="green white-text">
                      <a href="https://iot.agroinformatics.org/dist_linux.zip">
                        Linux.zip
                      </a>
                    </button>
                  </div>
                  <li>
                    {' '}
                    2. Unzip the Project to a new file location
                  </li>
                  <li>
                    <p>3. Download the access token</p>
                    <div>
                      <button className="blue white-text">
                        <a href="https://iot.agroinformatics.org/access_token.py">
                          {' '}
                          Download Token
                        </a>
                      </button>
                    </div>
                  </li>
                  <li>
                    4. Copy or move the access_token.py file to the
                    /dist/main/ directory
                  </li>
                  <li>
                    5. Double click or right click run on the main.exe
                    file If running on a windows machine you might see
                    this message:
                  </li>
                  <div className="registerNodePicArea">
                    <img
                      src={WindowsStill}
                      className="registerNodePic"
                      alt="Windows alert. Two buttons. Run Anyway and Don't Run."
                    />
                    <p> ~ Click “Run anyway” to proceed ~</p>
                  </div>
                  <li>
                    6. Plug-in your Particle Boron device into the USB
                    port and follow the prompts to setup and register
                    your device
                  </li>
                  <li>
                    <p>
                      7. After each node is registered, enter the
                      node's ID and click submit:
                    </p>
                    <img
                      src={IdBlob}
                      className="registerNodePic"
                      alt="List of properties for newly registered node with id circled"
                    />
                  </li>
                  <li>
                    8. When all your devices are registered, stop the
                    exe by clicking ‘close’ or the ‘x’ in the top
                    right corner of the window.
                  </li>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
export default RegisterNode;
