/**
 * Allows user to select their uploaded projects and add them to new project or skip to end
 * Props: skip (func)
 */

import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../../../hooks/dataContext';
import { getNodeList } from '../../../../utils/node.api';
import './SelectNodes.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const SelectNodes = (props) => {
  const { state, dispatch } = useContext(store);
  const { skip } = props;
  const { nodeList, userNodes, publicNodes, sharedNodes } = state;

  const classes = useStyles();
  const [allNodes, setAllNodes] = useState([]);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    setAllNodes([...userNodes, ...sharedNodes, ...publicNodes]);
    if (filter) {
      setAllNodes([...userNodes, ...sharedNodes]);
    }
    if (!filter) {
      setAllNodes([...userNodes, ...sharedNodes, ...publicNodes]);
    }
  }, [filter]);

  useEffect(() => {
    getNodeList(state, dispatch);
    setAllNodes([...userNodes, ...sharedNodes, ...publicNodes]);
  }, []);

  useEffect(() => {
    setAllNodes([...userNodes, ...sharedNodes, ...publicNodes]);
  }, [nodeList]);

  // Iterates over list of devices/nodes and renders a row for each.

  return (
    <div className="nodeContainer">
      <p className="selectNodeTitle">
        Select node(s) to add to your project.
      </p>
      <br />
      <button
        className="transparent blue-text"
        onClick={() => skip()}
      >
        Skip
      </button>
      <br />
      <div>
        <Paper className="tableHeader" elevation={3}>
          <FormControlLabel
            control={
              <Checkbox
                aria-label="Include Public Nodes"
                name="Include Public Nodes"
                defaultChecked
                onChange={() => setFilter((filter) => !filter)}
                color="primary"
              />
            }
            label="Include Public Nodes"
          />
        </Paper>
      </div>
      <br />
      <div className={classes.root}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="tags-outlined"
          options={allNodes}
          getOptionLabel={(option) => option?.node_id}
          onChange={(e, value) => {
            dispatch({
              type: 'ADD_SENSOR_TO_PROJECT',
              payload: value,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select nodes"
              placeholder="Select nodes"
            />
          )}
        />
      </div>
    </div>
  );
};

export default SelectNodes;
