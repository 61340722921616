/**
 * Popup that prompts user to confirm before navigating to GEMS core.
 */

import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';

const AreYouSureNavigate = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  return (
    <div className="naviagateModal quantityModal">
      <p>
        Are you sure you want to leave LabX IoT and navigate to the
        GEMS Agroinformatics platform?
      </p>
      <button
        className="blue white-text"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
      >
        <a
          //   className="nav-link"
          href="https://gems.agroinformatics.org"
        >
          Confirm
        </a>
      </button>
    </div>
  );
};

export default AreYouSureNavigate;
