import React, { useContext } from 'react';
import { store } from '../../../hooks/dataContext';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

// Generic info modal
const Info = (props) => {
  const { data } = props;
  const { success, message } = data;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <div>
      {!success && (
        <div>
          <Typography variant="h5" gutterBottom>
            Error
          </Typography>
          <ErrorIcon
            fontSize="large"
            style={{ color: '#ef5350' }}
          ></ErrorIcon>
        </div>
      )}
      <p>{message}</p>
      <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
              data: {},
            },
          });
        }}
      >
        Close
      </Button>
    </div>
  );
};

export default Info;
