import axios from 'axios';
import { formatISO } from 'date-fns';
import ConsoleLogger from '../hooks/ConsoleLogger';
import { getNodeById } from './node.api';

export const getProjects = async (dispatch, history) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_IOT_SERVER}/projects`, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        ConsoleLogger(
          'this is getProjects response',
          response.data.records
        );
        dispatch({
          type: 'SET_PROJECTS',
          payload: response.data.records,
        });
        return response.data;
      });
  } catch (e) {
    history.push('/landing');
    console.warn("There's been an error getting projects", e);
  }
};

export const createNewProject = async (
  project,
  dispatch,
  selectedNodeIds = []
) => {
  project.nodes = selectedNodeIds;
  ConsoleLogger('this is the project in createNew', project);
  const stringedProject = JSON.stringify(project);
  try {
    return await axios
      .post(`${process.env.REACT_APP_IOT_SERVER}/projects`, project, {
        rejectUnauthorized: false,
        withCredentials: false,
      })
      .then((response) => {
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: { subModalTitle: 'Success' },
        });
        ConsoleLogger(response.data);
      })
      .catch((err) => {
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: { subModalTitle: `${err}` },
        });
      });
  } catch (e) {
    console.warn("There's been an error creating new project", e);
  }
};

export const deleteProject = async (projectId, dispatch) => {
  try {
    return await axios
      .delete(
        `${process.env.REACT_APP_IOT_SERVER}/projects/${projectId}`,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        getProjects(dispatch);
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: {
            data: {},
          },
        });
        return response.data;
      })
      .catch((err) => {
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: {
            subModalTitle: 'Error',
          },
        });
      });
  } catch (e) {
    console.warn("There's been an error deleting project", e);
  }
};

/**Retrieve Project and updates local context if dispatch is provided.
 * return: Project
 */
export const getProjectById = async (projectId, dispatch) => {
  if (!projectId) return null;
  return await axios
    .get(
      `${process.env.REACT_APP_IOT_SERVER}/projects/${projectId}`,
      {
        rejectUnauthorized: false,
        withCredentials: false,
      }
    )
    .then((response) => {
      ConsoleLogger(
        'this is project with id' + projectId,
        response.data.records
      );
      if (dispatch)
        // provides option not to update state
        dispatch({
          type: 'SET_PROJECT',
          payload: response.data.records[0],
        });
      return response?.data?.records[0];
    })
    .catch((err) => {
      console.warn(
        "There's been an error getting project by id",
        err
      );
    });
};

export const getProjectNodes = async (id, dispatch) => {
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_IOT_SERVER}/projects/${id}/nodes`,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        ConsoleLogger(
          'this is getProjectNodes',
          response.data.records
        );

        //const node = response.data.nodes;
        //getNodeById(node.node_id, dispatch);
        dispatch({
          type: 'SET_PROJECT_NODES',
          payload: response.data.records,
        });
        dispatch({
          type: 'TOGGLE_LOADING',
        });
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error getting project nodes", e);
  }
};

export const getProjectNodesData = async (projectId, dispatch) => {
  ConsoleLogger('this is getProjectNodesData projectId', projectId);
  if (!projectId) return;
  try {
    dispatch({ type: 'TOGGLE_LOADING', payload: null });

    dispatch({
      type: 'SET_PROJECT_NODES',
      payload: [],
    });

    return await axios
      .get(
        `${process.env.REACT_APP_IOT_SERVER}/projects/${projectId}/nodes/data`,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        ConsoleLogger('this is getProjectNodesData', response.data);

        if (
          response.data.records &&
          response.data.records.length > 0
        ) {
          dispatch({
            type: 'SET_PROJECT_NODES',
            payload: response.data.records[0].nodes || [],
          });
        }
        dispatch({
          type: 'TOGGLE_LOADING',
        });
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error with getProjectNodesData", e);
  }
};

export const togglePublicProject = async (project, dispatch) => {
  const toggled = {
    is_public: !project.is_public,
  };
  ConsoleLogger('togglePublicProject project', project);
  try {
    return await axios
      .patch(
        `${process.env.REACT_APP_IOT_SERVER}/projects/${project.id}`,
        toggled,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: { subModalTitle: 'Success' },
        });
        getProjects(dispatch);
        return response.data;
      });
  } catch (e) {
    console.warn("There's been an error toggling public project", e);
  }
};

export const updateProject = async (project, nodes, dispatch) => {
  const { name, start_date, end_date, metadata, is_public } = project;

  const projectApiModel = {
    name,
    start_date: start_date && formatISO(start_date),
    end_date: end_date && formatISO(end_date),
    is_public,
    metadata: JSON.stringify(metadata),
    nodes: nodes,
  };

  if (!project.id) {
    console.warn('Could not update project. Project Id is required');
    return;
  }

  ConsoleLogger('update project', project);

  return await axios
    .patch(
      `${process.env.REACT_APP_IOT_SERVER}/projects/${project.id}`,
      projectApiModel,
      {
        rejectUnauthorized: false,
        withCredentials: false,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.warn("There's been an error updating project", err);
    });
};

export const removeNode = async (id, node_id, owner, dispatch) => {
  const data = {
    id: id,
    node_id: node_id,
    owner: owner,
  };
  ConsoleLogger('Remove node from project nodeId');
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_IOT_SERVER}/projects/removeNode`,
        data,
        {
          rejectUnauthorized: false,
          withCredentials: false,
        }
      )
      .then((response) => {
        getProjectNodes(id, dispatch);
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: {
            subModalTitle: 'Success',
          },
        });
      });
  } catch (e) {
    console.warn(
      "There's been an error removing node from project",
      e
    );
  }
};
