import styled from 'styled-components';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    width: 100%;
    tr {
      :first-child {
        // these styles are needed to hide a header.
        th {
          border: 0px;
          font-size: 0;
          margin: 0;
          padding: 0;
          visibility: hidden;
        }
      }
    }
    th {
      background-color: #f6f6f6;
    }
    thead > tr > th > span {
      margin-bottom: 5px;
      margin-left: 2px;
    }
    th,
    td {
      margin: 0;
      padding: 0.6rem;
      border-bottom: 1px solid lightgrey;
      border-top: 1px solid lightgrey;
      white-space: nowrap;
      max-width: 200px;
      min-height: 70px;
      text-overflow: ellipsis;
    }
    td > a {
      color: #006888;
    }
    td > a:visited {
      color: #69a03d;
    }
    td > a:visited {
      color: #138e8f;
    }
    .tableTitle {
      min-width: 225px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding-left: 35px;
    }
    .tableUser {
      min-width: 225px;
    }
    .hiddenButton {
      background-color: transparent;
    }
    .productSelect {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .productSelect > .dropdown {
      margin-right: 15px;
    }
  }
`;

export default Styles;
