/* eslint-disable no-case-declarations */
// This is where the data state lives. It's wrapped around the root level of the
// app where it provides the state. You update the state object by using the dispatch
// function within your component or api call. It looks like this:
//
// dispatch({ type: 'SET_DEVICE_ID', payload: deviceId });
//
// You can access it within each component in two ways. If you need to access the dispatch
// outside of the return (to pass it to an api or using in the useEffect hook) you should import the
// store and use this inside the component function:
//
// const globalState = useContext(store);
// const { state, dispatch } = globalState;
//
// If you don't need to operate on the state variables and just need to render them, you can pass the
// state to be used in the JSX (in the return), you can access/pass the state like this:
//
// const { Consumer } = store;
// <Consumer>
//  {({ state, dispatch }) => (
//    <div className="setup">
//       <p>Blerg {state.nodeData.status}</p>
//    </div>
//  )}
// </Consumer>
//

import React, { createContext, useReducer } from 'react';
import ConsoleLogger from './ConsoleLogger';
import {
  nodeSetup,
  node,
  project,
  selectedSensor,
  nodeList,
  order,
} from '../utils/constants';
import { format, max, min, parseJSON } from 'date-fns';
import { isNil, isNull } from 'lodash';

export const initialState = {
  nodeSetup,
  project,
  projects: [project, project, project],
  userProjects: [],
  selectedNode: node,
  selectedNodeTwo: node,
  selectedSensor,
  selectedSensorTwo: selectedSensor,
  hardwareList: null,
  projectNodes: [],
  nodeData: {},
  nodeList,
  nodeGroup: [],
  loading: false,
  modal: {
    open: false,
    subModalTitle: null,
    data: null,
  },
  order,
  orderResponse: 'CONGRATS!',
  user: { id: null },
  userNodes: [],
  publicNodes: [],
  sharedNodes: [],
  registrationNumber: undefined,
};

const store = createContext(initialState);
const { Provider } = store;

const DataContext = ({ children }) => {
  // eslint-disable-next-line no-shadow
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_USER':
        return {
          ...state,
          user: action.payload,
        };
      case 'TOGGLE_MODAL':
        const modalToggle = {
          ...state,
          modal: {
            open: !state.modal.open,
            data: action.payload.data,
            subModalTitle: action.payload.subModalTitle,
          },
        };
        return modalToggle;
      case 'SET_REGISTRATION_NUMBER':
        return { ...state, registrationNumber: action.payload };
      case 'UPDATE_PROJECT':
        const updated = action.payload;
        const userProjects = state.userProjects.map((proj) =>
          proj.id === updated.id ? updated : proj
        );
        const projects = state.projects.map((proj) =>
          proj.id === updated.id ? updated : proj
        );
        const stateWithUpdatedProject = {
          ...state,
          project: { ...updated },
          userProjects: [...userProjects],
          projects: [...projects],
        };
        return stateWithUpdatedProject;
      case 'SET_PROJECT':
        const setNewProjectData = {
          ...state,
          project: { ...action.payload },
        };
        return setNewProjectData;
      case 'SET_PROJECTS':
        const userProjectArray = [];
        action.payload &&
          action.payload.map((project) => {
            if (project.owner && state.user.id === project.owner) {
              userProjectArray.push(project);
            }
            return null;
          });
        const setProducts = {
          ...state,
          projects: action.payload || [],
          userProjects: userProjectArray,
        };
        return setProducts;
      case 'SET_PROJECT_NODES':
        const setProjectNodes = {
          ...state,
          projectNodes: action.payload,
        };
        return setProjectNodes;
      case 'SET_NODE_LIST':
        const nodesList = action.payload;
        const userNodes = [];
        const publicNodes = [];
        const onlyPublicNodes = [];
        const sharedNodes = [];

        nodesList.map((node) => {
          if (state.user.id === node.owner) {
            userNodes.push(node);
          } else if (node.is_public && node.is_public === true) {
            onlyPublicNodes.push(node);
          } else {
            sharedNodes.push(node);
          }
          publicNodes.push(node);

          return null;
        });

        const setNodeLists = {
          ...state,
          nodeList: publicNodes,
          userNodes: userNodes,
          publicNodes: onlyPublicNodes,
          sharedNodes: sharedNodes,
        };
        return setNodeLists;
      case 'ADD_NODE_DATA':
        // This will append the node data with data in payload. This structure is designed to facilitate quick and easy lookup and is indexed by [nodeId] and [parameter]
        // nodeData = {
        //   [nodeId]: {
        //      timestampRange: [earliestDownloadedReadingDate, lastHeardFrom] <------- timestamp of first and last reading already downloaded.
        //      data: {
        //        [parameter]:{
        //          dateRange: [start, end] <------ timestamp of start and end date used to query data below.
        //          data: [...level0data]
        //        }
        //      }
        //   }
        // }

        const {
          node_id,
          data, // flat list of data to loop through and put into structure above.
          dateRange: [incomingStart, incomingEnd], // the date range that was queried to obtain this data.
        } = action.payload;

        if (!data) return state;

        let [minReading, maxReading] = state.nodeData[node_id]
          ?.timestampRange || [null, null];

        // Lets build parameter data object. Afterwards whe should get a parameter's data with parameterDictionary[parameter].data and parameter's queried dateRange with parameterDictionary[parameter].dateRange
        const parameterDictionary = data.reduce(
          (dictionary, reading) => {
            // ignore the following readings.
            if (isNil(reading.parameter) || isNil(reading.data))
              return dictionary;

            // build up firstReadingDate and lastHeardFromDate.
            const timestamp = parseJSON(reading.time_of_reading);
            minReading = isNull(minReading)
              ? timestamp
              : min([minReading, timestamp]);
            maxReading = isNull(maxReading)
              ? timestamp
              : max([maxReading, timestamp]);

            const [existingStart, existingEnd] = dictionary[
              reading.parameter
            ]?.dateRange || [new Date(), new Date()];
            const existingParameterData =
              dictionary[reading.parameter]?.data || [];
            return {
              ...dictionary,
              [reading.parameter]: {
                dateRange: [
                  incomingStart
                    ? min([incomingStart, existingStart])
                    : null,
                  max([incomingEnd, existingEnd]),
                ],
                data: [...existingParameterData, reading],
              },
            };
          },
          state.nodeData[node_id]?.data || {}
        );

        const isAll =
          state.nodeData[node_id]?.isAll || isNil(incomingStart);
        return {
          ...state,
          nodeData: {
            ...state.nodeData,
            [node_id]: {
              timestampRange: [minReading, maxReading],
              isAll,
              data: parameterDictionary,
            },
          },
        };
      case 'SET_SELECTED_NODE':
        const setSelectedNode = {
          ...state,
          selectedNode: action.payload,
          hardwareListOne: action.payload.hardware,
        };
        ConsoleLogger('setSelectedNode', setSelectedNode);
        return setSelectedNode;
      case 'SET_SELECTED_NODE_DATES':
        const allDates = action.payload?.allDates;

        if (allDates.length === 0) {
          const setSelectedNode = {
            ...state,
            selectedNode: action.payload,
            hardwareListOne: action.payload.hardware,
          };
          ConsoleLogger('setSelectedNode', setSelectedNode);
          return setSelectedNode;
        }

        let minDate = null;
        let maxDate = null;
        allDates.map((element, index) => {
          const date = element.time_of_reading;
          if (index === 0) {
            minDate = date;
            maxDate = date;
          }

          if (date > maxDate) {
            maxDate = date;
          }
          if (date < minDate) {
            minDate = date;
          }
        });

        const setSelectedNodeWithDates = {
          ...state,
          selectedNode: {
            ...action.payload,
            startDate: format(
              new Date(minDate),
              "dd MMM yyyy 'at' h:mm a"
            ),
            lastHeardFrom: format(
              new Date(maxDate),
              "dd MMM yyyy 'at' h:mm a"
            ),
          },
          hardwareListOne: action.payload.hardware,
        };

        return setSelectedNodeWithDates;
      case 'SET_SELECTED_NODE_TWO':
        const setSelectedNode2 = {
          ...state,
          selectedNodeTwo: {
            ...state.selectedNode,
            ...action.payload,
          },
        };
        return setSelectedNode2;
      case 'SET_SELECTED_SENSOR':
        const setSelectedSensor = {
          ...state,
          selectedSensor: {
            // ...state.selectedSensor,
            ...action.payload,
          },
        };
        return setSelectedSensor;
      case 'SET_SELECTED_SENSOR_TWO':
        const setSelectedSensorTwo = {
          ...state,
          selectedSensorTwo: {
            // ...state.selectedSensorTwo,
            ...action.payload,
          },
        };
        return setSelectedSensorTwo;
      case 'ADD_NODE_TO_PROJECT':
        return { ...state };
      case 'ADD_SENSOR_TO_PROJECT':
        const nodeIds = action.payload.map((el) => {
          return el.id;
        });

        const updatedNodeGroup = {
          ...state,
          nodeGroup: nodeIds,
        };

        return updatedNodeGroup;
      case 'SET_HARDWARE_LIST':
        const setHardwareList = {
          ...state,
          hardwareList: action.payload,
        };
        return setHardwareList;
      case 'SEND_TO_INVOICE_CUSTOMER':
        const customerCart = {
          ...state,
          order: {
            ...state.order,
            contact: action.payload,
          },
        };
        return customerCart;
      case 'SEND_TO_INVOICE_NODE':
        const nodes = [...state.order.nodesOnOrder];
        nodes.push(action.payload);
        const customerNode = {
          ...state,
          order: {
            ...state.order,
            nodesOnOrder: nodes,
          },
        };

        return customerNode;
      case 'SEND_TO_INVOICE_EXTRAS':
        const invoiceExtras = {
          ...state,
          order: {
            ...state.order,
            extras: action.payload,
          },
        };
        return invoiceExtras;
      case 'PLACE_ORDER':
        const finalOrder = {
          ...state,
          order: {
            ...state.order,
            timestamp: action.payload,
          },
        };
        return finalOrder;
      case 'SET_ORDER_RESPONSE':
        const orderResponse = {
          ...state,
          orderResponse: action.payload,
        };
        return orderResponse;
      case 'TOGGLE_LOADING':
        const loadingData = {
          ...state,
          loading: !state.loading,
        };
        return loadingData;
      default:
        throw new Error('Unexpected action');
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, DataContext };
